import classNames from "classnames";
import { noop as _noop } from "lodash";
import { useEffect } from "react";
import { Index } from "@components/SearchInput/Tabs";
import type { CategoryTab, TournamentTab } from "@libs/utils/teams";
import { getCategoryByTournamentId } from "@libs/utils/teams";
import styles from "./index.module.css";
import type { FC } from "react";

type TournamentTabProps = {
  onSelectCategoryTab: (value: CategoryTab | null) => void | undefined;
  onSelectTournament: (value: number | null) => void | undefined;
  categorizedTabs: CategoryTab[];
  hideTournamentTabs: boolean;
  selectedTournamentId: number | null | undefined;
};

export const TournamentTabsPc: FC<TournamentTabProps> = ({
  onSelectCategoryTab = _noop,
  onSelectTournament = _noop,
  categorizedTabs,
  hideTournamentTabs,
  selectedTournamentId,
}) => {
  const selectedCategory = getCategoryByTournamentId(
    categorizedTabs,
    selectedTournamentId
  );

  // Load selectCategory on initial mount.
  useEffect(() => {
    onSelectCategoryTab(selectedCategory);
    return () => {
      return;
    };
  }, []);

  // カテゴリタブのクリック時
  const onCategoryTabClick = (categoryTab: CategoryTab): void => {
    onSelectCategoryTab(categoryTab);
    // カテゴリタブの選択時には、直前に選択したtournamentを選択済みとする。
    onSelectTournament(categoryTab.previouslySelectedTournamentId);
  };

  // リーグタブのクリック時
  const onTournamentTabClick = (tournamentTab: TournamentTab) => {
    const tournamentId = tournamentTab.id;

    // リーグIDを元に対応するカテゴリを取得する。
    const categoryTabOfTournament = getCategoryByTournamentId(
      categorizedTabs,
      tournamentId
    );

    if (categoryTabOfTournament) {
      // リーグの選択時に、カテゴリタブ別のリーグの選択状況を記録しておく。
      // setStateを呼んでいない(直接propertyを編集している)ので、UI更新は発生しない。
      categoryTabOfTournament.previouslySelectedTournamentId = tournamentId;
    }

    onSelectTournament(tournamentId);
  };

  return (
    <>
      <Index
        tabs={categorizedTabs.map((tab) => ({
          label: tab.label,
          value: tab.label,
        }))}
        selected={selectedCategory?.label || ""}
        onSelect={({ value }) => {
          const categorizedTab = categorizedTabs.find(
            (tab) => tab.label === value
          );
          if (categorizedTab) {
            onCategoryTabClick(categorizedTab);
          }
        }}
      />

      {!hideTournamentTabs && (
        <div className={styles.inner}>
          <ul className={styles.tournamentTabWrapper}>
            {selectedCategory?.tournamentTabs?.map((tournamentTab) => {
              const isSelected = selectedTournamentId === tournamentTab.id;
              return (
                <li
                  key={tournamentTab.displayName}
                  className={classNames(styles.tournamentTabItem, {
                    [styles.isActive]: isSelected,
                  })}
                  onClick={() => onTournamentTabClick(tournamentTab)}
                >
                  <span className={styles.tournament}>
                    {tournamentTab.displayName}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};
