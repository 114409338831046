import classNames from "classnames";
import * as React from "react";
import styles from "./index.module.css";

type CalendarDateProps = {
  date: number;
  onClickDay: () => void;
  disabled: boolean;
  active: boolean;
  hasMatch: boolean;
};

export const CalendarDate: React.FC<CalendarDateProps> = ({
  date,
  active,
  hasMatch,
  disabled,
  onClickDay,
}) => {
  return (
    <div
      className={classNames(styles.lineItem, { [styles.disabled]: disabled })}
    >
      <span
        className={classNames({
          [styles.active]: active,
          [styles.event]: !active && hasMatch,
        })}
        onClick={(): void => {
          if (!disabled) {
            onClickDay();
          }
        }}
      >
        {date}
      </span>
    </div>
  );
};
