import { useState } from "react";
import { createContainer } from "unstated-next";
import Auth from "@hooks/useAuth";
import { useUseUserPolicyConfirmationModalsController_CurrentUserQuery } from "@hooks/useUserPolicyConfirmationModalsController/__generated__/queries";
import useUserPolicyConfirmationModalController from "@hooks/useUserPolicyConfirmationModalsController/useUserPolicyConfirmationModalController";
import { POINT_TERMS, PRIVACY_POLICY_FOR_WEB } from "../../constants";

const useUserPolicyConfirmationModalsController = () => {
  const [isActive, setIsActive] = useState(false);
  const privacyPolicyConfirmationModalController =
    useUserPolicyConfirmationModalController(PRIVACY_POLICY_FOR_WEB);
  const pointTermsConfirmationModalController =
    useUserPolicyConfirmationModalController(POINT_TERMS);
  const { isFanstaUserRegisteredAndValidated } = Auth.useContainer();

  const { refetch } =
    useUseUserPolicyConfirmationModalsController_CurrentUserQuery({
      onCompleted: async (data) => {
        const modals = data?.currentUser.user?.confirmationModals ?? [];
        await privacyPolicyConfirmationModalController.showIfNeeded(modals);
        await pointTermsConfirmationModalController.showIfNeeded(modals);
      },
      skip: !(isFanstaUserRegisteredAndValidated && isActive),
    });

  const showIfNeeded = async () => {
    const { data } = await refetch();
    const modals = data?.currentUser.user?.confirmationModals ?? [];
    const isPrivacyPolicyModalShown =
      await privacyPolicyConfirmationModalController.showIfNeeded(modals);
    const isPointTermsModalShown =
      await pointTermsConfirmationModalController.showIfNeeded(modals);
    return isPrivacyPolicyModalShown || isPointTermsModalShown;
  };

  return {
    isPrivacyPolicyShown: privacyPolicyConfirmationModalController.isShown,
    isPointTermsShown: pointTermsConfirmationModalController.isShown,
    showIfNeeded,
    onPrivacyPolicyConfirm: privacyPolicyConfirmationModalController.onConfirm,
    onPointTermsConfirm: pointTermsConfirmationModalController.onConfirm,
    setIsActive,
  };
};

const UserPolicyConfirmationModalsController = createContainer(
  useUserPolicyConfirmationModalsController
);
export default UserPolicyConfirmationModalsController;
