import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import HeaderButtons from "@components/CommonLayout/CommonHeader/HeaderButtons";
import HeaderPoint from "@components/CommonLayout/CommonHeader/HeaderPoint";
import { useCommonHeader_CurrentUserQuery as useCurrentUserQuery } from "@components/CommonLayout/CommonHeader/__generated__/queries";
import Auth from "@hooks/useAuth";
import { paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import styles from "./index.module.css";

type HeaderProps = {
  headerFixed: boolean;
  noHeaderButton: boolean;
  hasHeaderPoints: boolean;
};

const CommonHeader: React.FC<HeaderProps> = ({
  headerFixed,
  noHeaderButton,
  hasHeaderPoints,
}) => {
  const router = useRouter();

  const {
    isCurrentUserReady,
    isFanstaUserReady,
    isFanstaUserRegisteredAndValidated,
  } = Auth.useContainer();
  const isAuthStatusFixed = isCurrentUserReady && isFanstaUserReady;

  const { data: currentUserData, error: currentUserError } =
    useCurrentUserQuery({
      skip:
        !hasHeaderPoints ||
        !isAuthStatusFixed ||
        !isFanstaUserRegisteredAndValidated,
    });
  if (currentUserError) {
    console.error(currentUserError);
  }

  const logoElement = (
    <Link href={paths.home}>
      <img
        src={IFImg("/img/logo_fansta.svg")}
        alt="スポーツバー検索・予約サービス Fansta"
      />
      <div className={styles.headerText}>Powered by DAZN</div>
    </Link>
  );

  return (
    <>
      <header
        className={`${styles.header} ${headerFixed ? styles.headerFixed : ""}`}
      >
        {router.pathname === paths.home ? (
          <h1 className={styles.headerLogo}>{logoElement}</h1>
        ) : (
          <div className={styles.headerLogo}>{logoElement}</div>
        )}
        {!noHeaderButton && (
          <HeaderButtons
            headerFixed={headerFixed}
            shouldShow={isAuthStatusFixed}
            isAuthStatusFixed={isAuthStatusFixed}
            isAuthenticated={isFanstaUserRegisteredAndValidated}
            shouldShowPoint={hasHeaderPoints}
            points={currentUserData?.currentUser.user?.points}
          />
        )}
      </header>
      {hasHeaderPoints && (
        <div className="md:none">
          <HeaderPoint
            isAuthStatusFixed={isAuthStatusFixed}
            isAuthenticated={isFanstaUserRegisteredAndValidated}
            points={currentUserData?.currentUser.user?.points}
          />
        </div>
      )}
    </>
  );
};

export default CommonHeader;
