import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import type { Exact } from "@graphql/__generated__/types";
import { auth } from "@libs/utils/firebase/auth";
import {
  useUseAuth_UpdateEmailMutation as useUpdateEmailMutation,
  useUseAuth_VerifyEmailMutation as useVerifyEmailMutation,
} from "./__generated__/mutations";
import { UseAuth_CurrentUserDocument as CurrentUserDocument } from "./__generated__/queries";
import type { UseAuth_CurrentUserQuery as CurrentUserQuery } from "./__generated__/queries";
import type { QueryLazyOptions } from "@apollo/client";
import type { IdTokenResult, User } from "firebase/auth";

type TokenResult = IdTokenResult;

type AuthContextProps = {
  currentUser: User | null | undefined;
  tokenResult: TokenResult | null | undefined;
  fanstaUser: CurrentUserQuery | null;
  loadFanstaUser: (
    options?: QueryLazyOptions<Exact<{ [key: string]: never }>>
  ) => void;
  isCurrentUserReady: boolean;
  isFanstaUserReady: boolean;
  isCurrentUserLoggedOut: boolean;
  isFanstaUserRegisteredAndValidated: boolean;
  isOnlyFirebaseUserRegistered: boolean;
};

export const hasValidToken = (tokenResult?: TokenResult | null): boolean => {
  return !!tokenResult && dayjs(tokenResult.expirationTime).isAfter(dayjs());
};

const INTERVAL = 20 * 60 * 1000;

const useAuth = (): AuthContextProps => {
  const [currentUser, setCurrentUser] = useState<User | null | undefined>(
    undefined
  );

  const [fanstaUser, setFanstaUser] = useState<CurrentUserQuery | null>(null);

  const [tokenResult, setTokenResult] = useState<
    TokenResult | null | undefined
  >(undefined);

  const client = useApolloClient();

  const loadFanstaUser = async () => {
    const response = await client.query({
      fetchPolicy: "no-cache",
      query: CurrentUserDocument,
    });
    console.info("[AuthProvider]: fansta user loaded");
    return setFanstaUser(response.data);
  };

  const [updateEmail] = useUpdateEmailMutation();
  const [verifyEmail] = useVerifyEmailMutation();

  const isCurrentUserReady = currentUser !== undefined;
  const isFanstaUserReady = !!fanstaUser;
  const isCurrentUserLoggedOut = currentUser === null;
  const isFanstaUserRegistered = !!fanstaUser?.currentUser.user;
  const isFanstaUserRegisteredAndValidated =
    isFanstaUserRegistered && hasValidToken(tokenResult);
  const isOnlyFirebaseUserRegistered =
    !!fanstaUser && !fanstaUser.currentUser.user && hasValidToken(tokenResult);

  useEffect(() => {
    const syncEmail = async () => {
      // ログアウト状態でのアドレス変更取り消しでFirebaseとDBとの間でメルアドの同期が取れなくなるケースへの対応
      if (
        !currentUser?.email ||
        !fanstaUser?.currentUser.user?.email ||
        currentUser?.email === fanstaUser?.currentUser.user?.email
      ) {
        return;
      }
      try {
        const user = await updateEmail({
          variables: {
            email: currentUser?.email || "",
          },
        });
        if (currentUser.emailVerified) {
          await verifyEmail({
            variables: {
              uuid: user.data?.updateEmail?.uuid || "",
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
      loadFanstaUser();
    };
    syncEmail();
  }, [currentUser, fanstaUser]);

  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(
      (user) => {
        if (!user) {
          setFanstaUser(null);
          setCurrentUser(null);
          setTokenResult(null);
          loadFanstaUser();
          console.info("[AuthProvider]: signout");
          return;
        }

        const set = async (): Promise<void> => {
          let tokenResult = await user.getIdTokenResult();

          // ログアウトせずにブラウザを閉じるなどしてトークンの有効期限が切れた場合はリフレッシュトークンを使ってトークンを更新
          if (!hasValidToken(tokenResult)) {
            tokenResult = await user.getIdTokenResult(true);
          }

          setFanstaUser(null);
          setCurrentUser(user);
          setTokenResult(tokenResult);
          loadFanstaUser();
          console.info("[AuthProvider]: signin");
        };
        set();
      },
      () => {
        setFanstaUser(null);
      }
    );
    return (): void => unsubscribe();
  }, []);

  // 定期的にトークンをリフレッシュして操作中に突然トークンの有効期限が切れて無効になるのを防ぐ
  // https://colinhacks.com/essays/nextjs-firebase-authentication
  useEffect(() => {
    const handler = setInterval(async () => {
      if (currentUser) {
        await currentUser.getIdToken(true);
      }
    }, INTERVAL);
    return (): void => clearInterval(handler);
  }, [currentUser]);

  return {
    currentUser,
    fanstaUser,
    tokenResult,
    loadFanstaUser,
    isCurrentUserReady,
    isFanstaUserReady,
    isCurrentUserLoggedOut,
    isFanstaUserRegisteredAndValidated,
    isOnlyFirebaseUserRegistered,
  };
};

const Auth = createContainer(useAuth);

export default Auth;
