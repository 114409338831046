import { useMemo, useState } from "react";
import type { UseCommonData_UsersTournamentFragment as UsersTournamentFragment } from "@hooks/useCommonData/__generated__/fragments";
import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import type { SearchInput_TeamsQuery } from "./__generated__/queries";
import type { Dispatch, SetStateAction } from "react";

type UseTeamSelectionProps = {
  tournaments: UseCommonData_CommonDataQuery["tournaments"];
  teams: SearchInput_TeamsQuery["teamsUnbundled"];
  activeTournamentId?: number;
  initialSelectedTeamIds?: number[];
  initialTournamentTbaSelected?: number[];
};

export type TeamSelection = {
  selectedTeamIds: Set<number>;
  tournamentsTbaSelected: Set<number>;
  activeTournament: UsersTournamentFragment | undefined;
  tba:
    | {
        isSelected: boolean;
        toggle: () => void;
        select: () => void;
        unselect: () => void;
      }
    | undefined;
  isSelectedTeam: (teamId: number) => boolean;
  isSelectedTournament: (tournamentId: number) => boolean;
  isClearable: boolean;
  toggleTeamSelection: (teamId: number) => void;
  toggleTournamentSelection: (tournamentId: number) => void;
  setSelectedTeamIds: Dispatch<SetStateAction<Set<number>>>;
  setTournamentsTbaSelected: Dispatch<SetStateAction<Set<number>>>;
  clearAll: () => void;
};

export const useTeamSelection = ({
  tournaments,
  teams,
  activeTournamentId,
  initialSelectedTeamIds,
  initialTournamentTbaSelected,
}: UseTeamSelectionProps): TeamSelection => {
  const [selectedTeamIds, setSelectedTeamIds] = useState<Set<number>>(
    new Set(initialSelectedTeamIds ?? [])
  );
  const [tournamentsTbaSelected, setTournamentsTbaSelected] = useState<
    Set<number>
  >(new Set(initialTournamentTbaSelected ?? []));
  const activeTournament = useMemo(
    () => tournaments.find((t) => t.id === activeTournamentId),
    [activeTournamentId, tournaments]
  );

  const getTeamsFromTournament = (tournamentId: number) =>
    teams.filter(
      (team) =>
        team.masterTournaments?.some(
          (tournament) => tournament.id === tournamentId
        )
    );
  const isSelectedTeam = (teamId: number) => selectedTeamIds.has(teamId);
  const isTournamentTbaSelected = (tournamentId: number) =>
    tournamentsTbaSelected.has(tournamentId);
  // (トーナメント内の全チームが選択されている && TBAがある場合はTBAも選択されている)場合にトーナメントが選択されているとみなす
  const isSelectedTournament = (tournamentId: number) => {
    const teamsOfTournament = getTeamsFromTournament(tournamentId);
    if (!teamsOfTournament.every((t) => isSelectedTeam(t.id))) {
      return false;
    }

    const hasTba =
      tournaments.find((t) => t.id === tournamentId)?.hasTba ?? false;
    return !hasTba || isTournamentTbaSelected(tournamentId);
  };

  const selectTeams = (teamIds: number[]) => {
    setSelectedTeamIds(new Set([...selectedTeamIds, ...teamIds]));
  };
  const unselectTeams = (teamIds: number[]) => {
    setSelectedTeamIds(
      new Set([...selectedTeamIds].filter((i) => !teamIds.includes(i)))
    );
  };

  const selectTba = (tournamentId: number) => {
    setTournamentsTbaSelected(
      new Set([...tournamentsTbaSelected, tournamentId])
    );
  };
  const unselectTba = (tournamentId: number) => {
    setTournamentsTbaSelected(
      new Set([...tournamentsTbaSelected].filter((t) => t !== tournamentId))
    );
  };

  // TBAが存在するリーグが選択されていれば、TBA関連の操作を提供する
  // 存在しないリーグであればNullを返す
  const tba = useMemo(() => {
    if (!activeTournament?.hasTba) {
      return undefined;
    }

    return {
      isSelected: isTournamentTbaSelected(activeTournament.id),
      toggle: () => {
        (isTournamentTbaSelected(activeTournament.id)
          ? unselectTba
          : selectTba)(activeTournament.id);
      },
      select: () => selectTba(activeTournament.id),
      unselect: () => unselectTba(activeTournament.id),
    };
  }, [tournaments, activeTournamentId, tournamentsTbaSelected]);

  const selectTournament = (tournamentId: number) => {
    const teamsOfTournament = getTeamsFromTournament(tournamentId);
    selectTeams(teamsOfTournament.map((t) => t.id));
    if (tournaments.find((t) => t.id === tournamentId)?.hasTba) {
      selectTba(tournamentId);
    }
  };
  const unselectTournament = (tournamentId: number) => {
    const teamsOfTournament = getTeamsFromTournament(tournamentId);
    unselectTeams(teamsOfTournament.map((t) => t.id));
    if (tournaments.find((t) => t.id === tournamentId)?.hasTba) {
      unselectTba(tournamentId);
    }
  };

  const toggleTeamSelection = (teamId: number) => {
    (isSelectedTeam(teamId) ? unselectTeams : selectTeams)([teamId]);
  };

  const toggleTournamentSelection = (tournamentId: number) => {
    (isSelectedTournament(tournamentId)
      ? unselectTournament
      : selectTournament)(tournamentId);
  };

  const isClearable = useMemo(
    () => selectedTeamIds.size !== 0 || tournamentsTbaSelected.size !== 0,
    [selectedTeamIds, tournamentsTbaSelected]
  );
  const clearAll = () => {
    setSelectedTeamIds(new Set());
    setTournamentsTbaSelected(new Set());
  };

  return {
    selectedTeamIds,
    tournamentsTbaSelected,
    activeTournament,
    tba,
    isSelectedTeam,
    isSelectedTournament,
    isClearable,
    toggleTeamSelection,
    toggleTournamentSelection,
    setSelectedTeamIds,
    setTournamentsTbaSelected,
    clearAll,
  };
};
