import _ from "lodash";
import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import type { Teams_TeamsQuery as TeamsQuery } from "./__generated__/queries";

export const getTeamIdsByTournament = (
  allTeams: TeamsQuery["teamsUnbundled"]
): Record<string, Array<number>> => {
  return _.transform(
    allTeams,
    (acc, team) => {
      const tournamentIds = _.map(team.masterTournaments, "id");
      _.forEach(tournamentIds, (tournamentId) => {
        if (!acc[tournamentId]) {
          acc[tournamentId] = [];
        }
        acc[tournamentId].push(team.id);
      });
    },
    {} as Record<string, Array<number>>
  );
};
export const getIsSomeTeamInTheTournamentSelected = (
  allTeams: TeamsQuery["teamsUnbundled"],
  selectedTeamIds: number[],
  tournamentId: number
): boolean => {
  const teamIdsByTournament = getTeamIdsByTournament(allTeams);
  if (!teamIdsByTournament[tournamentId]) {
    return false;
  }
  return (
    _.intersection(teamIdsByTournament[tournamentId], selectedTeamIds).length >
    0
  );
};
export const getIsEveryTeamInTheTournamentSelected = (
  allTeams: TeamsQuery["teamsUnbundled"],
  selectedTeamIds: number[],
  tournamentId: number
): boolean => {
  const teamIdsByTournament = getTeamIdsByTournament(allTeams);
  if (!teamIdsByTournament[tournamentId]) {
    return false;
  }
  // SEE: [Find if array includes all elements from another array · Issue #1743 · lodash/lodash](https://github.com/lodash/lodash/issues/1743)
  return (
    _.difference(teamIdsByTournament[tournamentId], selectedTeamIds).length ===
    0
  );
};
type ReducedTeamIdsByTournament = {
  tournaments: UseCommonData_CommonDataQuery["tournaments"];
  teams: TeamsQuery["teamsUnbundled"];
};
// TeamIdsの配列から、どのトーナメント・チームが選択状態かを返却する関数。
export const reduceTeamsByTournament = (
  allTeams: TeamsQuery["teamsUnbundled"],
  tournaments: UseCommonData_CommonDataQuery["tournaments"],
  teamIds: number[]
): ReducedTeamIdsByTournament => {
  let nextTeamIds = _.clone(teamIds);
  const teamIdsByTournament = getTeamIdsByTournament(allTeams);
  if (_.isEmpty(tournaments)) {
    return {
      tournaments: [],
      teams: [],
    };
  }

  const selectedTournaments = _.filter(tournaments, (tournament) => {
    const teamIdsByThisTournament = teamIdsByTournament[tournament.id];
    const isSelected = getIsEveryTeamInTheTournamentSelected(
      allTeams,
      teamIds,
      tournament.id
    );
    if (isSelected) {
      nextTeamIds = _.difference(nextTeamIds, teamIdsByThisTournament);
    }
    return isSelected;
  });

  return {
    tournaments: selectedTournaments,
    teams: _.filter(allTeams, (team) => _.includes(nextTeamIds, team.id)),
  };
};

// トーナメントIDとチームIDの複合的な指定からをチームIDのみに還元する関数
export const parseTeamIdsFromReduced = (
  allTeams: TeamsQuery["teamsUnbundled"],
  tournamentIds: number[],
  rawTeamIds: number[]
): Array<number> => {
  const teamIdsByTournament = getTeamIdsByTournament(allTeams);
  return _.union(
    _.reduce(
      // TournamentIdを元に、チームIDの一覧を取得しマージする。
      tournamentIds,
      (acc, tournamentId) => {
        const teamIds = teamIdsByTournament[tournamentId];
        return _.union(acc, teamIds);
      },
      [] as Array<number>
    ),
    rawTeamIds
  );
};

export type TournamentTab = {
  displayName: string;
  id: number;
};

export type CategoryTab = {
  label: string;
  previouslySelectedTournamentId: number;
  tournamentTabs: TournamentTab[];
};

export const getCategorizedTabs = (
  tournaments: UseCommonData_CommonDataQuery["tournaments"] | undefined
): CategoryTab[] => {
  return _.map(
    _.groupBy(
      tournaments,
      (tournament) => tournament.masterTournamentCategory.label
    ),
    (tournaments, category) => {
      // TournamentTab[]
      const children = tournaments.map(
        (tournament): TournamentTab => ({
          id: tournament.id,
          displayName: tournament.displayName,
        })
      );
      // CategoryTab
      return {
        label: category,
        previouslySelectedTournamentId: children[0].id,
        tournamentTabs: children,
      };
    }
  );
};

// 選択されたtournamentIdを元に選択されたtabを見つける。
export const getCategoryByTournamentId = (
  categorizedTabs: CategoryTab[],
  tournamentId: number | null | undefined
): CategoryTab | undefined =>
  _.find(categorizedTabs, ({ tournamentTabs }) => {
    return Boolean(_.find(tournamentTabs, ({ id }) => id === tournamentId));
  });
