import Link from "next/link";
import { useState } from "react";
import { Button } from "@components/Button";
import FanstaDialog from "@components/FanstaDialog";
import { paths } from "@libs/paths";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  onConfirm: () => Promise<void>;
  isOpen: boolean;
};

export const PrivacyPolicyConfirmationModal: FC<Props> = ({
  onConfirm,
  isOpen,
}) => {
  const [isTermsConfirmed, setIsTermsConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCheckboxChange = () => {
    setIsTermsConfirmed(!isTermsConfirmed);
  };

  return (
    <FanstaDialog isShown={isOpen}>
      <p className={styles.dialogBoxTitle}>プライバシーポリシー同意のお願い</p>
      <p className={styles.dialogBoxText}>
        サービスを利用するには内容をご確認のうえ、同意してください。
      </p>
      <div className={styles.dialogBoxConfirm}>
        <label className={styles.checkbox}>
          <input
            type="checkbox"
            checked={isTermsConfirmed}
            onChange={handleCheckboxChange}
          />
          <div className={styles.checkboxText}>
            <Link href={paths.privacyPolicy} target="_blank">
              プライバシーポリシー
            </Link>
            に同意
          </div>
        </label>
      </div>
      <div className={styles.dialogBoxButton}>
        <Button
          className={styles.accept}
          onClick={async () => {
            setIsSubmitting(true);
            await onConfirm();
            setIsSubmitting(false);
          }}
          styleType="filled"
          colorType="primary"
          disabled={!isTermsConfirmed || isSubmitting}
        >
          同意する
        </Button>
      </div>
    </FanstaDialog>
  );
};
