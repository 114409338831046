import {
  UserPolicyTypeEnum,
  UserPolicyVersionEnum,
} from "@graphql/__generated__/types";

export const TOP_TITLE =
  "Fansta(ファンスタ) - スポーツバー検索・予約サービス【DAZN公認】";

/**
 * FanstaアプリのWebViewからのアクセス時にUserAgentに含まれる文字列
 */
export const USER_AGENT_FANSTA_APP = "FanstaWebView";
export const USER_AGENT_IOS_REGEX = /iPhone|iPad|iPod/;
export const USER_AGENT_ANDROID_REGEX = /Android/i;

/**
 * 同意が必要な最新のポリシー情報
 */
export const PRIVACY_POLICY_FOR_WEB = {
  policyType: UserPolicyTypeEnum.Privacy,
  policyVersion: UserPolicyVersionEnum.V2022_10_24Web,
};

const PRIVACY_POLICY_FOR_APP = {
  policyType: UserPolicyTypeEnum.Privacy,
  policyVersion: UserPolicyVersionEnum.V2022_10_24App,
};

export const POINT_TERMS = {
  policyType: UserPolicyTypeEnum.Points,
  policyVersion: UserPolicyVersionEnum.V2023_11_17,
};

export const USER_POLICIES_FOR_WEB = [PRIVACY_POLICY_FOR_WEB, POINT_TERMS];
export const USER_POLICIES_FOR_APP = [PRIVACY_POLICY_FOR_APP, POINT_TERMS];
