import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import type { SearchCondition } from "@hooks/useSearchCondition";
import { getTerminatingStaticURLParameters } from "@libs/rewrite";
import { formats } from "@libs/utils/date";
import { getDynamicTeam, seoTournamentName, trimSpaces } from "./common";
import type {
  Og_MatchesStaticUrlParamsQuery as MatchesStaticUrlParamsQuery,
  Og_TeamsQuery as TeamsQuery,
} from "./__generated__/queries";
import type { PagesMatches_MatchesQuery } from "../../../pages/matches/__generated__/queries";
import type { QueryResult } from "@apollo/client";
import type { Dayjs } from "dayjs";
import type { ParsedUrlQuery } from "querystring";

const getOgFromPrefix = (
  dynamicPrefix: string,
  baseYmd?: Dayjs
): [string, string] => {
  const descriptionPrefix = dynamicPrefix
    ? `${dynamicPrefix}の試合を観戦`
    : "スポーツ観戦が";
  const description = `${descriptionPrefix}できるスポーツバーを掲載中！試合日程、近くのエリア、好きなチームから見たい試合を上映しているお店を探して予約するならFansta(ファンスタ)。スポーツ観戦・スポーツライブビューイングを楽しもう。`;

  if (!dynamicPrefix) {
    return ["試合日程", description];
  }

  const baseYmdText = baseYmd ? `：${baseYmd.format(formats["ymTrim"])}` : "";
  const title = `${dynamicPrefix}` + baseYmdText + "の試合日程";

  return [title, description];
};

const getOgFromSearchCondition = (
  searchCondition: SearchCondition,
  data: QueryResult<
    PagesMatches_MatchesQuery & TeamsQuery & UseCommonData_CommonDataQuery
  >["data"],
  baseYmd?: Dayjs
): [string, string] => {
  const dynamicPrefix = trimSpaces(
    getDynamicTeam(searchCondition, data?.teamsUnbundled || []) || ""
  );

  return getOgFromPrefix(dynamicPrefix, baseYmd);
};

const getStaticPrefix = (
  query: ParsedUrlQuery,
  data: MatchesStaticUrlParamsQuery
) => {
  const { team } = getTerminatingStaticURLParameters(query);
  switch (team) {
    case undefined:
      return "";
    case "genre":
      return `${data.sportGenres[0].name}`;
    case "tournament":
      return `${seoTournamentName(data.tournaments)}`;
    case "team":
      return `${data.teamsUnbundled[0].name}`;
    default:
      return "";
  }
};

const getOgFromParsedUrlQuery = (
  query: ParsedUrlQuery,
  data: MatchesStaticUrlParamsQuery,
  baseYmd?: Dayjs
) => {
  const staticPrefix = getStaticPrefix(query, data);

  return getOgFromPrefix(staticPrefix, baseYmd);
};

export {
  getOgFromPrefix,
  getOgFromSearchCondition,
  getStaticPrefix,
  getOgFromParsedUrlQuery,
};
