import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";

dayjs.extend(isSameOrAfter);
dayjs.extend(weekOfYear);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("ja");

// fansta では6時に日付の切り替えをする
export const FanstaDayEnd = 6;

// dayjsの設定値
const SundayNum = 0;
const SaturdayNum = 6;

// default timezone
export const defaultTimeZone = "Asia/Tokyo";

export const fanstaToday = (): dayjs.Dayjs => {
  const tmpNow = dayjs().tz(defaultTimeZone);
  return tmpNow.hour() < FanstaDayEnd
    ? tmpNow.add(-1, "days").hour(FanstaDayEnd)
    : tmpNow;
};

// API側の同様の関数名に合わせた名前にしています
// 2時間前(表示する試合の条件)dayjsインスタンス(主に試合の絞り込み用)
export const baseTimeForMatches = (): dayjs.Dayjs => {
  return dayjs().add(-2, "hours").tz(defaultTimeZone);
};

export const timestampForCypress = (): number => dayjs().endOf("day").valueOf();

export const convertDayjs = (src: string | Date | dayjs.Dayjs): dayjs.Dayjs => {
  return dayjs(src).tz(defaultTimeZone);
};

export const formats = {
  default: "YYYY/MM/DD H:mm",
  ym: "YYYY年 M月",
  ymTrim: "YYYY年M月",
  ymdJp: "YYYY年M月D日",
  ymdJpBlank: "YYYY年 M月 D日",
  ymdOnly: "YYYYMMDD",
  ymdHyphen: "YYYY-MM-DD",
  month: "YYYYMM",
  date: "M月D日 (ddd)",
  dateWithoutSpace: "M月D日(ddd)",
  dateOnly: "M月D日",
  dateOnlyEn: "M/D",
  dateEn: "M/D (ddd)",
  dateEnNarrow: "M/D(ddd)",
  time: "H:mm",
  weekOnly: "dd",
  match: "M月D日 H:mm",
  asOf: "YYYY年M月D日時点",
  reservationDate: "YYYY/M/D(ddd)",
};

export const toLocaleStr = (
  str: string | dayjs.Dayjs,
  formatName: keyof typeof formats = "default",
  locale = "ja"
): string => {
  if (typeof str === "string") {
    str = convertDayjs(str);
  }
  return str.locale(locale).format(formats[formatName]);
};

const padZero = (num: number) => (num < 10 ? `0${num}` : num);

export const toJpDate = (
  date: string | Date | dayjs.Dayjs,
  formatName: keyof typeof formats = "dateEn",
  locale = "ja"
): string => {
  let day = convertDayjs(date);
  if (day.hour() < FanstaDayEnd) {
    day = day.subtract(1, "day");
  }
  return toLocaleStr(day, formatName, locale).toUpperCase();
};

export const toJpTime = (date: string | Date): string => {
  const day = convertDayjs(date);
  let hour = day.hour();
  const minute = day.minute();
  if (hour < FanstaDayEnd) {
    hour += 24;
  }
  return `${hour}:${padZero(minute)}`;
};

export const toJpReservationDatetime = (
  datetime: string | Date,
  formatName: keyof typeof formats = "reservationDate"
): string => {
  return `${toJpDate(datetime, formatName)} ${toJpTime(datetime)}`;
};

export const isSunday = (now: dayjs.Dayjs): boolean => {
  return now.day() === SundayNum;
};

export const isSaturday = (now: dayjs.Dayjs): boolean => {
  return now.day() === SaturdayNum;
};

export const isHoliday = (
  targetDay: dayjs.Dayjs,
  holidays: dayjs.Dayjs[]
): boolean => {
  return !!holidays.find(
    (d) => toLocaleStr(d, "ymdOnly") === toLocaleStr(targetDay, "ymdOnly")
  );
};

export const weekDays = ja.weekdaysShort || [];

export { dayjs };
