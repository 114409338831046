import { createSignalIfSupported, useApolloClient } from "@apollo/client";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { isRouterTrulyReady } from "@hooks/useIsRouterReady";
import { UseCommonData_CommonDataDocument as CommonDataDocument } from "./__generated__/queries";
import type { UseCommonData_CombinedAreaListItemFragment as CombinedAreaListItemFragment } from "./__generated__/fragments";
import type { UseCommonData_CommonDataQuery as CommonDataQuery } from "./__generated__/queries";

// 全ページで共通のデータをbatchで取得するためのhook
const useCommonData = (): {
  data: CommonDataQuery | undefined;
  combinedAreas: Array<CombinedAreaListItemFragment>;
  loading: boolean;
} => {
  const [result, setResult] = useState({
    data: undefined,
    combinedAreas: [] as Array<CombinedAreaListItemFragment>,
    loading: true,
  });
  const router = useRouter();
  const client = useApolloClient();

  const isReady = isRouterTrulyReady(router);

  // 何故かクエリが重複するので、useCommonDataQueryを使わない。
  // TODO: これやらずに済むならそのように修正する。
  useEffect(() => {
    if (!isReady) {
      return;
    }

    // SEE: [Cancellable apollo-client queries :: OpenHood](https://experiments.openhood.com/apollo-client/typescript/2020/10/23/apollo-client-cancellable-queries/)
    const { controller, signal } = createSignalIfSupported();

    (async () => {
      const context = {
        // Authが有効だとクエリが遅くなるので、公開APIの場合は明示的にskipする。
        skipAuth: true,
        fetchOptions: {},
      };

      if (signal) {
        context.fetchOptions = { signal };
      }

      // クエリのタイミングを細かく制御するために、useQueryを使わない。
      const { data } = await client.query({
        query: CommonDataDocument,
        fetchPolicy: "cache-first",
        // errorPolicy = "all | ignore" でないとエラーハンドラのnetworkErrorがnullになるので、強制的に "all" を指定する。
        // SEE: https://www.apollographql.com/docs/react/data/error-handling/#graphql-error-policies
        errorPolicy: "all",
        context,
      });

      const combinedAreas = _.compact(
        _.flatten(
          _.map(data?.masterPrefectures, (prefecture) => {
            return prefecture?.combinedAreas || [];
          })
        )
      );

      setResult({
        data: data,
        combinedAreas,
        loading: false,
      });

      return null;
    })();

    return () => {
      if (controller) {
        if (!(controller instanceof AbortController)) {
          return;
        }
        // Abort pending GQL request if available.
        controller.abort();
      }
    };
  }, [isReady]);

  return result;
};

const CommonDataState = createContainer(useCommonData);

export { CommonDataState };
export default CommonDataState;
