import { getDatabase, onValue, ref } from "firebase/database";
import { app } from "@libs/utils/firebase/config";
import type { DataSnapshot } from "firebase/database";

const db = getDatabase(app);

const environment = process.env.DEPLOY_ENV || "local";

const userNotificationBasePath = `${environment}/user_notifications`;

const subscribeUserNotificationAllUpdatedAtRef = (
  callback: (snapshot: DataSnapshot) => void
) => {
  const userNotificationsAllUpdatedAtRef = ref(
    db,
    `${userNotificationBasePath}/all/updated_at`
  );
  return onValue(userNotificationsAllUpdatedAtRef, callback);
};

export { subscribeUserNotificationAllUpdatedAtRef };
