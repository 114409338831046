import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useRouter } from "next/router";
import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Button } from "@components/Button";
import { LeftAlignedTitle } from "@components/LeftAlignedTitle";
import { PredefinedToolTips } from "@components/ToolTip";
import { RequestReservationSteps } from "@components/pages/shops/[uuid]/request_reservation/RequestReservationSteps";
import { ReservationPaymentNewCard } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentNewCard";
import { ReservationPaymentOnSite } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentOnSite";
import { ReservationPaymentSavedCard } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentSavedCard";
import { ReservationPlanAllowedPaymentTypeEnum } from "@graphql/__generated__/types";
import Message, { PredefinedMessages } from "@hooks/useMessage";
import RequestReservation from "@hooks/useRequestReservation";
import { paths } from "@libs/paths";
import { rollbar } from "@libs/utils/rollbar";
import styles from "./index.module.css";
import type { PaymentMethods_PaymentCardsQuery } from "@pages/shops/[uuid]/request_reservation/payment_methods/__generated__/queries";
import type {
  StripeError,
  StripePaymentElementChangeEvent,
} from "@stripe/stripe-js";
import type { FC } from "react";

export enum StaticPaymentMethodEnum {
  OnSite = "ON_SITE",
  NewCard = "NEW_CARD",
}

export type PaymentMethodInputType = {
  method: StaticPaymentMethodEnum | string; // "NEW_CARD" | "ON_SITE" | "stripePaymentMethodId(pm_***)" のどれかが入る。結局型はstringになってユニオンではないが、明示のためEnumも入れている
  savesNewCard: boolean;
};

type Props = {
  savedCards: PaymentMethods_PaymentCardsQuery["paymentCards"];
};

export const ReservationPaymentForm: FC<Props> = ({ savedCards }) => {
  const router = useRouter();
  const stripe = useStripe();
  const elements = useElements();

  const { addMessage } = Message.useContainer();

  const shopUuid =
    typeof router.query.uuid === "string" ? router.query.uuid : "";
  const { requestReservation, setRequestReservation } =
    RequestReservation.useContainer();
  const plan = requestReservation.reservationFrame?.reservationPlan;

  const showPaymentMethods = (() => {
    switch (plan?.allowedPaymentType) {
      case ReservationPlanAllowedPaymentTypeEnum.Any:
        return {
          onSystem: true,
          onSite: true,
        };
      case ReservationPlanAllowedPaymentTypeEnum.OnSystem:
        return {
          onSystem: true,
          onSite: false,
        };
      case ReservationPlanAllowedPaymentTypeEnum.OnSite:
        return {
          onSystem: false,
          onSite: true,
        };
      default: {
        return {
          onSystem: false,
          onSite: false,
        };
      }
    }
  })();

  const methods = useForm<PaymentMethodInputType>({
    defaultValues: {
      method:
        requestReservation.paymentMethod ??
        (savedCards.items.length > 0
          ? savedCards.items[0].stripePaymentMethodId
          : StaticPaymentMethodEnum.NewCard),
      savesNewCard: false,
    },
  });
  const { handleSubmit, control } = methods;
  const selectedPaymentMethod = useWatch({ control, name: "method" });
  const [isNewCardValid, setIsNewCardValid] = useState(false);
  const isValid = (() => {
    switch (selectedPaymentMethod) {
      case StaticPaymentMethodEnum.NewCard:
        return isNewCardValid;
      case StaticPaymentMethodEnum.OnSite:
        return true;
      default: {
        const selectedSavedCard = savedCards.items.find(
          (card) => card.stripePaymentMethodId === selectedPaymentMethod
        );
        return selectedSavedCard && !selectedSavedCard.isExpired;
      }
    }
  })();

  const handlePaymentElementChange = (
    event: StripePaymentElementChangeEvent
  ) => {
    setIsNewCardValid(event.complete);
  };

  const handleStripeError = (error: StripeError) => {
    switch (error.type) {
      case "validation_error":
        // バリデーションエラーの場合はフォームにメッセージが表示されるので何もしない
        return;
      default:
        addMessage(PredefinedMessages.commonError);
        rollbar?.error(error);
        return;
    }
  };

  const handleNextButton = async (input: PaymentMethodInputType) => {
    // 現地決済の場合
    if (input.method === StaticPaymentMethodEnum.OnSite) {
      setRequestReservation({
        ...requestReservation,
        paymentMethod: input.method,
        confirmationToken: undefined,
        creditCard: undefined,
        savesNewCard: false,
      });
      router.push(paths.shopRequestReservationConfirm(shopUuid));
      return;
    }

    // 保存済みのカードの場合
    if (input.method !== StaticPaymentMethodEnum.NewCard) {
      const creditCard = savedCards.items.find(
        (card) => card.stripePaymentMethodId === input.method
      );
      setRequestReservation({
        ...requestReservation,
        paymentMethod: input.method,
        confirmationToken: undefined,
        creditCard,
        savesNewCard: false,
      });
      router.push(paths.shopRequestReservationConfirm(shopUuid));
      return;
    }

    // 新規カードの場合
    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleStripeError(submitError);
      return;
    }
    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: {
            address: {
              country: "JP",
            },
          },
        },
      },
    });

    if (error) {
      handleStripeError(error);
      return;
    }

    setRequestReservation({
      ...requestReservation,
      paymentMethod: input.method,
      confirmationToken: confirmationToken.id,
      creditCard: confirmationToken?.payment_method_preview.card,
      savesNewCard: input.savesNewCard,
    });
    router.push(paths.shopRequestReservationConfirm(shopUuid));
    return;
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleNextButton)}>
        <RequestReservationSteps step={2} showsStep2={true} />

        <section className={styles.formSection}>
          <div className={styles.title}>
            <LeftAlignedTitle
              label={"お支払い方法"}
              toolTip={PredefinedToolTips.reservationPaymentMethods}
            />
          </div>

          <div className={styles.secureText}>
            すべての取引は安全で、暗号化されています。
          </div>

          <div className={styles.paymentMethodsItems}>
            {savedCards.items.map((card) => (
              <ReservationPaymentSavedCard
                card={card}
                key={card.stripePaymentMethodId}
              />
            ))}
            {showPaymentMethods.onSystem && savedCards.canSaveMoreCards && (
              <ReservationPaymentNewCard
                handlePaymentElementChange={handlePaymentElementChange}
              />
            )}
            {showPaymentMethods.onSite && <ReservationPaymentOnSite />}
          </div>

          {/*TODO: [事前決済] ポイント還元率の変更 のPBIで実装する*/}
          {/*<div className={styles.pointsBreakdown}>*/}
          {/*  <a href="#">獲得ポイントの内訳</a>*/}
          {/*</div>*/}
        </section>

        {/*TODO: [事前決済] ポイントの使用 のPBIで実装する*/}
        {/*<section className={styles.formSection}>*/}
        {/*  <div className={styles.title}>*/}
        {/*    <LeftAlignedTitle*/}
        {/*      label={"Fanstaポイントの利用"}*/}
        {/*    />*/}
        {/*  </div>*/}

        {/*  <div className={styles.availablePoint}>*/}
        {/*    <div className={styles.availablePointLabel}>*/}
        {/*      ご利用可能ポイント*/}
        {/*    </div>*/}
        {/*    <div className={styles.availablePointNumber}>*/}
        {/*      <em>1,000</em> pt*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className={styles.pointItems}>*/}
        {/*    <ReservationPaymentsPointItem*/}
        {/*      label="全てのポイントを利用"*/}
        {/*      type="all"*/}
        {/*    />*/}

        {/*    <ReservationPaymentsPointItem*/}
        {/*      label="ポイントの一部を利用"*/}
        {/*      type="specify"*/}
        {/*    />*/}

        {/*    <ReservationPaymentsPointItem*/}
        {/*      label="ポイントを利用しない"*/}
        {/*      type="not-used"*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*</section>*/}

        <div>
          <Button
            className={styles.btn}
            styleType="filled"
            colorType="primary"
            type="submit"
            disabled={!isValid}
          >
            次へ
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
