import classNames from "classnames";
import _ from "lodash";
import * as React from "react";
import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import { getIsEveryTeamInTheTournamentSelected } from "@libs/utils/teams";
import styles from "./index.module.css";
import type { SearchInput_TeamsQuery } from "../__generated__/queries";

export type FilterLabelProps = {
  defaultLabel?: string;
  tournaments: UseCommonData_CommonDataQuery["tournaments"];
  allTeams: SearchInput_TeamsQuery["teamsUnbundled"];
  selectedTeamIds: number[];
  selectedTournamentIdsTba: number[];
};

const SEPARATOR = ",";

const TBA = "未定";

// ネストした `SEPARATOR` 区切りのテキストをuniq&flatにする関数
const flattenAndUniq = (strings: string[]): string[] => {
  return _.compact(
    _.uniq(_.flatten(_.map(strings, (str) => str.split(SEPARATOR))))
  );
};

export const getFilterLabel = ({
  defaultLabel,
  selectedTeamIds,
  tournaments,
  allTeams,
  selectedTournamentIdsTba,
}: FilterLabelProps) => {
  let filterBtnLabel = defaultLabel;

  if (!_.isEmpty(selectedTeamIds) && allTeams) {
    const labels: string[] = [];

    const selectedTeams = allTeams.filter((team) =>
      _.includes(selectedTeamIds, team.id)
    );

    // 1. まずリーグ名の選択状況を反映する。
    _.each(tournaments, ({ id: tournamentId, name }) => {
      if (
        !getIsEveryTeamInTheTournamentSelected(
          allTeams,
          selectedTeamIds,
          tournamentId
        )
      ) {
        return;
      }
      labels.push(name);
    });

    // 2. その後、チーム名の選択状況を反映する。
    _.each(tournaments, ({ id: tournamentId }) => {
      // 1で探索済みのデータは無視する。
      if (
        getIsEveryTeamInTheTournamentSelected(
          allTeams,
          selectedTeamIds,
          tournamentId
        )
      ) {
        return;
      }
      // チームが所属しているトーナメントが複数あり、そのうちの1つ以上のトーナメントが既に全選択されている場合はスキップ
      const selectedTeamsOfTheTournament = selectedTeams.filter(
        (team) =>
          team.masterTournaments?.find((t) => t.id === tournamentId) &&
          !team.masterTournaments?.find((t) =>
            getIsEveryTeamInTheTournamentSelected(
              allTeams,
              selectedTeamIds,
              t.id
            )
          )
      );
      labels.push(_.map(selectedTeamsOfTheTournament, "name").join(SEPARATOR));
    });

    filterBtnLabel = flattenAndUniq(labels).join(SEPARATOR);
  }

  if (!_.isEmpty(selectedTournamentIdsTba)) {
    _.some(selectedTournamentIdsTba, (tbaTournamentId) => {
      // Omit `TBA` label if all teams in the tournament are selected.
      if (
        getIsEveryTeamInTheTournamentSelected(
          allTeams,
          selectedTeamIds,
          tbaTournamentId
        )
      ) {
        return false;
      }
      // changes were made
      if (filterBtnLabel !== defaultLabel) {
        filterBtnLabel = filterBtnLabel += `,${TBA}`;
      } else {
        filterBtnLabel = TBA;
      }
      return true;
    });
  }

  return filterBtnLabel;
};

export const FilterLabel: React.FC<FilterLabelProps> = (props) => {
  const defaultLabel = props.defaultLabel || "条件で絞り込む";

  const filterBtnLabel = getFilterLabel({
    ...props,
    defaultLabel,
  });

  return (
    <select
      className={classNames(
        {
          [styles.isEmpty]: filterBtnLabel === defaultLabel,
        },
        styles.filterLabel,
        styles.textEllipsis
      )}
      name="team"
      tabIndex={-1}
    >
      <option value="">{filterBtnLabel}</option>
    </select>
  );
};
