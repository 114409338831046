import Bowser from "bowser";
import classNames from "classnames";
import _ from "lodash";
import { useState, useMemo, useEffect } from "react";
import * as React from "react";
import { CalendarDate } from "@components/Calender/CalendarDate";
import {
  toLocaleStr,
  dayjs,
  weekDays,
  formats,
  defaultTimeZone,
} from "@libs/utils/date";
import styles from "./index.module.css";

type CalendarProps = {
  today: dayjs.Dayjs;
  month: string;
  matchDays: Array<string>;
  onClickDay: (day: CalendarDay) => void;
  onGoToPreviousMonth: (() => void) | null;
  onGoToNextMonth: () => void;
};

export type CalendarDay = {
  rawDay?: dayjs.Dayjs;
  weekday: number;
  date: number;
  week: number;
  hasMatch: boolean;
  active: boolean;
  disabled: boolean;
  visible: true;
};
type EmptyDay = { visible: false };

// 月〜日の数字の並び順(標準は日曜始まりなので、月曜始まりに変更)
const weekDaysByOrder = [1, 2, 3, 4, 5, 6, 0];

// 週番号(年における当該週のindex)を取得する。
export const getFixedWeek: (day: dayjs.Dayjs) => number = (day) => {
  const weekday = day.weekday();
  const isSunday = weekday === 0;

  // 日本の場合は年末の週番号が1になる場合があるが表示上の都合で、年末で週番号が1の場合は53として計上する
  // 日本（アメリカ式）の場合1月1日が含まれる週が週番号1になるので、2020年12月27日（日）から2021年1月2日（土）は週番号1、最終週のWeek 52は2021年12月19日（日）から2021年12月25日（土）までとなる
  const rawWeek = day.month() === 11 && day.week() === 1 ? 53 : day.week();

  // 表示上の都合で日曜日でない場合は翌週に繰り上げる
  return !isSunday ? rawWeek + 1 : rawWeek;
};

type CalendarWeek<T = CalendarDay | EmptyDay> = { week: number; days: T[] };

// 指定された月のカレンダーの行(週)の一覧を取得する。
const getCalendarWeeks: (
  today: dayjs.Dayjs,
  month: dayjs.Dayjs,
  matchDays: Array<dayjs.Dayjs>
) => CalendarWeek[] = (today, month, matchDays) => {
  const days: Array<CalendarDay> = _.compact(
    _.range(1, month.daysInMonth() + 1).map(function (d) {
      const day = month.date(d).tz(defaultTimeZone);
      const week = getFixedWeek(day);
      const disabled = day.isBefore(today, "day");
      const active = day.isSame(today, "day");

      return {
        rawDay: day,
        weekday: day.weekday(),
        hasMatch: matchDays.some((matchDay) => matchDay.isSame(day, "day")),
        date: d,
        week,
        active,
        disabled,
        visible: true,
      };
    })
  );

  return days
    .reduce<CalendarWeek<CalendarDay>[]>((acc, day) => {
      const index = day.week - 1;
      acc[index] = acc[index]
        ? { ...acc[index], days: acc[index].days.concat(day) }
        : { week: day.week, days: [day] };
      return acc;
    }, [])
    .map<CalendarWeek>(
      (daysByWeek: CalendarWeek<CalendarDay>): CalendarWeek => {
        const newDays: Array<CalendarDay | EmptyDay> = weekDaysByOrder.map(
          (weekday) => {
            // 当該の日付が無い場合は、空枠表示用にEmptyDayを渡す。
            return (
              daysByWeek.days.find((day) => day.weekday === weekday) ?? {
                visible: false,
              }
            );
          }
        );
        return { week: daysByWeek.week, days: newDays };
      }
    );
};

export const Calendar: React.FC<CalendarProps> = ({
  onClickDay,
  today,
  month,
  matchDays,
  onGoToNextMonth,
  onGoToPreviousMonth,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  // PCではカレンダーを初期で開いた状態とする。
  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    browser.is("desktop") && setIsCollapsed(false);
  }, []);

  // `202012` のような月の初日を基準日として保持する。
  const baseDay = useMemo(() => dayjs(month, formats.month), [month]);

  let days = useMemo(
    () =>
      getCalendarWeeks(
        today,
        baseDay,
        (matchDays || []).map((matchDay) => dayjs(matchDay, "YYYYMMDD"))
      ),
    [today, baseDay, matchDays]
  );

  // 開閉の状態に応じて、表示する週を絞り込む。
  // 当月でない場合、月の最初の週を開く
  if (isCollapsed) {
    const currentWeek = getFixedWeek(today);
    const baseWeek = getFixedWeek(baseDay);
    const sameMonth = today.isSame(baseDay, "month");

    days = days.filter((day) =>
      sameMonth ? day.week === currentWeek : day.week === baseWeek
    );
  }

  const isPrevMonthDisabled = !onGoToPreviousMonth;

  return (
    <div className={styles.calendar}>
      <div className={styles.title}>
        <div className={classNames("l-main__innerWidth", styles.container)}>
          <div className={styles.titleBox}>
            <div className={styles.boxLeft}>
              <p className={styles.titleLabel}>
                <span
                  className={classNames(
                    [styles.arrow],
                    isCollapsed ? styles.arrowDown : styles.arrowUp
                  )}
                  onClick={(): void => setIsCollapsed((prev) => !prev)}
                >
                  {toLocaleStr(month, "ym")}
                </span>
              </p>
            </div>
            <div className={styles.boxRight}>
              <button
                type="button"
                className={classNames(styles.arrow, styles.arrowPrev, {
                  [styles.disabled]: isPrevMonthDisabled,
                })}
                disabled={isPrevMonthDisabled}
                onClick={(): void => {
                  if (onGoToPreviousMonth) {
                    onGoToPreviousMonth();
                  }
                }}
              >
                <span className="none md:block">前月</span>
              </button>
              <button
                type="button"
                className={classNames(styles.arrow, styles.arrowNext)}
                onClick={(): void => onGoToNextMonth()}
              >
                <span className="none md:block">翌月</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={isCollapsed ? styles.box : styles.boxMonthly}>
        <div className={classNames(styles.line, styles.weekdayLine)}>
          {weekDaysByOrder.map((weekday) => (
            <div key={weekday} className={styles.weekdayItem}>
              {weekDays[weekday]}
            </div>
          ))}
        </div>

        {days.map((daysByWeek) => (
          <div key={daysByWeek.week} className={styles.line}>
            {daysByWeek.days.map((day, index) =>
              day.visible ? (
                <CalendarDate
                  key={day.date}
                  date={day.date}
                  onClickDay={(): void => onClickDay(day)}
                  disabled={day.disabled}
                  active={day.active}
                  hasMatch={day.hasMatch}
                />
              ) : (
                <div
                  key={`${daysByWeek.week}${index}`}
                  className={styles.lineItem}
                />
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
