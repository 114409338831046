import Head from "next/head";
import { getImageFluxUrl } from "@components/ImageFlux";
import { imagePaths, paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import { trimSpaces } from "@libs/utils/og/common";
import { TOP_TITLE } from "../../constants";
import type {
  CommonHead_ReservationConfigShopFragment,
  CommonHead_ShopFragment,
} from "./__generated__/fragments";
import type { FC } from "react";

const SITE_NAME = "Fansta(ファンスタ)";

type Props = {
  title?: string;
  description?: string;
  ogType?: "article" | "website";
  ogImage?: string;
  ogUrl?: string;
  canonicalUrl?: string;
  options?: HeadOptions;
};
interface HeadOptions {
  titleSuffix?: boolean;
}

export const joinTitle = (title?: string, titleSuffix?: boolean): string => {
  if (title === undefined) {
    return "";
  }
  if (titleSuffix !== undefined && !titleSuffix) {
    return title;
  }
  return title + " | " + SITE_NAME;
};

const makeAbsolutePath = (url?: string): string => {
  if (!url) {
    return "";
  }
  // URLが `https://` から始まらない場合、ホスト名を自動で付与し、コンポーネントからはpathnameのみを渡せるようにする。
  // URLが `https://` から始まる場合、URLの値をそのまま返す。
  return url.match(/^http[s]?/) ? url : `${process.env.APP_HOST}${url}`;
};

/**
 * HEADの生成に必要な情報を作成する。
 *
 * @param title ページのタイトル
 * @param description ページの説明として表示されるディスクリプション
 * @param ogType ページのタイプ
 * @param ogUrl ページのURL
 * @param ogImage シェアされた場合に表示される画像
 * @param canonicalUrl SEOのための正規URL
 * @param options 例外的な処理をする際に設定する値
 **/
export const CommonHead: FC<Props> = ({
  title,
  description,
  ogType,
  ogUrl,
  ogImage,
  canonicalUrl,
  options,
}) => {
  // Descriptionから余分な改行文字や空白を消す。
  const trimmedDescription = trimSpaces(description || "");

  const absoluteOgUrl = makeAbsolutePath(ogUrl);
  const absoluteCanonicalUrl = makeAbsolutePath(canonicalUrl);

  const joinedTitle = joinTitle(title, options?.titleSuffix) || "";
  const defaultOgImage = IFImg(imagePaths.defaultOgp);

  return (
    <Head>
      {/* link */}
      {absoluteCanonicalUrl && (
        <link rel="canonical" href={absoluteCanonicalUrl} />
      )}

      <title>{joinedTitle || SITE_NAME}</title>

      {/* property */}
      <meta property="og:title" content={joinedTitle} />
      <meta property="og:description" content={trimmedDescription || ""} />
      <meta property="og:url" content={absoluteOgUrl} />
      <meta property="og:type" content={ogType || "article"} />
      <meta property="og:image" content={ogImage || defaultOgImage} />
      <meta property="og:site_name" content={SITE_NAME} />

      {/* name */}
      <meta name="description" content={trimmedDescription || ""} />
      <meta name="twitter:card" content={"summary_large_image"} />
      <meta name="twitter:site" content={"@fansta_jp"} />
      <meta name="twitter:title" content={joinedTitle} />
      <meta name="twitter:description" content={trimmedDescription || ""} />
      <meta name="twitter:url" content={absoluteOgUrl} />
      <meta name="twitter:image" content={ogImage || defaultOgImage} />
      <meta name="thumbnail" content={ogImage || defaultOgImage} />
    </Head>
  );
};

export const NoIndex: FC = () => {
  return (
    <Head>
      <meta name="robots" content="noindex, nofollow" />
      <meta name="googlebot" content="noindex, nofollow" />
    </Head>
  );
};

export const ShopHead: FC<{
  shop: CommonHead_ShopFragment | undefined;
  type?: "index" | "matches" | "photos";
  overrides?: Props;
}> = ({ shop, type = "index", overrides = {} }) => {
  const { ogImage, ...rest } = overrides;
  if (!shop) {
    return <CommonHead {...overrides} />;
  }

  const titleShopPart = `${shop.name}(${shop.masterArea.name}/${
    shop.masterShopGenre.name
  }${shop.isReservableOnline ? "/ネット予約可" : ""})`;
  const descriptionShopPart = `${shop.name}(${shop.masterArea.name}/${shop.masterShopGenre.name})`;

  let ogUrl = "";
  let title = "";
  let description;
  if (type === "index") {
    ogUrl = paths.shopDetail(shop.uuid || "");
    title = titleShopPart;

    const viewEnvironmentDescription = shop.viewEnvironments[0]
      ? shop.viewEnvironments[0].description
      : "";

    description = `${descriptionShopPart}の情報はFansta(ファンスタ)でチェック！${shop.closestStation}駅から徒歩${shop.closestStationTime}分、${shop.descriptionTitle}/${viewEnvironmentDescription}`;
  } else if (type === "matches") {
    ogUrl = paths.shopMatches(shop.uuid || "");
    title = `上映予定 : ${titleShopPart}`;
    description = `${descriptionShopPart}でライブビューイングを楽しめる試合情報はFansta(ファンスタ)でチェック！`;
  } else if (type === "photos") {
    ogUrl = paths.shopPhoto(shop.uuid || "");
    title = `写真 : ${titleShopPart}`;
    description = `${descriptionShopPart}の写真はFansta(ファンスタ)でチェック！`;
  }

  let shopOgImage = "";
  if (shop.shopImages[0]) {
    shopOgImage = getImageFluxUrl({
      url: shop.shopImages[0].imageUrl,
      imageType: "og",
    });
  }

  return (
    <CommonHead
      title={title}
      description={description}
      ogImage={ogImage || shopOgImage}
      ogUrl={ogUrl}
      {...rest}
    />
  );
};

export const ReservationHead: FC<{
  shop: CommonHead_ReservationConfigShopFragment | undefined;
  title: string;
}> = ({ shop, title }) => {
  return (
    <CommonHead
      title={`${title} : ${shop?.name}(${shop?.masterArea.name}/${shop?.masterShopGenre.name})`}
      ogType={"website"}
    />
  );
};
export const TopHead = (
  <CommonHead
    title={TOP_TITLE}
    description={
      "Fanstaはスポーツバーや飲食店を検索・予約できるDAZN公認のサービスです。サッカーや野球の試合日程、近くのエリア、好きなチームから目的の試合を上映しているお店を見つけてスポーツ観戦・スポーツライブビューイングを楽しもう。"
    }
    ogImage={""}
    ogUrl={paths.home}
    ogType={"website"}
    canonicalUrl={paths.home}
    options={{ titleSuffix: false }}
  />
);
