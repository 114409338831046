import classNames from "classnames";
import ReservationPaymentOption from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentOption";
import { StaticPaymentMethodEnum } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import styles from "./index.module.css";
import type { FC } from "react";

export const ReservationPaymentOnSite: FC = () => {
  return (
    <ReservationPaymentOption
      label={"現地でお支払い"}
      value={StaticPaymentMethodEnum.OnSite}
      point={2}
      errorMessage={null}
      hidesChildrenIfNotSelected
    >
      <div className={classNames(styles.local)}>
        <div className={styles.description}>
          現地でお支払いを選択された場合、どうなるのか説明できるテキストエリアです。手数料とかなんかそういうのの説明が必要な場合はここに記載します。
        </div>

        {/*TODO: [事前決済] ポイント還元率の変更 のPBIで実装する*/}
        {/*<div className={classNames(styles.appeal, styles.appealAdvance)}>*/}
        {/*  <div className={styles.appealTitle}>*/}
        {/*    事前カード決済なら、*/}
        {/*    <br />*/}
        {/*    ポイントがもっとお得に*/}
        {/*    <br />*/}
        {/*    たまる！*/}
        {/*  </div>*/}

        {/*<div className={styles.appealTransformationWrapper}>*/}
        {/*  <div*/}
        {/*    className={classNames(*/}
        {/*      styles.appealTransformation,*/}
        {/*      styles.appealTransformationBefore*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    <div className={styles.appealTransformationTitle}>*/}
        {/*      現地でお支払い*/}
        {/*    </div>*/}
        {/*    <div className={styles.appealTransformationPercentage}>*/}
        {/*      <em>2%</em>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div*/}
        {/*    className={classNames(*/}
        {/*      styles.appealTransformation,*/}
        {/*      styles.appealTransformationAfter*/}
        {/*    )}*/}
        {/*  >*/}
        {/*    <div className={styles.appealTransformationTitle}>*/}
        {/*      事前カード決済*/}
        {/*    </div>*/}
        {/*    <div className={styles.appealTransformationPercentage}>*/}
        {/*      <em>4%</em>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        {/*</div>*/}
      </div>
    </ReservationPaymentOption>
  );
};
