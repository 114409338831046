import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import { Button } from "@components/Button";
import { OPEN_STATUS, SearchItem } from "@components/SearchInput/_SearchItem";
import type { UseCommonData_MasterPrefectureFragment as MasterPrefectureFragment } from "@hooks/useCommonData/__generated__/fragments";
import { Index } from "../Tabs";
import styles from "../_SearchItem/index.module.css";
import { useAreaSelection } from "../_useAreaSelection";
import type { TabElements } from "../Tabs";

type SearchAreaProps = {
  openArea: boolean;
  setOpenArea: (flag: boolean) => void;
  prefectures: Array<MasterPrefectureFragment>;
  selectedPrefecture: number;
  onSelectPrefecture: (prefectureId?: number) => void;
  selectedArea: Array<number>;
  onSelectArea: (combinedAreaIds: number[], areaIds: number[]) => void;
  submitText: string;
};

export const SearchArea: React.FC<SearchAreaProps> = ({
  openArea,
  setOpenArea,
  prefectures,
  selectedPrefecture,
  onSelectPrefecture,
  selectedArea,
  onSelectArea,
  submitText,
}) => {
  const [openStatus, setOpenStatus] = useState<number>(OPEN_STATUS.CLOSED); // 検索メニューの開閉状態を管理
  const scrollPrefectureRefs = useRef<TabElements>({});
  const [doOnSelectArea, setDoOnSelectArea] = useState(false);
  const [isAreaScroll, setAreaScroll] = useState(true);
  const [enableSwipe, setEnableSwipe] = useState(true);
  const areaItemsRef = useRef<HTMLDivElement>(null);
  const draftSelection = useAreaSelection({
    allPrefectures: prefectures,
    selectedPrefectureId: selectedPrefecture,
    initialSelectedAreaIds: selectedArea,
  });

  // 最後にモーダルを閉じた時から選択状況が変化していたら、テキストボックスのクリアボタンが使用されたものとして状態を同期する
  const [lastSelectedOnClosing, setLastSelectedOnClosing] =
    useState<number[]>(selectedArea);
  const [lastStatus, setLastStatus] = useState<number>(OPEN_STATUS.CLOSED);
  useEffect(() => {
    const willOpen =
      openStatus === OPEN_STATUS.CHANGING && lastStatus === OPEN_STATUS.CLOSED;

    if (willOpen) {
      // 順序を問わず`lastSelectedOnClosing`と`selectedArea`に差異があるかどうか
      const isChangedWithoutModal =
        lastSelectedOnClosing.length !== selectedArea.length ||
        lastSelectedOnClosing.some((a) => !selectedArea.includes(a));
      if (isChangedWithoutModal) {
        draftSelection.setSelectedAreas(selectedArea);
      }
    }

    const isClosed =
      openStatus === OPEN_STATUS.CLOSED && lastStatus === OPEN_STATUS.CHANGING;
    if (isClosed) {
      setLastSelectedOnClosing(selectedArea);
    }

    setLastStatus(openStatus);
  }, [openStatus]);

  useEffect(() => {
    if (areaItemsRef.current) {
      areaItemsRef.current.scrollTo({ top: 0 });
    }

    if (openStatus !== OPEN_STATUS.OPENED) {
      // 最後に選択した都道府県を真ん中に表示する
      scrollPrefectureRefs.current[selectedPrefecture]?.scrollIntoView({
        inline: "center",
      });
    }
  }, [openStatus, selectedPrefecture]);

  // 検索UIを閉じた & 開閉ステータスも"closed"になったら非表示にする
  if (!openArea && openStatus === OPEN_STATUS.CLOSED) {
    return null;
  }

  const tabs = (
    <Index
      tabs={draftSelection.targetPrefectures.map((prefecture) => ({
        label: prefecture.name,
        value: prefecture.id,
      }))}
      selected={selectedPrefecture}
      onSelect={({ value: prefectureId }) => {
        onSelectPrefecture(prefectureId as number);
        setEnableSwipe(true);
      }}
      ref={(ref) => {
        if (ref) {
          scrollPrefectureRefs.current = ref;
        }
      }}
    />
  );

  return (
    <SearchItem
      openContent={openArea}
      openStatus={openStatus}
      setOpenStatus={setOpenStatus}
      enableSwipe={enableSwipe}
      setEnableSwipe={setEnableSwipe}
      setScroll={setAreaScroll}
      tabs={tabs}
      contentRef={areaItemsRef}
      onAnimationEnd={(): void => {
        // エリア周りの選択状態を確定する。
        onSelectArea(
          [...draftSelection.selectedCombinedAreaIds],
          [...draftSelection.selectedAreaIds]
        );
      }}
      onClose={() => {
        // `この条件で絞り込む` からの操作以外の場合は "onAnimationEnd" をskipするための処理
        setDoOnSelectArea(false);
        // 県の選択状態を確定する。
        onSelectPrefecture(selectedPrefecture);
        // 閉じる
        setOpenArea(false);
      }}
      doSearch={doOnSelectArea}
    >
      {draftSelection.targetPrefectures.map((prefecture) => (
        <div
          className={
            prefecture.id === selectedPrefecture
              ? isAreaScroll
                ? classNames(styles.items, styles.areaItems)
                : classNames(
                    styles.items,
                    styles.areaItems,
                    styles.overflowHidden
                  )
              : classNames(styles.items, styles.areaItems, styles.hidden)
          }
          key={prefecture.id}
          ref={prefecture.id === selectedPrefecture ? areaItemsRef : null}
        >
          {prefecture?.combinedAreas?.map((combinedArea) => (
            <div key={combinedArea.id}>
              <div className={styles.itemMain}>
                <label className={styles.areaCheck}>
                  <input
                    type="checkbox"
                    name="areaMain"
                    value={combinedArea.name}
                    checked={draftSelection.isSelectedCombinedArea(
                      combinedArea.id
                    )}
                    onChange={() =>
                      draftSelection.toggleCombinedAreaSelection(
                        combinedArea.id
                      )
                    }
                  />
                  <p className={styles.areaCheckText}>{combinedArea.name}</p>
                </label>
              </div>

              <div className={classNames(styles.itemSub, styles.areaSub)}>
                {combinedArea.areas?.map((area) => (
                  <label className={styles.areaCheck} key={area.id}>
                    <input
                      type="checkbox"
                      name="areaSub"
                      value={area.name}
                      checked={draftSelection.isSelectedArea(area.id)}
                      onChange={() =>
                        draftSelection.toggleAreaSelection(area.id)
                      }
                    />
                    <p className={styles.areaCheckText}>{area.name}</p>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}

      <div className={styles.areaButtons}>
        <Button
          className={styles.reset}
          styleType="filled"
          colorType="secondary"
          onClick={draftSelection.clearAll}
          disabled={!draftSelection.isClearable}
        >
          クリア
        </Button>
        <Button
          className={styles.submit}
          styleType="filled"
          colorType="primary"
          onClick={(): void => {
            setOpenArea(false);
            setDoOnSelectArea(true);
          }}
        >
          {submitText}
        </Button>
      </div>
    </SearchItem>
  );
};
