import { useState } from "react";
import { createContainer } from "unstated-next";
import type React from "react";

type EmailEditContextProps = {
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
};

const useEmailEdit = (): EmailEditContextProps => {
  // メアド変更時にAuthContextの更新による再レンダリングで初期化されず状態を保てるstateが必要なのでこのcontextを利用している
  const [isSubmitting, setIsSubmitting] = useState(false);

  return { isSubmitting, setIsSubmitting };
};

const EmailEdit = createContainer(useEmailEdit);

export default EmailEdit;
