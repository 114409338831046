import { useEffect, useState } from "react";
import { ConfirmationModal } from "@components/ConfirmationModal";
import Auth from "@hooks/useAuth";
import { useOptInModal_CreateEmailOptInSettingMutation } from "./__generated__/mutations";

export const OptInModal = () => {
  const { fanstaUser } = Auth.useContainer();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isShowOptInModal, setIsShowOptInModal] = useState(false);
  useEffect(() => {
    if (fanstaUser?.currentUser.user?.isAnsweredOptingQuestion === false) {
      setIsShowOptInModal(true);
    }
  }, [fanstaUser]);

  const [CreateEmailOptInSettingMutation] =
    useOptInModal_CreateEmailOptInSettingMutation();
  const handleOptInSubmit = async (isAllowedEmail: boolean) => {
    setIsSubmitting(true);
    await CreateEmailOptInSettingMutation({
      variables: {
        isAllowedEmail,
      },
    });
    setIsSubmitting(false);
    setIsShowOptInModal(false);
  };

  return (
    <ConfirmationModal
      isShown={isShowOptInModal}
      title="メール配信について"
      message="Fanstaからのお知らせやお気に入りチームのお知らせなどをお届けします。"
      continueButton={{
        label: "受け取る",
        onClick: () => handleOptInSubmit(true),
        isDisabled: isSubmitting,
      }}
      abortButton={{
        label: "受け取らない",
        onClick: () => handleOptInSubmit(false),
        isDisabled: isSubmitting,
      }}
      abortsOnBackgroundClick={false}
    />
  );
};
