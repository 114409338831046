import _ from "lodash";
import { useEffect, useReducer } from "react";
import { createContainer } from "unstated-next";
import { CommonDataState } from "@hooks/useCommonData";
import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import SearchQueryParams from "@hooks/useSearchQueryParams";
import {
  getIsEveryTeamInTheTournamentSelected,
  getIsSomeTeamInTheTournamentSelected,
} from "@libs/utils/teams";
import type { UseTeamsByTournament_TeamsQuery as TeamsQuery } from "./__generated__/queries";

enum TeamsByTournamentActionType {
  SET_SHOP_SEARCH_TOURNAMENT_ID = "SET_SHOP_SEARCH_TOURNAMENT_ID",
  SET_TEAM_LIST_TOURNAMENT_ID = "SET_TEAM_LIST_TOURNAMENT_ID",
  INIT_TOURNAMENT_IDS = "INIT_TOURNAMENT_IDS",
}

export type CachedTeamsQuery = TeamsQuery["teamsUnbundled"];

type RawTeamsByTournamentState = {
  allTeams?: CachedTeamsQuery;
  allTournaments?: UseCommonData_CommonDataQuery["tournaments"];
  teamsForTeamList?: CachedTeamsQuery;
  teamsForSearch?: CachedTeamsQuery;
  tournamentId?: number;
  teamListTournamentId?: number;
  lastTournamentId?: number;
};

type TeamsByTournamentAction = {
  type: TeamsByTournamentActionType;
  payload: number | null;
};

type TeamsByTournamentState = UseAllTeams &
  RawTeamsByTournamentState & {
    setTournamentId: (tournamentId: number | null) => void;
    setTeamListTournamentId: (tournamentId: number | null) => void;
    initializeTournamentId: () => void;
  };

const teamsByTournamentReducer = (
  state: RawTeamsByTournamentState,
  action: TeamsByTournamentAction
): RawTeamsByTournamentState => {
  const tournamentId = action.payload || undefined;
  switch (action.type) {
    case TeamsByTournamentActionType.SET_SHOP_SEARCH_TOURNAMENT_ID:
      return {
        ...state,
        tournamentId,
        lastTournamentId: state.tournamentId,
      };
    case TeamsByTournamentActionType.SET_TEAM_LIST_TOURNAMENT_ID:
      return {
        ...state,
        teamListTournamentId: tournamentId,
      };
    case TeamsByTournamentActionType.INIT_TOURNAMENT_IDS:
      return {
        ...state,
        tournamentId,
        teamListTournamentId: tournamentId,
        lastTournamentId: tournamentId,
      };
    default:
      return state;
  }
};

type UseAllTeams = {
  allTeams: TeamsQuery["teamsUnbundled"];
  allTeamsLoading: boolean;
};

// キャッシュからリーグの選択状態別に適切なチーム一覧を返却する独自のReactHook
const useTeamsByTournamentState = (): TeamsByTournamentState => {
  const { data: commonData, loading: allTeamsLoading } =
    CommonDataState.useContainer();
  const { params } = SearchQueryParams.useContainer();
  const [teamsByTournamentState, dispatch] = useReducer(
    teamsByTournamentReducer,
    {}
  );

  // トーナメントIDの初期選択状態をクエリパラメータから導出する。
  const initializeTournamentId = () => {
    const { teams: teamIds = [] } = params;
    const allTeamSelectedTournamentId = _.find(allTournaments, ({ id }) =>
      getIsEveryTeamInTheTournamentSelected(allTeams, teamIds, id)
    )?.id;
    const someTeamSelectedTournamentId = _.find(allTournaments, ({ id }) =>
      getIsSomeTeamInTheTournamentSelected(allTeams, teamIds, id)
    )?.id;
    const tournamentId =
      allTeamSelectedTournamentId ||
      someTeamSelectedTournamentId ||
      allTournaments[0].id;

    dispatch({
      type: TeamsByTournamentActionType.INIT_TOURNAMENT_IDS,
      payload: tournamentId,
    });
  };

  // CommonDataがロードし終わったらUIタブ選択を初期化する
  useEffect(() => {
    !allTeamsLoading && initializeTournamentId();
  }, [allTeamsLoading]);

  const allTeams = commonData?.teamsUnbundled || [];
  const allTournaments = commonData?.tournaments || [];

  // チームリスト向けのチーム一覧で絞り込む。
  const teamsForTeamList = allTeams.filter((team) =>
    _.includes(
      _.map(team?.masterTournaments, "id"),
      teamsByTournamentState.teamListTournamentId
    )
  );

  // 店舗検索UI向けのチーム一覧で絞り込む。
  const teamsForSearch = allTeams.filter((team) =>
    _.includes(
      _.map(team?.masterTournaments, "id"),
      teamsByTournamentState.tournamentId
    )
  );

  return {
    tournamentId: teamsByTournamentState.tournamentId,
    teamListTournamentId: teamsByTournamentState.teamListTournamentId,

    allTournaments,
    allTeams,
    allTeamsLoading,

    teamsForTeamList,
    teamsForSearch,

    setTournamentId(tournamentId: number | null) {
      dispatch({
        type: TeamsByTournamentActionType.SET_SHOP_SEARCH_TOURNAMENT_ID,
        payload: tournamentId,
      });
    },
    setTeamListTournamentId(tournamentId: number | null) {
      dispatch({
        type: TeamsByTournamentActionType.SET_TEAM_LIST_TOURNAMENT_ID,
        payload: tournamentId,
      });
    },

    initializeTournamentId,
  };
};

const TeamsByTournamentState = createContainer(useTeamsByTournamentState);

export { TeamsByTournamentState };
