import { useMemo, useState } from "react";
import type { UseCommonData_MasterPrefectureFragment as MasterPrefectureFragment } from "@hooks/useCommonData/__generated__/fragments";

type UseAreaSelectionProps = {
  allPrefectures: MasterPrefectureFragment[];
  selectedPrefectureId: number;
  initialSelectedAreaIds: number[];
};

export const useAreaSelection = ({
  allPrefectures,
  selectedPrefectureId,
  initialSelectedAreaIds,
}: UseAreaSelectionProps) => {
  const [selectedAreaIds, setSelectedAreaIds] = useState<Set<number>>(
    new Set(initialSelectedAreaIds)
  );

  // 結合エリアを持つ都道府県のみを選択の対象とする
  const targetPrefectures = useMemo(
    () => allPrefectures?.filter((p) => (p.combinedAreas?.length ?? 0) >= 1),
    [allPrefectures]
  );
  const selectedPrefecture = useMemo(
    () => targetPrefectures?.find((p) => p.id === selectedPrefectureId),
    [targetPrefectures, selectedPrefectureId]
  );
  const selectedCombinedAreaIds = useMemo(() => {
    // 所属するエリアがすべて選択されている結合エリアは選択されているものとする
    const combinedAreas = selectedPrefecture?.combinedAreas ?? [];
    const selectedCombinedAreas = combinedAreas.filter(
      (a) => a.areas?.every((a) => selectedAreaIds.has(a.id))
    );
    return new Set(selectedCombinedAreas.map((a) => a.id));
  }, [selectedPrefecture, selectedAreaIds]);

  const isSelectedCombinedArea = (combinedAreaId: number) =>
    selectedCombinedAreaIds.has(combinedAreaId);
  const isSelectedArea = (areaId: number) => selectedAreaIds.has(areaId);
  const isPartialSelectedCombinedArea = (combinedAreaId: number) => {
    const containedAreas =
      selectedPrefecture?.combinedAreas?.find((a) => a.id === combinedAreaId)
        ?.areas ?? [];
    return containedAreas.some((a) => isSelectedArea(a.id));
  };

  const isClearable = useMemo(
    () => selectedAreaIds.size >= 1,
    [selectedAreaIds]
  );

  const selectAreas = (areaIds: number[]) => {
    setSelectedAreaIds(new Set([...selectedAreaIds, ...areaIds]));
  };

  const unselectAreas = (areaIds: number[]) => {
    setSelectedAreaIds(
      new Set([...selectedAreaIds].filter((id) => !areaIds.includes(id)))
    );
  };

  const setSelectedAreas = (areaIds: number[]) => {
    setSelectedAreaIds(new Set(areaIds));
  };

  const toggleAreaSelection = (areaId: number) => {
    (isSelectedArea(areaId) ? unselectAreas : selectAreas)([areaId]);
  };

  const toggleCombinedAreaSelection = (combinedAreaId: number) => {
    const targetCombinedArea = selectedPrefecture?.combinedAreas?.find(
      (a) => a.id === combinedAreaId
    );
    const targetAreaIds = targetCombinedArea?.areas?.map((a) => a.id) ?? [];
    (isSelectedCombinedArea(combinedAreaId) ? unselectAreas : selectAreas)(
      targetAreaIds
    );
  };

  const clearAll = () => {
    setSelectedAreaIds(new Set());
  };

  return {
    targetPrefectures,
    selectedPrefecture,
    selectedCombinedAreaIds,
    selectedAreaIds,
    isClearable,
    isSelectedCombinedArea,
    isSelectedArea,
    isPartialSelectedCombinedArea,
    setSelectedAreas,
    toggleAreaSelection,
    toggleCombinedAreaSelection,
    clearAll,
  };
};
