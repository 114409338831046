import type { UsersMatchType } from "@graphql/__generated__/types";
import { toLocaleStr } from "@libs/utils/date";

export const shouldShowMatchTime = (
  match: Pick<UsersMatchType, "startsAt" | "isTba">
) => {
  // 試合情報が確定済み(isTba = false) or 仮日時でない場合にtrue
  // TODO: もっと安定した実装に修正する。
  const isDummyTime = toLocaleStr(match.startsAt, "time") === "23:59";
  return !match.isTba || !isDummyTime;
};
