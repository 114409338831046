import classNames from "classnames";
import { Fragment } from "react";
import { Text } from "@components/Text";
import { IFImg } from "@libs/utils/IFImg";
import styles from "./index.module.css";
import type { FC } from "react";

export type FlowListStepStatus = "checked" | "current" | "";
type Props = {
  label: { value: string; status: FlowListStepStatus }[];
};

export const FlowList: FC<Props> = ({ label }) => {
  if (label.length === 0) {
    return;
  }
  return (
    <ul className={styles.flowList}>
      {label.map((item, index) => (
        <Fragment key={index}>
          <li
            className={classNames(styles.valueWrapper, {
              [styles.incomplete]: item.status === "",
            })}
          >
            <img
              src={IFImg(
                `/img/icon/step_${
                  item.status === "checked" ? "complete" : "incomplete"
                }.svg`
              )}
              alt="step"
              height={16}
              width={16}
            />
            <Text>{item.value}</Text>
          </li>
          {index < label.length - 1 && (
            <li
              className={classNames(styles.line, {
                [styles.incomplete]: item.status === "",
              })}
            />
          )}
        </Fragment>
      ))}
    </ul>
  );
};
