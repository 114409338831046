import { useState, useEffect } from "react";

type ScrollbarWidthProps = {
  scrollbarWidth: number | null;
};

// SEE: https://gist.github.com/ciases/aeff65950f2bbafc8da699d1711fb7f3
function getScrollWidth(): number {
  const scrollDiv = document.createElement("div");
  scrollDiv.className = "helper-scrollbar-width";
  document.body.appendChild(scrollDiv);

  // Will be `zero` on mobile device.
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  document.body.removeChild(scrollDiv);

  return scrollbarWidth;
}

function useScrollbarWidth(): ScrollbarWidthProps {
  const [scrollbarWidth, setScrollbarWidth] = useState<number | null>(null);

  useEffect(() => {
    setScrollbarWidth(getScrollWidth());
  }, []);

  return {
    scrollbarWidth,
  };
}

export { useScrollbarWidth };

export default {
  useScrollbarWidth,
};
