import _ from "lodash";

export const IFImg = (
  imgPath: string | null | undefined,
  trimImageFluxParams = false
): string => {
  if (!imgPath) {
    return "";
  }

  // 絶対パスの場合はそのまま帰す
  if (imgPath.indexOf("://") > 0 || imgPath.indexOf("//") === 0) {
    return imgPath;
  }

  // Handle imageFluxParams("/c/f=webp:auto")
  let baseUrl = process.env.IMAGE_FLUX_URL || "";
  const storageUrl = process.env.GCS_URL || "";

  if (baseUrl && trimImageFluxParams) {
    const parsedUrl = new URL(baseUrl);

    const pathname = parsedUrl.pathname;
    const paths = _.trimStart(pathname, "/").split("/");
    if (paths.length > 2) {
      let firstPath = paths[0];
      // If startsWith "/c" or "/c!"
      // SEE: https://console.imageflux.jp/docs/image/conversion-parameters
      if (firstPath === "c" || firstPath === "c!") {
        paths.shift();
      }

      // If contains params like `f=webp`.
      firstPath = paths[0];
      if (_.includes(firstPath, "=")) {
        paths.shift();
      }

      // Re-construct  baseUrl.
      baseUrl = parsedUrl.origin + "/" + paths.join("/");
    }
  }

  return imgPath.match(/\/img\/.*(jpeg|jpg|png|gif)$/i)
    ? baseUrl + imgPath
    : storageUrl + imgPath;
};
