import classNames from "classnames";
import _ from "lodash";
import { parseRelativeUrl } from "next/dist/shared/lib/router/utils/parse-relative-url";
import { useRouter } from "next/router";
import * as React from "react";
import { useEffect, useState } from "react";
import { FilterLabel } from "@components/SearchInput/FilterLabel";
import { SearchArea } from "@components/SearchInput/SearchArea";
import { SearchAreaPc } from "@components/SearchInput/SearchAreaPc";
import { SearchTeam } from "@components/SearchInput/SearchTeam";
import { SearchTeamPc } from "@components/SearchInput/SearchTeamPc";
import { Text } from "@components/Text";
import { CommonDataState } from "@hooks/useCommonData";
import type { SearchCondition } from "@hooks/useSearchCondition";
import { useSearchCondition } from "@hooks/useSearchCondition";
import SearchQueryParams from "@hooks/useSearchQueryParams";
import { TeamsByTournamentState } from "@hooks/useTeamsByTournament";
import ToggleScroll from "@hooks/useToggleScroll";
import { getRouter } from "@libs/rewrite";
import { checkIsPcByScreenWidth } from "@libs/utils/browser";
import styles from "./index.module.css";

type TeamSearchIcon = "team" | "filter";
type SearchInputProps = {
  className: string;
  onChange?: (searchCondition: SearchCondition) => void;
  teamSearchPlaceholder: string;
  teamSearchPosition?: string;
  teamSearchIcon?: TeamSearchIcon;
  showSearchArea?: boolean;
  shouldSyncHistory?: boolean;
  submitText: string;
  isDisplayingSearchTitle?: boolean;
  teamSearchTitle?: string;
  isCompactPc?: boolean;
  children?: React.ReactNode;
};

const CssStyles: { [key: string]: string } = {
  keyVisualSearch: styles.keyVisualSearch,
  sort: styles.sort,
};

const defaultPrefectureId = 13; // デフォルトの都道府県：東京
const emptyAreaLabel = "エリア";

// SearchInput is flexible and can manage selection state
export const SearchInput: React.FC<SearchInputProps> = ({
  className,
  onChange,
  teamSearchIcon: _teamSearchIcon,
  teamSearchPlaceholder,
  teamSearchPosition,
  showSearchArea = true,
  shouldSyncHistory,
  submitText,
  isDisplayingSearchTitle,
  teamSearchTitle,
  isCompactPc,
  children,
}) => {
  const teamsByTournamentState = TeamsByTournamentState.useContainer();
  const searchQueryParams = SearchQueryParams.useContainer();
  const { data: prefData } = CommonDataState.useContainer();

  const prefectures = prefData?.masterPrefectures || [];

  const teamSearchIcon = _teamSearchIcon || "team";

  // UI上のTournamentの選択状態
  const {
    allTournaments,
    setTournamentId,
    teamsForSearch,
    tournamentId,
    allTeams,
    allTeamsLoading: initialTeamLoading,
  } = teamsByTournamentState;

  const allTeamsForFilterLabel = initialTeamLoading ? [] : allTeams;

  const tournaments = allTournaments;

  const [openArea, setOpenArea] = useState(false);
  const [openTeam, setOpenTeam] = useState(false);

  const {
    state: searchConditionState,
    reset: resetSearchCondition,
    selectPrefectureId,
    selectAreaIds,
    inSyncWith,
  } = useSearchCondition({ defaultPrefectureId });

  // エリア or チームの選択画面を開いている場合、背景スクロールを抑制する
  const toggleScroll = ToggleScroll.useContainer();
  useEffect(() => {
    // Skip if non PC.
    if (!checkIsPcByScreenWidth()) {
      !openArea && !openTeam ? toggleScroll.enable() : toggleScroll.disable();
    }
  }, [openArea, openTeam]);

  const rewriteRouter = getRouter();

  // 都道府県に紐付いた結合・単体エリアを１次元配列に変換する
  const combinedAreas = _.flatten(prefectures.map((p) => p.combinedAreas));
  const areas = _.flatten(combinedAreas.map((ca) => ca?.areas));

  const selectedCombinedAreas = searchConditionState.combinedAreaIds || [];
  const selectedAreas = searchConditionState.areaIds || [];
  const selectedPrefecture = searchConditionState.prefectureId || 0;
  const selectedMatchId = searchConditionState.matchId || 0;
  const selectedTournamentIdsTba = searchConditionState.tournamentIdsTba || [];
  const selectedTeamIds = searchConditionState.teamIds || [];

  // Try sync History(= URL params).
  // Changes of history will be propagated to SearchConditionState also.
  const doSync = (searchCondition: SearchCondition, doSyncHistory = false) => {
    const searchConditionForHistory = _.clone(searchCondition);

    // Change in-memory state first.
    resetSearchCondition(searchCondition);
    // Call eventHandler with latest params.
    onChange && onChange(searchCondition);
    // Skip sync History.
    if (!doSyncHistory) {
      return;
    }

    // 履歴向けには無加工の方を使う。
    const {
      prefectureId,
      combinedAreaIds,
      areaIds,
      teamIds,
      matchId,
      tournamentIdsTba,
    } = searchConditionForHistory;

    // SearchConditionを元にして選択状態をURLに反映する。
    searchQueryParams.syncHistory({
      combinedAreas: combinedAreaIds,
      areas: areaIds,
      teams: teamIds,
      match: matchId,
      prefecture: prefectureId,
      tba: tournamentIdsTba,
    });
  };

  // Apply queryParams to searchCondition.
  useEffect(() => {
    if (!searchQueryParams.isReady) {
      return;
    }

    const { areas, combinedAreas, teams, prefecture, match, tba } =
      searchQueryParams.params;

    doSync(
      _.pickBy(
        {
          ...searchCondition,
          combinedAreaIds: combinedAreas,
          areaIds: areas,
          teamIds: teams,
          prefectureId: prefecture,
          matchId: match,
          tournamentIdsTba: tba,
        },
        _.identity
      ) as SearchCondition
    );
    // Only updates on mount.
  }, [searchQueryParams.isReady]);

  // broswer forward backward button
  const { beforePopState, pathname } = useRouter();
  useEffect(() => {
    if (window) {
      beforePopState(({ url, as }) => {
        const matchResult = rewriteRouter.match(as);

        if (matchResult) {
          // Server Side Render static urls
          window.location.href = as;
          return false;
        }

        const [isInSync, diff] = inSyncWith(
          searchConditionState,
          url.split("?")[1] || ""
        );

        // Early return if in-sync.
        // SEE: https://github.com/vercel/next.js/issues/6784
        // return true since we are not interested in hard linking
        if (isInSync) {
          return true;
        }

        // Apply nextSearchCondition if needed.
        const newSearchCondition = {
          ...searchConditionState,
          ...diff,
        };

        resetSearchCondition(newSearchCondition);
        searchQueryParams.syncHistoryBeforePopState(parseRelativeUrl(as).query);

        // if leaving path no need to call onChange
        // e.g. /shops -> /matches
        const isSamePath = as.includes(pathname);
        if (isSamePath) {
          onChange && onChange(newSearchCondition);
        }

        return true;
      });
    }

    return () => {
      // reset on unmount
      if (window) {
        beforePopState(() => true);
      }
    };
  }, [resetSearchCondition, searchConditionState, inSyncWith]);

  // 選択した結合エリアが持つ単体エリアIDを取得
  // ついでに選択した結合エリア名も取得
  const includeCombinedAreaIds: Array<number> = [];
  const combinedAreaName: Array<string> = selectedCombinedAreas.map(
    (selectedCombinedArea: number) =>
      combinedAreas.find((combinedArea) => {
        // 選択済みの結合エリアに含まれる単体エリアを除外リストに入れる
        if (selectedCombinedArea === combinedArea?.id) {
          combinedArea?.areas?.map((area) => {
            includeCombinedAreaIds.push(area.id);
          });
          return true;
        }
        return false;
      })?.name || ""
  );

  // 選択された結合エリアに含まれる単体エリアも選択済みにする
  if (_.difference(includeCombinedAreaIds, selectedAreas).length !== 0) {
    selectAreaIds(_.union(includeCombinedAreaIds, selectedAreas));
  }

  // フォームに表示するエリア・チーム名の設定
  const areaLabel = (): string => {
    if (!selectedAreas.length) {
      return emptyAreaLabel;
    }

    // 選択した結合エリアに含まれる単体エリアを除外する
    const filteredAreas = selectedAreas.filter(
      (selectedArea: number) => !includeCombinedAreaIds.includes(selectedArea)
    );
    const areasName = filteredAreas.map(
      (filteredArea: number) =>
        areas.find((area) => filteredArea === area?.id)?.name || ""
    );

    return (
      _.compact(combinedAreaName.concat(areasName)).join(",") || emptyAreaLabel
    );
  };

  // エリアの選択解除時
  const clearSelectedAreas = (): void => {
    doSync(
      {
        ...searchCondition,
        combinedAreaIds: [] as number[],
        // 常にトーナメントの選択を解除する。
        tournamentIds: [],
        areaIds: [] as number[],
      } as SearchCondition,
      shouldSyncHistory
    );
  };

  // チームの選択解除時
  const clearSelectedTeamsAndTba = (): void => {
    doSync(
      {
        ...searchCondition,
        // 試合の選択状態も解除する。
        matchId: 0,
        teamIds: [],
        tournamentIdsTba: [],
      } as SearchCondition,
      shouldSyncHistory
    );
  };

  const searchCondition = {
    prefectureId: selectedPrefecture,
    combinedAreaIds: selectedCombinedAreas,
    matchId: selectedMatchId,
    areaIds: selectedAreas,
    teamIds: selectedTeamIds,
    tournamentIdsTba: selectedTournamentIdsTba,
  };

  const isEmptyArea = areaLabel() === emptyAreaLabel;

  const onSelectTeamAndTba = (
    teamIds: Array<number>,
    tournamentIdsTba: Array<number>
  ): void => {
    // チームの絞り込みの変更時はmatchの選択を無視(除外)する。
    const nextSearchCondition = {
      ...searchCondition,
      // 試合の選択状態も解除する。
      matchId: 0,
      teamIds,
      tournamentIdsTba,
    } as SearchCondition;

    doSync(nextSearchCondition, shouldSyncHistory);
  };

  return (
    <div className={CssStyles[className]}>
      <div className="l-main__innerWidth">
        <ul
          className={classNames(styles.list, {
            [styles.mergeList]:
              showSearchArea && className !== "keyVisualSearch",
          })}
        >
          {showSearchArea && (
            <li id="searchArea" className={styles.item}>
              <div
                onClick={(): void => {
                  setOpenTeam(false);
                  // Toggle UI
                  setOpenArea((v) => !v);
                }}
              >
                {isDisplayingSearchTitle && (
                  <div className="none md:block">
                    <Text tag="h2" size={15} bold>
                      エリアから探す
                    </Text>
                  </div>
                )}
                <div
                  className={classNames(styles.select, styles.area, {
                    [styles.notSelect]: !isEmptyArea,
                  })}
                >
                  <select
                    className={classNames(
                      {
                        [styles.isEmpty]: isEmptyArea,
                      },
                      styles.textEllipsis
                    )}
                    name="area"
                    tabIndex={-1}
                  >
                    <option data-test="area-label" value="">
                      {areaLabel()}
                    </option>
                  </select>
                </div>
              </div>

              {(!_.isEmpty(searchCondition.areaIds) ||
                !_.isEmpty(searchCondition.combinedAreaIds)) && (
                <div
                  className={styles.reset}
                  onClick={(e): void => {
                    e.stopPropagation();
                    clearSelectedAreas();
                  }}
                ></div>
              )}

              <div className="none md:block">
                <SearchAreaPc
                  openArea={openArea}
                  setOpenArea={setOpenArea}
                  prefectures={prefectures}
                  selectedPrefecture={selectedPrefecture}
                  onSelectPrefecture={(prefectureId?: number): void => {
                    // 都道府県IDが指定されない場合、デフォルトの都道府県ID（東京都）を設定する
                    if (prefectureId === undefined) {
                      prefectureId = defaultPrefectureId;
                    }

                    const nextSearchCondition = {
                      ...searchCondition,
                      prefectureId,
                    } as SearchCondition;

                    // 県の選択状態の変更時は、URLの変更は行わない。
                    selectPrefectureId(prefectureId);

                    // Call eventHandler with latest params.
                    onChange && onChange(nextSearchCondition);
                  }}
                  selectedArea={selectedAreas}
                  onSelectArea={(
                    combinedAreaIds: Array<number>,
                    areaIds: Array<number>
                  ): void => {
                    doSync(
                      {
                        ...searchCondition,
                        combinedAreaIds,
                        areaIds,
                        // エリアの絞り込みの変更時はmatchの選択状況を保つ。
                        matchId: selectedMatchId,
                      } as SearchCondition,
                      shouldSyncHistory
                    );
                  }}
                  submitText={submitText}
                  isCompact={isCompactPc}
                />
              </div>
            </li>
          )}

          <li id="searchTeam" className={styles.item}>
            <div
              onClick={(): void => {
                setOpenArea(false);
                // Toggle UI
                setOpenTeam((v) => !v);
              }}
            >
              {isDisplayingSearchTitle && (
                <div className="none md:block">
                  <Text tag="h2" size={15} bold>
                    {teamSearchTitle ? teamSearchTitle : "チームから探す"}
                  </Text>
                </div>
              )}
              <div
                className={classNames(
                  _.isEmpty(searchCondition.teamIds)
                    ? [styles.select, styles[teamSearchIcon]]
                    : [styles.select, styles.notSelect, styles[teamSearchIcon]]
                )}
              >
                <FilterLabel
                  defaultLabel={teamSearchPlaceholder}
                  tournaments={tournaments || []}
                  allTeams={allTeamsForFilterLabel}
                  selectedTeamIds={selectedTeamIds}
                  selectedTournamentIdsTba={selectedTournamentIdsTba}
                />
              </div>
              {(!_.isEmpty(searchCondition.teamIds) ||
                !_.isEmpty(searchCondition.tournamentIdsTba)) && (
                <div
                  className={styles.reset}
                  onClick={(e): void => {
                    e.stopPropagation();
                    clearSelectedTeamsAndTba();
                  }}
                ></div>
              )}
            </div>
            <div className="none md:block">
              <SearchTeamPc
                tournaments={tournaments}
                selectedMasterTournamentId={tournamentId}
                selectMasterTournament={setTournamentId}
                teams={teamsForSearch || []}
                openTeam={openTeam}
                setOpenTeam={setOpenTeam}
                onSelectTeamAndTba={onSelectTeamAndTba}
                selectedTeamIds={selectedTeamIds}
                selectedTournamentIdsTba={selectedTournamentIdsTba}
                submitText={submitText}
                teamSearchPosition={teamSearchPosition}
                isCompact={isCompactPc}
              />
            </div>
          </li>
        </ul>

        {children}
      </div>

      <div className="md:none">
        {showSearchArea && (
          <SearchArea
            openArea={openArea}
            setOpenArea={(flag: boolean): void => {
              setOpenArea(flag);
            }}
            prefectures={prefectures}
            selectedPrefecture={selectedPrefecture}
            onSelectPrefecture={(prefectureId?: number): void => {
              // 都道府県IDが指定されない場合、デフォルトの都道府県ID（東京都）を設定する
              if (prefectureId === undefined) {
                prefectureId = defaultPrefectureId;
              }

              const nextSearchCondition = {
                ...searchCondition,
                // 常にトーナメントの選択を解除する。
                tournamentId: 0,
                prefectureId,
              } as SearchCondition;

              // 県の選択状態の変更時は、URLの変更は行わない。
              selectPrefectureId(prefectureId);

              // Call eventHandler with latest params.
              onChange && onChange(nextSearchCondition);
            }}
            selectedArea={selectedAreas}
            onSelectArea={(
              combinedAreaIds: Array<number>,
              areaIds: Array<number>
            ): void => {
              doSync(
                {
                  ...searchCondition,
                  combinedAreaIds,
                  areaIds,
                  // 常にトーナメントの選択を解除する。
                  tournamentId: 0,
                  // エリアの絞り込みの変更時はmatchの選択状況を保つ。
                  matchId: selectedMatchId,
                } as SearchCondition,
                shouldSyncHistory
              );
            }}
            submitText={submitText}
          />
        )}

        <SearchTeam
          tournaments={tournaments}
          selectedMasterTournamentId={tournamentId}
          selectMasterTournament={setTournamentId}
          teams={teamsForSearch || []}
          openTeam={openTeam}
          setOpenTeam={(flag: boolean): void => {
            setOpenTeam(flag);
          }}
          selectedTeamIds={selectedTeamIds}
          selectedTournamentIdsTba={selectedTournamentIdsTba}
          submitText={submitText}
          onSelectTeamAndTba={onSelectTeamAndTba}
        />
      </div>
    </div>
  );
};
