import { createSignalIfSupported } from "@apollo/client";
import { useEffect } from "react";

export const abortPendingRequestIfSupported = () => {
  // SEE: [Cancellable apollo-client queries :: OpenHood](https://experiments.openhood.com/apollo-client/typescript/2020/10/23/apollo-client-cancellable-queries/)
  const { controller } = createSignalIfSupported();

  if (controller) {
    if (!(controller instanceof AbortController)) {
      return;
    }
    // Abort pending GQL request(fetch) if available.
    controller.abort();
  }
};

// Do abort pending request on unmount(mostly for "page" component).
export const useAbortPendingRequestOnUnmount = () => {
  useEffect(() => {
    return () => abortPendingRequestIfSupported();
  }, []);
};
