import { useRouter } from "next/router";
import Compose from "@components/Compose";
import LoginRequiredPage from "@components/LoginRequiredPage";
import AuthResult from "@hooks/useAuthResult";
import PhoneNumberBackup from "@hooks/usePhoneNumberBackup";
import { paths } from "@libs/paths";
import type { FC, ReactNode } from "react";

const PageAuthProviderSwitcher: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const router = useRouter();
  const pathname = router.pathname;

  // ログインしていないと表示できないページへの処理
  if (
    pathname.includes(paths.mypage) ||
    pathname.includes(paths.notificationsList) ||
    pathname.includes(paths.lotteryCampaigns) ||
    /\/shops\/.*\/request_reservation/.test(pathname)
  ) {
    return <LoginRequiredPage>{children}</LoginRequiredPage>;
  }

  // login, signup以下のページへの処理
  if (pathname.includes(paths.login) || pathname.includes(paths.signup)) {
    return (
      <Compose components={[AuthResult.Provider, PhoneNumberBackup.Provider]}>
        {children}
      </Compose>
    );
  }

  return <>{children}</>;
};

export default PageAuthProviderSwitcher;
