import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Button } from "@components/Button";
import { TeamList } from "@components/SearchInput/SearchTeamPc/TeamList";
import { TournamentTabsPc } from "@components/SearchInput/SearchTeamPc/TournamentTabsPc";
import { SearchItemPc } from "@components/SearchInput/_SearchItemPc";
import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import type { CategoryTab } from "@libs/utils/teams";
import { getCategorizedTabs } from "@libs/utils/teams";
import styles from "../_SearchItemPc/index.module.css";
import { useTeamSelection } from "../_useTeamSelection";
import type { SearchInput_TeamsQuery } from "../__generated__/queries";
import type { FC } from "react";

type Props = {
  tournaments: UseCommonData_CommonDataQuery["tournaments"] | undefined;
  selectedMasterTournamentId: number | undefined;
  selectMasterTournament: (tournamentId: number | null) => void | undefined;
  teams: SearchInput_TeamsQuery["teamsUnbundled"];
  openTeam: boolean;
  setOpenTeam: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectTeamAndTba: (
    teamIds: Array<number>,
    tournamentIds: Array<number>
  ) => void;
  selectedTournamentIdsTba: Array<number>;
  selectedTeamIds: Array<number>;
  submitText: string;
  teamSearchPosition?: string;
  isCompact?: boolean;
};

export const SearchTeamPc: FC<Props> = ({
  tournaments,
  selectedMasterTournamentId,
  selectMasterTournament,
  teams,
  openTeam,
  setOpenTeam,
  onSelectTeamAndTba,
  selectedTournamentIdsTba,
  selectedTeamIds,
  submitText,
  teamSearchPosition,
  isCompact,
}) => {
  const [categorizedTabs, setCategorizedTabs] = useState<CategoryTab[]>([]);
  const [selectedCategoryTab, selectCategoryTab] = useState<CategoryTab | null>(
    null
  );
  const draftSelection = useTeamSelection({
    tournaments: tournaments ?? [],
    teams: teams,
    activeTournamentId: selectedMasterTournamentId,
    initialSelectedTeamIds: selectedTeamIds,
    initialTournamentTbaSelected: selectedTournamentIdsTba,
  });

  // テキストボックスのクリアボタンが使用された場合は、選択状況を親に合わせる
  // モーダルを閉じてから次にモーダルを開くまでに選択状況が変更されていたら
  // テキストボックスのクリアボタンが使用されたものとみなす
  type LastSelectionType = { teams: Set<number>; tbas: Set<number> };
  const [lastSelectionOnClosed, setLastSelectionOnClosed] =
    useState<LastSelectionType>({
      teams: new Set(selectedTeamIds),
      tbas: new Set(selectedTournamentIdsTba),
    });
  const areEqualSets = (a: Set<number>, b: Set<number>) =>
    a.size === b.size && [...a].every((i) => b.has(i));
  const isSelectionChangedWithoutModal = () => {
    const isTeamsChanegd = !areEqualSets(
      new Set(selectedTeamIds),
      lastSelectionOnClosed.teams
    );
    const isTbasChanged = !areEqualSets(
      new Set(selectedTournamentIdsTba),
      lastSelectionOnClosed.tbas
    );
    return isTeamsChanegd || isTbasChanged;
  };
  useEffect(() => {
    // 開いた時
    if (openTeam) {
      if (isSelectionChangedWithoutModal()) {
        draftSelection.setSelectedTeamIds(new Set(selectedTeamIds));
        draftSelection.setTournamentsTbaSelected(
          new Set(selectedTournamentIdsTba)
        );
      }
    }

    // 閉じた時
    if (!openTeam) {
      setLastSelectionOnClosed({
        teams: new Set(selectedTeamIds),
        tbas: new Set(selectedTournamentIdsTba),
      });
    }
  }, [openTeam]);

  useEffect(() => {
    // create data for tabs based on 'tournaments' prop
    if (tournaments?.length) {
      setCategorizedTabs(getCategorizedTabs(tournaments));
    }
  }, [tournaments]);

  const hasTwoOrMoreTournamentTabs =
    selectedCategoryTab?.tournamentTabs &&
    selectedCategoryTab?.tournamentTabs.length > 1;

  const toggleAllCheckboxLabel = useMemo(
    () =>
      draftSelection.activeTournament
        ? draftSelection.activeTournament.allTeamsLabel
        : "",
    [draftSelection.activeTournament]
  );

  const requiresAltTeamIcons = useMemo(() => {
    if (tournaments === undefined) {
      return false;
    }

    if (selectedMasterTournamentId === undefined) {
      return false;
    }

    return (
      tournaments.find((t) => t.id === selectedMasterTournamentId)
        ?.requiresAltTeamIcons ?? false
    );
  }, [tournaments, selectedMasterTournamentId]);

  if (!openTeam) {
    return null;
  }

  return (
    <SearchItemPc
      onClose={() => {
        setOpenTeam(false);
      }}
      teamSearchPosition={teamSearchPosition}
      className={classNames(styles.teamWrappr, {
        [styles.compact]: isCompact,
      })}
    >
      <TournamentTabsPc
        onSelectCategoryTab={selectCategoryTab}
        onSelectTournament={selectMasterTournament}
        categorizedTabs={categorizedTabs}
        selectedTournamentId={selectedMasterTournamentId}
        hideTournamentTabs={!hasTwoOrMoreTournamentTabs}
      />
      <div className={styles.inner}>
        <TeamList
          selectedMasterTournamentId={selectedMasterTournamentId}
          teams={teams}
          toggleAllCheckboxLabel={toggleAllCheckboxLabel}
          draftSelection={draftSelection}
          requiresAltTeamIcons={requiresAltTeamIcons}
        />
        <div className={styles.buttons}>
          <Button
            className={styles.reset}
            styleType="filled"
            colorType="secondary"
            onClick={draftSelection.clearAll}
            disabled={!draftSelection.isClearable}
          >
            クリア
          </Button>
          <Button
            className={styles.submit}
            styleType="filled"
            colorType="primary"
            onClick={(
              e: React.MouseEvent<
                HTMLButtonElement | HTMLAnchorElement,
                MouseEvent
              >
            ): void => {
              e.stopPropagation();
              setOpenTeam(false);
              onSelectTeamAndTba(
                [...draftSelection.selectedTeamIds],
                [...draftSelection.tournamentsTbaSelected]
              );
            }}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </SearchItemPc>
  );
};
