import classNames from "classnames";
import { forwardRef, useEffect, useRef } from "react";
import styles from "./index.module.css";

type Tab = {
  label: string;
  value: number | string;
};

type TabProps = {
  selected: number | string;
  tabs: Tab[];
  onSelect: (tab: Tab) => void;
};

export type TabElements = Record<number | string, HTMLLIElement>;

export const Index = forwardRef<TabElements, TabProps>(
  ({ selected, tabs, onSelect }, ref) => {
    const refsByValue = useRef<TabElements>({});

    useEffect(() => {
      if (!ref) {
        return;
      }
      if (typeof ref === "function") {
        ref(refsByValue.current);
      } else if (ref.current) {
        ref.current = refsByValue.current;
      }
    }, [ref, refsByValue]);

    return (
      <div className={styles.tabs}>
        <ul className={styles.tabList}>
          {tabs.map((tab) => (
            <li
              className={classNames([
                styles.tabItem,
                selected === tab.value && styles.tabActive,
              ])}
              key={tab.value}
              onClick={() => onSelect(tab)}
              ref={(element): void => {
                if (element) {
                  refsByValue.current[tab.value] = element;
                }
              }}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
    );
  }
);
