import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import type { TypeSearchQueryParams } from "@hooks/useSearchQueryParams";
import { reduceStaticParameters, translateTeamParameters } from "./common";

export const getStaticRepresentation = (
  commonData: UseCommonData_CommonDataQuery,
  params: TypeSearchQueryParams
): string | null => {
  const parametersForMatchesStaticUrl = translateTeamParameters(
    commonData,
    params
  );
  // No error was thrown but need to check if any static parameters were returned
  // All static URLs start with a genre parameter (baseYmd does not exist in dynamic URLs)
  const hasStaticRepresentation = parametersForMatchesStaticUrl.genre;
  if (hasStaticRepresentation) {
    return reduceStaticParameters(parametersForMatchesStaticUrl, "/matches");
  }
  return null;
};
