import Link from "next/link";
import * as React from "react";
import { Button } from "@components/Button";
import NotificationBadge from "@components/CommonLayout/CommonHeader/HeaderButtons/NotificationBadge";
import HeaderPoint from "@components/CommonLayout/CommonHeader/HeaderPoint";
import { paths } from "@libs/paths";
import styles from "./index.module.css";

type Props = {
  headerFixed: boolean;
  shouldShow: boolean;
  isAuthStatusFixed: boolean;
  isAuthenticated: boolean;
  shouldShowPoint: boolean;
  points?: number;
};

const HeaderButtons: React.FC<Props> = ({
  headerFixed,
  shouldShow,
  isAuthStatusFixed,
  isAuthenticated,
  shouldShowPoint,
  points,
}) => {
  if (headerFixed) {
    return (
      <div className={styles.headerContact}>
        <Button
          className={styles.btn}
          styleType="filled"
          colorType="primary"
          href={paths.contact}
          id="contact-link-header"
        >
          お問い合わせ
        </Button>
      </div>
    );
  }

  if (!shouldShow) {
    return null;
  }

  return (
    <div className={styles.btns}>
      {shouldShowPoint && (
        <div className="none md:block">
          <HeaderPoint
            isAuthStatusFixed={isAuthStatusFixed}
            isAuthenticated={isAuthenticated}
            points={points}
          />
        </div>
      )}
      {isAuthenticated ? (
        <>
          <Link href={paths.notificationsList} className={styles.notifications}>
            お知らせ
            <NotificationBadge />
          </Link>
          <Link href={paths.mypage} className={styles.mypage}>
            マイページ
          </Link>
        </>
      ) : (
        <>
          <Link href={paths.login} legacyBehavior>
            <Button
              className={styles.login}
              styleType="outlined"
              colorType="primary"
            >
              ログイン
            </Button>
          </Link>
          <Link href={paths.signup} legacyBehavior>
            <Button
              className={styles.signup}
              styleType="filled"
              colorType="primary"
            >
              新規登録
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default HeaderButtons;
