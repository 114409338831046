import { useState, useEffect } from "react";
import { createContainer } from "unstated-next";
import { checkIsPcByScreenWidth } from "@libs/utils/browser";

const useIsPc = () => {
  const [isPc, setIsPc] = useState(false);
  const resizeHandler = () => setIsPc(checkIsPcByScreenWidth());
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => window.removeEventListener("resize", resizeHandler);
  });

  return isPc;
};

const IsPc = createContainer(useIsPc);
export default IsPc;
