import classNames from "classnames";
import { useState } from "react";
import * as React from "react";
import { useSwipeable } from "react-swipeable";
import styles from "./index.module.css";
import type { RefObject } from "react";
import type { SwipeableHandlers } from "react-swipeable";

type SearchAreaProps = {
  openContent: boolean;
  openStatus: number;
  setOpenStatus: (num: number) => void;
  enableSwipe: boolean;
  setEnableSwipe: (flag: boolean) => void;
  setScroll: (flag: boolean) => void;
  contentRef?: RefObject<HTMLDivElement>;
  doSearch: boolean;
  tabs?: React.ReactElement;
  onAnimationEnd?: () => void;
  onClose: () => void;
  children: React.ReactNode;
};

export const OPEN_STATUS = {
  CLOSED: 0,
  CHANGING: 1,
  OPENED: 2,
} as const;

type OPEN_STATUS = (typeof OPEN_STATUS)[keyof typeof OPEN_STATUS];

// ボタンを含むフッターの高さ
const MODAL_FOOTER_HEIGHT = 66;

export const SearchItem: React.FC<SearchAreaProps> = ({
  openContent,
  openStatus,
  setOpenStatus,
  enableSwipe,
  setEnableSwipe,
  setScroll,
  contentRef,
  doSearch,
  tabs,
  onAnimationEnd,
  onClose,
  children,
}) => {
  const [contentPosition, setContentPosition] = useState(0);

  // スクロール中の挙動
  const scrollEvent = (e: Event): void => {
    if (e.target === null) {
      return;
    }

    // 結合・単体エリアのスクロール位置が一番上ならスワイプが効くようにする
    // ※ マイナスも範囲に入れているのはiOS/Safari対策
    const element = e.target as HTMLDivElement;
    if (element.scrollTop <= 0) {
      setEnableSwipe(true);
    } else {
      setEnableSwipe(false);
    }
  };
  if (contentRef && contentRef.current) {
    contentRef.current.addEventListener("scroll", scrollEvent);
  }

  // 複数のハンドラーを使用する場合、それぞれハンドラーを生成する必要がある
  // ref: https://github.com/FormidableLabs/react-swipeable/issues/250
  const CreateSwipeHandler = (alwaysSwipe: boolean): SwipeableHandlers => {
    return useSwipeable({
      trackMouse: false,
      onSwiping: (eventData) => {
        // 初期位置から上方向に移動しないように抑制
        if ((alwaysSwipe || enableSwipe) && eventData.deltaY > 0) {
          setContentPosition(eventData.deltaY);
        }

        if (eventData.dir === "Down" && enableSwipe) {
          setScroll(false);
        }
      },
      onSwiped: (eventData) => {
        // 元の位置に戻した時にはスクロール出来るように戻す
        if (
          (alwaysSwipe || enableSwipe) &&
          eventData.deltaY > MODAL_FOOTER_HEIGHT
        ) {
          // ある一定以上の範囲（MODAL_FOOTER_HEIGHT）を超えてスワイプしたら閉じる
          onClose();
        } else {
          // 一定値を超えない場合には元の開いた位置に戻る
          setContentPosition(0);
        }

        setScroll(true);
      },
    });
  };
  const contentSwipeHandler = CreateSwipeHandler(false);
  const borderAreaSwipeHandler = CreateSwipeHandler(true);

  // スワイプ中の検索UIのポジションを変化させる
  const searchAreaPositionStyle = {
    "--delta-y": contentPosition + "px",
  } as React.CSSProperties;

  return (
    <div className={openContent ? classNames(styles.cover) : classNames()}>
      <div
        className={
          openContent
            ? classNames(styles.root, styles.open, styles.slideIn)
            : classNames(styles.root, styles.open, styles.slideOut)
        }
        onAnimationStart={(): void => {
          setOpenStatus(OPEN_STATUS.CHANGING);
        }}
        onAnimationEnd={(): void => {
          if (openContent) {
            setOpenStatus(OPEN_STATUS.OPENED);
          } else {
            setOpenStatus(OPEN_STATUS.CLOSED);
            setContentPosition(0);

            // bfcacheによる描画が残る問題への対策のため
            // UIが閉じ切るまで画面遷移を実行しない
            if (doSearch) {
              if (onAnimationEnd) {
                onAnimationEnd();
              }
            }
          }
        }}
      >
        <div className={styles.overlay}>
          <div
            className={classNames(styles.content)}
            style={searchAreaPositionStyle}
          >
            <div className={styles.borderArea} {...borderAreaSwipeHandler}>
              <div
                className={
                  openContent
                    ? openStatus === OPEN_STATUS.CHANGING ||
                      openStatus === OPEN_STATUS.OPENED
                      ? classNames(styles.border, styles.borderDown)
                      : classNames(styles.border)
                    : classNames(styles.border)
                }
                onClick={(): void => {
                  onClose();
                }}
              />
            </div>

            {tabs || null}

            <div className={styles.form} {...contentSwipeHandler}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
