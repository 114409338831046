import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button } from "@components/Button";
import { SearchItemPc } from "@components/SearchInput/_SearchItemPc";
import type { UseCommonData_MasterPrefectureFragment as MasterPrefectureFragment } from "@hooks/useCommonData/__generated__/fragments";
import styles from "../_SearchItemPc/index.module.css";
import { useAreaSelection } from "../_useAreaSelection";
import type { FC } from "react";

type SearchAreaPcProps = {
  openArea: boolean;
  setOpenArea: React.Dispatch<React.SetStateAction<boolean>>;
  prefectures: Array<MasterPrefectureFragment>;
  selectedPrefecture: number;
  onSelectPrefecture: (prefectureId?: number) => void;
  selectedArea: Array<number>;
  onSelectArea: (combinedAreaIds: number[], areaIds: number[]) => void;
  submitText: string;
  isCompact?: boolean;
};

// Split array based on columnSize.
// JSXとして認識されないよう <T,> としている
const columunizeArray = <T,>(
  arr: Array<T>,
  columnSize = 3
): Array<Array<T>> => {
  return _.transform(
    _.clone(arr),
    (acc, row, i) => {
      const column = i % columnSize;
      if (!acc[column]) {
        acc[column] = [];
      }
      acc[column].push(row);
    },
    [] as Array<Array<T>>
  );
};

export const SearchAreaPc: FC<SearchAreaPcProps> = ({
  openArea,
  setOpenArea,
  prefectures,
  selectedPrefecture,
  onSelectPrefecture,
  selectedArea,
  onSelectArea,
  submitText,
  isCompact,
}) => {
  const selection = useAreaSelection({
    allPrefectures: prefectures,
    selectedPrefectureId: selectedPrefecture,
    initialSelectedAreaIds: selectedArea,
  });

  // 最後にモーダルを閉じた時から選択状況が変化していたら、テキストボックスのクリアボタンが使用されたものとして状態を同期する
  const [lastSelectedOnClosing, setLastSelectedOnClosing] =
    useState<number[]>(selectedArea);
  useEffect(() => {
    // モーダルを開いた
    if (openArea) {
      // 順序を問わず`lastSelectedOnClosing`と`selectedArea`に差異があるかどうか
      const isChangedWithoutModal =
        lastSelectedOnClosing.length !== selectedArea.length ||
        lastSelectedOnClosing.some((a) => !selectedArea.includes(a));
      if (isChangedWithoutModal) {
        selection.setSelectedAreas(selectedArea);
      }
    }

    // モーダルを閉じた
    if (!openArea) {
      setLastSelectedOnClosing(selectedArea);
    }
  }, [openArea]);

  // 検索UIを閉じた & 開閉ステータスも"closed"になったら非表示にする
  if (!openArea) {
    return null;
  }

  // 擬似的なMasonryレイアウト + 列固定とするために3カラムの配列に変換する。
  const chunkedCombinedAreas = columunizeArray(
    selection.selectedPrefecture?.combinedAreas || [],
    3
  );

  return (
    <SearchItemPc
      onClose={() => setOpenArea(false)}
      className={classNames(styles.areaWrappr, {
        [styles.compact]: isCompact,
      })}
    >
      <div className={styles.inner}>
        <div className={styles.areaSelect}>
          <select
            name="prefecture"
            value={selectedPrefecture}
            onChange={({ target: { value } }) =>
              onSelectPrefecture(parseInt(value))
            }
          >
            {selection.targetPrefectures.map((prefecture) => (
              <option value={prefecture.id} key={prefecture.id}>
                {prefecture.name}
              </option>
            ))}
          </select>
        </div>
        <div className={classNames(styles.items, styles.areaItems)}>
          {chunkedCombinedAreas.map((column) => {
            const key = column[0].id;
            // カラム毎に縦に並べる(= 疑似Masonry)
            return (
              <div className={styles["w-1/3"]} key={key}>
                {column.map((combinedArea) => {
                  return (
                    <div className={styles.item} key={combinedArea.id}>
                      <div className={styles.itemMain}>
                        <label className={styles.check}>
                          <input
                            type="checkbox"
                            name="areaMain"
                            value={combinedArea.name}
                            checked={selection.isSelectedCombinedArea(
                              combinedArea.id
                            )}
                            onChange={() =>
                              selection.toggleCombinedAreaSelection(
                                combinedArea.id
                              )
                            }
                          />
                          <p className={styles.checkText}>
                            <span>{combinedArea.name}</span>
                          </p>
                        </label>
                      </div>
                      {selection.isPartialSelectedCombinedArea(
                        combinedArea.id
                      ) &&
                        combinedArea?.areas?.map((area) => (
                          <div className={styles.itemSub} key={area.id}>
                            <label className={styles.check}>
                              <input
                                type="checkbox"
                                name="areaSub"
                                value={area.name}
                                checked={selection.isSelectedArea(area.id)}
                                onChange={() =>
                                  selection.toggleAreaSelection(area.id)
                                }
                              />
                              <p className={styles.checkText}>
                                <span>{area.name}</span>
                              </p>
                            </label>
                          </div>
                        ))}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.reset}
            styleType="filled"
            colorType="secondary"
            onClick={selection.clearAll}
            disabled={!selection.isClearable}
          >
            クリア
          </Button>
          <Button
            className={styles.submit}
            styleType="filled"
            colorType="primary"
            onClick={(
              e: React.MouseEvent<
                HTMLButtonElement | HTMLAnchorElement,
                MouseEvent
              >
            ): void => {
              e.stopPropagation();
              setOpenArea(false);
              onSelectArea(
                [...selection.selectedCombinedAreaIds],
                [...selection.selectedAreaIds]
              );
            }}
          >
            {submitText}
          </Button>
        </div>
      </div>
    </SearchItemPc>
  );
};
