import Bowser from "bowser";
import {
  USER_AGENT_ANDROID_REGEX,
  USER_AGENT_FANSTA_APP,
  USER_AGENT_IOS_REGEX,
} from "../../constants";
import designTokens from "../../styles/design-tokens.json";

const { breakpoints } = designTokens;

// SEE: [express - How do I detect whether I am on server on client in next.js? - Stack Overflow](https://stackoverflow.com/a/59562136/9998350)
export const isBrowser = () => {
  return typeof window !== "undefined";
};

// UserAgentによってPC判定をする関数
export const checkIsPcByUA = (): boolean => {
  // TODO: `process.browser` を参照して分岐するとSSR時にhydration errorになり得るので下記の公式ドキュメントを参考に修正する。
  // SEE: [react-hydration-error | Next.js](https://nextjs.org/docs/messages/react-hydration-error)
  if (!process.browser) {
    return false;
  }
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.is("desktop");
};

// 画面幅によってPC判定をする関数
export const checkIsPcByScreenWidth = (): boolean => {
  // Always return false for SSR.
  if (!isBrowser()) {
    return false;
  }
  const { matches } = window.matchMedia(
    `(min-width: ${breakpoints.tablet.value})`
  );
  return matches;
};

/**
 * 指定されたUserAgent文字列を元にアプリのWebViewからのアクセスかどうかを取得
 */
export const checkIsMobileApp = (userAgent: string): boolean => {
  return !!userAgent.match(USER_AGENT_FANSTA_APP);
};

export const getUserAgent = (): string =>
  process.browser ? window.navigator.userAgent : "";

export const checkIsIOS = (userAgent: string): boolean => {
  return !!userAgent.match(USER_AGENT_IOS_REGEX);
};

export const checkIsAndroid = (userAgent: string): boolean => {
  return !!userAgent.match(USER_AGENT_ANDROID_REGEX);
};
