import { useState, useEffect } from "react";
import { checkIsMobileApp, getUserAgent } from "@libs/utils/browser";

/**
 * FanstaアプリのWebViewからのアクセスかどうかを取得
 */
function useAccessFromApp(): boolean {
  const ua = getUserAgent();

  const [isFanstaMobileApp, setIsFanstaMobileApp] = useState(false);

  useEffect(() => {
    setIsFanstaMobileApp(checkIsMobileApp(ua));
  }, [ua]);

  return isFanstaMobileApp;
}

export default useAccessFromApp;
