import { IFImg } from "@libs/utils/IFImg";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  brand: string;
};

const availableBrands = ["visa", "mastercard", "amex", "diners", "jcb"];

export const CreditCardBrandIcon: FC<Props> = ({ brand }) => {
  const fileName = availableBrands.includes(brand) ? brand : "fallback";

  return (
    <div className={styles.card}>
      <img
        src={IFImg(`/img/icon/credit_card_brand/${fileName}.png`)}
        alt=""
        width={35}
        height={24}
      />
    </div>
  );
};
