import { useCallback, useState } from "react";
import * as React from "react";
import { createContainer } from "unstated-next";
import styles from "./index.module.css";

const MessageType = {
  SUCCESS: "success",
  CAUTION: "caution",
  ERROR: "error",
  INFO: "information",
};
export type MessageType = (typeof MessageType)[keyof typeof MessageType];

export type PredefinedMessage = {
  id: number;
  value: string | React.ReactElement;
  messageType: MessageType;
  canDelete: boolean;
  isAutoClose: boolean;
};

export const PredefinedMessages = {
  loginWithAccountExists: {
    id: 1,
    value: "ログインしました。",
    messageType: MessageType.SUCCESS,
    canDelete: true,
    isAutoClose: true,
  },
  loginWithAccountNotExists: {
    id: 2,
    value: "アカウントは存在しません。新規登録してください。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  signupWithAccountExists: {
    id: 3,
    value: "アカウント登録済のため、ログインしました。",
    messageType: MessageType.SUCCESS,
    canDelete: true,
    isAutoClose: true,
  },
  signupWithAccountNotExists: {
    id: 4,
    value: "アカウント登録が完了しました。",
    messageType: MessageType.SUCCESS,
    canDelete: true,
    isAutoClose: true,
  },
  emailVerificationRequested: {
    id: 5,
    value:
      "に確認メールを送信しました。メール内のURLをクリックしてメールアドレス確認を完了してください。",
    messageType: MessageType.CAUTION,
    canDelete: true,
    isAutoClose: true,
  },
  emailVerificationRequired: {
    id: 6,
    value: "メールアドレス確認を完了してください。",
    messageType: MessageType.CAUTION,
    canDelete: false,
    isAutoClose: false,
  },
  verificationUrlInvalid: {
    id: 7,
    value: "無効なURLです。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  emailRecovered: {
    id: 8,
    value: "メールアドレスをリセットしました。",
    messageType: MessageType.SUCCESS,
    canDelete: true,
    isAutoClose: true,
  },
  commonError: {
    id: 9,
    value: "エラーが発生しました。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  firebaseTooManyCodeRequests: {
    id: 10,
    value: "コードが送信できませんでした。時間をおいて再度お試しください。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  firebaseTooManyEmailRequests: {
    id: 11,
    value: "メールを送信できませんでした。時間をおいて再度お試しください。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  firebaseRequiresRecentLogin: {
    id: 12,
    value:
      "電話番号確認から時間が経っているため、新規登録できません。はじめからやり直してください。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  reservationNotConfirmed: {
    id: 14,
    value: "この予約はまだ確定しておりません。予約確定をお待ちください。",
    messageType: MessageType.CAUTION,
    canDelete: false,
    isAutoClose: false,
  },
  reservationCompleteNotConfirmed: (
    email: string,
    confirmationDeadlineText: string
  ) => ({
    id: 15,
    value: (
      <>
        <span className={styles.bold}>予約はまだ完了しておりません。</span>
        <br />
        {confirmationDeadlineText}までに、予約を受けられるか
        <br />
        <span className={styles.bold}>{email}</span>へご連絡いたします。
      </>
    ),
    messageType: MessageType.CAUTION,
    canDelete: false,
    isAutoClose: false,
  }),
  requestReservationFailed: {
    id: 15,
    value: "予約が確定できませんでした。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  offlineError: {
    id: 16,
    value:
      "ネットワークに接続できません。ネットワークの状況を確認し、もう一度お試しください。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  requestReservationNoAvailableFramesError: {
    id: 17,
    value: "現在ネット予約を受け付けておりません。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  giftExchangeTimeout: {
    id: 18,
    value:
      "商品交換中に問題が発生しました。交換履歴から商品が交換されているかをご確認ください。",
    messageType: MessageType.CAUTION,
    canDelete: true,
    isAutoClose: false,
  },
  requestReservationPlanInconsistentError: {
    id: 19,
    value: "店舗によりプランの内容が変更されたため、予約ができませんでした。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
  requestReservationCanceled: {
    id: 20,
    value: "この予約をキャンセルしました。",
    messageType: MessageType.SUCCESS,
    canDelete: true,
    isAutoClose: true,
  },
  requestReservationPaymentError: {
    id: 21,
    value: "決済でエラーが発生したため、予約ができませんでした。",
    messageType: MessageType.ERROR,
    canDelete: true,
    isAutoClose: true,
  },
};

type MessageContextType = {
  messages: PredefinedMessage[];
  addMessage: (message: PredefinedMessage) => void;
  removeMessage: (message: PredefinedMessage) => void;
};

const useMessage = (): MessageContextType => {
  const [messages, setMessages] = useState<PredefinedMessage[]>([]);

  const addMessage = useCallback(
    (message: PredefinedMessage): void => {
      if (
        messages.find(
          (m) =>
            m.value === message.value && m.messageType === message.messageType
        )
      ) {
        return;
      }
      setMessages((prev) => [...prev, message]);
    },
    [messages]
  );

  const removeMessage = (message: PredefinedMessage): void => {
    const i = messages.indexOf(message);
    if (i >= 0) {
      messages.splice(i, 1);
      setMessages([...messages]);
    }
  };

  return { messages, addMessage, removeMessage };
};

const Message = createContainer(useMessage);

export default Message;
