import { useState } from "react";
import { createContainer } from "unstated-next";
import type React from "react";

type PhoneNumberBackup = {
  phoneNumber: string;
  isTermsConfirmed: boolean;
};

type PhoneNumberBackupContextProps = {
  phoneNumberBackup: PhoneNumberBackup;
  setPhoneNumberBackup: React.Dispatch<React.SetStateAction<PhoneNumberBackup>>;
};

const defaultValue = {
  phoneNumber: "",
  isTermsConfirmed: false,
};

const usePhoneNumberBackup = (): PhoneNumberBackupContextProps => {
  // 認証コード再送ボタン押下時に入力枠に番号と同意チェックをリストアするのに使用
  const [phoneNumberBackup, setPhoneNumberBackup] = useState(defaultValue);

  return {
    phoneNumberBackup,
    setPhoneNumberBackup,
  };
};

const PhoneNumberBackup = createContainer(usePhoneNumberBackup);

export default PhoneNumberBackup;
