import Bowser from "bowser";
import { createContainer } from "unstated-next";
import { useHasMounted } from "@hooks/useHasMounted";

// サーバーサイドレンダ時はnullを返す
const useIsPcByUA: () => { isPcByUA: boolean | null } = () => {
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return { isPcByUA: null };
  }

  const browser = Bowser.getParser(window.navigator.userAgent);
  return { isPcByUA: browser.is("desktop") };
};

const isPcByUA = createContainer(useIsPcByUA);
export default isPcByUA;
