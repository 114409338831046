import { useEffect } from "react";
import ToggleScroll from "@hooks/useToggleScroll";
import { IFImg } from "@libs/utils/IFImg";
import styles from "./index.module.css";

type Props = {
  isShown: boolean;
  children: React.ReactNode;
  onBackgroundClick?: () => void;
  hasCloseButton?: boolean;
  onCloseButtonClick?: () => void;
};

export const FanstaDialog: React.FC<Props> = ({
  children,
  isShown,
  onBackgroundClick,
  hasCloseButton = false,
  onCloseButtonClick,
}) => {
  const backgroundScroll = ToggleScroll.useContainer();

  useEffect(() => {
    (isShown ? backgroundScroll.disable : backgroundScroll.enable)();

    return backgroundScroll.enable;
  }, [isShown]);

  return isShown ? (
    <div className={styles.dialog} onClick={onBackgroundClick}>
      <div className={styles.dialogInner}>
        <div className={styles.dialogBox} onClick={(e) => e.stopPropagation()}>
          {hasCloseButton && (
            <button className={styles.closeBtn} onClick={onCloseButtonClick}>
              <img
                src={IFImg("/img/icon_close_black.svg")}
                width={14}
                height={14}
                alt="閉じる"
              />
            </button>
          )}
          {children}
        </div>
      </div>
    </div>
  ) : null;
};

export default FanstaDialog;
