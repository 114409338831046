import cx from "classnames";
import { useState } from "react";
import { IFImg } from "@libs/utils/IFImg";
import type { FC } from "react";

/**
 * ImageFluxドキュメント
 * https://console.imageflux.jp/docs/image/conversion-parameters
 */
type ImageType =
  | "thumbnail"
  | "lpShopMobile"
  | "lpShop"
  | "shop"
  | "shopTop"
  | "viewEnvironment"
  | "fullScreen"
  | "og"
  | "news"
  | "defaultSize";

interface ImageTypeStatus {
  width: string | null;
  height: string | null;
  aspectMode: string;
}

const IMAGE_TYPES: Record<ImageType, ImageTypeStatus> = {
  thumbnail: {
    width: "210",
    height: "210",
    aspectMode: "2",
  },
  lpShopMobile: {
    width: "180",
    height: "269",
    aspectMode: "2",
  },
  lpShop: {
    width: "300",
    height: "302",
    aspectMode: "2",
  },
  shop: {
    width: "686",
    height: "320",
    aspectMode: "2",
  },
  shopTop: {
    width: "750",
    height: "500",
    aspectMode: "2",
  },
  viewEnvironment: {
    width: "420",
    height: "280",
    aspectMode: "2",
  },
  fullScreen: {
    width: "750",
    height: null,
    aspectMode: "0",
  },
  og: {
    width: "1200",
    height: "630",
    aspectMode: "2",
  },
  news: {
    width: "800",
    height: "800",
    aspectMode: "2",
  },
  defaultSize: {
    width: null,
    height: null,
    aspectMode: "0",
  },
};

type Props = {
  className?: string;
  imageType: ImageType;
  url: string;
  alt?: string;
};

export const getImageFluxUrl = (params: Props): string => {
  if (!params.url.match(/^http[s]?/)) {
    return params.url;
  }

  const parseURL = new URL(params.url);

  const query = (): string => {
    const queryStatus =
      IMAGE_TYPES[params.imageType] || IMAGE_TYPES.defaultSize;
    const q = Object.entries({
      f: "webp:auto",
      ir: "auto", // ExifのOrientationを使って画像を正しい向きに回転させる
      w: queryStatus.width,
      h: queryStatus.height,
      a: queryStatus.aspectMode,
    })
      .reduce((acc: string[], [key, value]) => {
        if (value) {
          acc.push(`${key}=${value}`);
        }
        return acc;
      }, [])
      .join(",");
    return "/c/" + q;
  };

  return parseURL.origin + query() + parseURL.pathname;
};

export const ImageFlux: FC<Props> = (props) => {
  const [isError, setIsError] = useState(false);

  // Compute and set imageFlux URL from props.
  let src = getImageFluxUrl(props);
  if (isError) {
    // Or use default "no_image.png" on error.
    src = getImageFluxUrl({
      ...props,
      url: IFImg("/img/no_image@2x.png", true),
    });
  }

  return (
    <img
      className={cx(props.className)}
      src={src}
      alt={props.alt || ""}
      onError={() => setIsError(true)}
    />
  );
};
