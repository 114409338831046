import classNames from "classnames";
import type { TeamSelection } from "@components/SearchInput/_useTeamSelection";
import { IFImg } from "@libs/utils/IFImg";
import styles from "../../_SearchItemPc/index.module.css";
import type { SearchInput_TeamsQuery } from "../../__generated__/queries";
import type { FC } from "react";

export type Props = {
  selectedMasterTournamentId: number | undefined;
  teams: SearchInput_TeamsQuery["teamsUnbundled"];
  toggleAllCheckboxLabel: string;
  draftSelection: TeamSelection;
  requiresAltTeamIcons: boolean;
};

export const TeamList: FC<Props> = ({
  selectedMasterTournamentId,
  teams,
  toggleAllCheckboxLabel,
  draftSelection,
  requiresAltTeamIcons,
}) => {
  return (
    <>
      <div className={styles.filterTeamAll}>
        <label className={classNames(styles.check)}>
          <input
            type="checkbox"
            name="teamSub"
            checked={
              selectedMasterTournamentId
                ? draftSelection.isSelectedTournament(
                    selectedMasterTournamentId
                  )
                : false
            }
            onChange={() => {
              if (selectedMasterTournamentId) {
                draftSelection.toggleTournamentSelection(
                  selectedMasterTournamentId
                );
              }
            }}
          />
          <div className={styles.checkText}>
            <span>{toggleAllCheckboxLabel}</span>
          </div>
        </label>
      </div>
      <div className={classNames(styles.items, styles.teamItems)}>
        {teams.map((team) => (
          <div
            className={classNames(styles.item, styles["w-1/3"], styles.team)}
            key={team.id}
          >
            <label className={classNames(styles.check, styles.team)}>
              <input
                type="checkbox"
                name="teamMain"
                value={team.id}
                checked={draftSelection.isSelectedTeam(team.id)}
                onChange={() => draftSelection.toggleTeamSelection(team.id)}
              />
              <div className={classNames(styles.checkText, styles.teamDetail)}>
                <div className={styles.logo}>
                  <img
                    src={IFImg(
                      requiresAltTeamIcons ? team.altIconPath : team.iconPath
                    )}
                    width={36}
                    height={36}
                    alt=""
                  />
                </div>
                <p className={styles.name}>
                  <span>{team.name}</span>
                </p>
              </div>
            </label>
          </div>
        ))}
        {draftSelection.tba && (
          <div
            className={classNames(styles.item, styles["w-1/3"], styles.team)}
          >
            <label className={classNames(styles.check, styles.team)}>
              <input
                type="checkbox"
                name="teamMain"
                value={selectedMasterTournamentId}
                checked={draftSelection.tba.isSelected}
                onChange={draftSelection.tba.toggle}
              />
              <div className={classNames(styles.checkText, styles.teamDetail)}>
                <div className={styles.logo}>
                  <img
                    src={IFImg(draftSelection.activeTournament?.tbaImagePath)}
                    width={36}
                    height={36}
                    alt=""
                  />
                </div>
                <p className={styles.name}>
                  <span>未定</span>
                </p>
              </div>
            </label>
          </div>
        )}
      </div>
    </>
  );
};
