import { useCallback, useState } from "react";

// モーダルダイアログの表示を非同期関数の実行として扱うHooks
export const useModalDialog = () => {
  // 関数オブジェクトをStateにセットする際は関数で包んであげる必要がある
  // SEE: https://react.dev/reference/react/useState#im-trying-to-set-state-to-a-function-but-it-gets-called-instead

  const defaultHandler = () => {
    console.warn("useModalDialog: Invalid operation.");
  };
  const [isShown, setIsShown] = useState(false);
  const [continueHandler, setContinueHandler] = useState<() => void>(
    () => defaultHandler
  );
  const [abortHandler, setAbortHandler] = useState<() => void>(
    () => defaultHandler
  );

  const resetHandlers = () => {
    setContinueHandler(() => defaultHandler);
    setAbortHandler(() => defaultHandler);
  };

  const setHandlers = (resolve: (value: boolean) => void) => {
    setContinueHandler(() => () => resolve(true));
    setAbortHandler(() => () => resolve(false));
  };

  const show = useCallback(async () => {
    const contiunes = await new Promise<boolean>((resolve) => {
      setHandlers(resolve);
      setIsShown(true);
    });

    resetHandlers();
    setIsShown(false);

    return contiunes;
  }, [continueHandler, abortHandler]);

  return {
    isShown,
    continueHandler,
    abortHandler,
    show,
  };
};
