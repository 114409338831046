import { useRouter } from "next/router";
import { useEffect } from "react";
import Auth from "@hooks/useAuth";
import { paths } from "@libs/paths";
import type { FC, ReactNode } from "react";

const LoginRequiredPage: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const {
    isCurrentUserReady,
    isCurrentUserLoggedOut,
    isOnlyFirebaseUserRegistered,
  } = Auth.useContainer();

  useEffect(() => {
    if (isCurrentUserLoggedOut || isOnlyFirebaseUserRegistered) {
      router.replace(paths.login);
    }
  }, [router, isCurrentUserLoggedOut, isOnlyFirebaseUserRegistered]);

  if (
    !isCurrentUserReady ||
    isCurrentUserLoggedOut ||
    isOnlyFirebaseUserRegistered
  ) {
    return null;
  }

  return <>{children}</>;
};

export default LoginRequiredPage;
