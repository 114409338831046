const getRewrites = (env = {}) => {
  // 環境変数から直で読むと上手くいかないので `env` (config/xxx.yml) の値を受け取ったうえで利用する。
  // SEE: [[toC] 再再修正: SEOの404の扱いの改善(rewrite周り) by subuta · Pull Request #1337 · mixi-lx-sys/fansta-frontend](https://github.com/mixi-lx-sys/fansta-frontend/pull/1337)
  // デフォルトは "development" を利用する。
  const FANSTA_ENV_BUCKET_NAME =
    env.FANSTA_ENV_BUCKET_NAME || "fansta-development";

  const regexForGenreSlug = "(s-\\w+)";
  const regexForBaseYmd = "(d-\\d{6})";
  const regexForMatchId = "(m-[0-9]+)";

  // TODO: 結合エリア・単体エリアが存在しない県を含む全ての県のslugを正規表現取り込んだ事で、以下のデグレが発生しているので後日修正する。
  // SEE: [結合エリア・単体エリアが存在しない県の静的URLにアクセスした時に絞り込みが効いていない · Issue #926 · mixi-lx-sys/fansta-doc](https://github.com/mixi-lx-sys/fansta-doc/issues/926)
  const regexForPrefectureSlug =
    "(hokkaido|aomori|iwate|miyagi|akita|yamagata|fukushima|ibaraki|tochigi|gunma|saitama|chiba|tokyo|kanagawa|niigata|toyama|ishikawa|fukui|yamanashi|nagano|gifu|shizuoka|aichi|mie|shiga|kyoto|osaka|hyogo|nara|wakayama|tottori|shimane|okayama|hiroshima|yamaguchi|tokushima|kagawa|ehime|kochi|fukuoka|saga|nagasaki|kumamoto|oita|miyazaki|kagoshima|okinawa|others)";

  // 静的URLで対応すべき新しいトーナメントが追加される場合はここにも追加すること(SEE: PR #1975)
  const regexForTournamentSlug =
    "(j1|j2|j3|acl|baseball|asiancup|nadeshiko|uefa-champions-league|uefa-europa-league|u23-asiancup|laliga|uefa-euro|cupmatch|afc)";

  const rewritesForMatchesPage = [
    {
      source: `/matches/:baseYmdSlug${regexForBaseYmd}`,
      destination: "/matches",
    },
    {
      source: `/matches/:genreSlug${regexForGenreSlug}`,
      destination: "/matches",
    },
    {
      source: `/matches/:genreSlug${regexForGenreSlug}/:baseYmdSlug${regexForBaseYmd}`,
      destination: "/matches",
    },
    {
      source: `/matches/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}`,
      destination: "/matches",
    },
    {
      source: `/matches/:genreSlug${regexForGenreSlug}/:teamSlug`, // regexForTournamentSlugに当てはまらない場合はteamSlugとみなす
      destination: "/matches",
    },
    {
      source: `/matches/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:baseYmdSlug${regexForBaseYmd}`,
      destination: "/matches",
    },
    {
      source: `/matches/:genreSlug${regexForGenreSlug}/:teamSlug/:baseYmdSlug${regexForBaseYmd}`, // regexForTournamentSlugに当てはまらない場合はteamSlugとみなす
      destination: "/matches",
    },
    {
      source: `/matches/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:teamSlug`,
      destination: "/matches",
    },
    {
      source: `/matches/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:teamSlug/:baseYmdSlug${regexForBaseYmd}`,
      destination: "/matches",
    },
  ];

  const rewritesForShopsPage = [
    // BEGIN match id
    {
      source: `/shops/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:matchId${regexForMatchId}`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:matchId${regexForMatchId}`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:matchId${regexForMatchId}`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:areaSlug/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:matchId${regexForMatchId}`,
      destination: "/shops",
    },
    // END match id
    // BEGIN search team
    {
      source: `/shops/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:teamSlug`,
      destination: "/shops",
    },
    {
      source: `/shops/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}`,
      destination: "/shops",
    },
    {
      source: `/shops/:genreSlug${regexForGenreSlug}/:teamSlug`, // regexForTournamentSlugに当てはまらない場合はteamSlugとみなす
      destination: "/shops",
    },
    {
      source: `/shops/:genreSlug${regexForGenreSlug}`,
      destination: "/shops",
    },
    // END search team
    // BEGIN search area + search team
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:areaSlug/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:teamSlug`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:areaSlug/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:areaSlug/:genreSlug${regexForGenreSlug}/:teamSlug`, // regexForTournamentSlugに当てはまらない場合はteamSlugとみなす
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:areaSlug/:genreSlug${regexForGenreSlug}`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:teamSlug`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:genreSlug${regexForGenreSlug}/:teamSlug`, // regexForTournamentSlugに当てはまらない場合はteamSlugとみなす
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:genreSlug${regexForGenreSlug}`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}/:teamSlug`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:genreSlug${regexForGenreSlug}/:tournamentSlug${regexForTournamentSlug}`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:genreSlug${regexForGenreSlug}/:teamSlug`, // regexForTournamentSlugに当てはまらない場合はteamSlugとみなす
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:genreSlug${regexForGenreSlug}`,
      destination: "/shops",
    },
    // END search area + search team
    // BEGIN dynamic route
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:areaSlug/:uuid`,
      destination: "/shops/:uuid",
    },
    // END dynamic route
    // BEGIN search area
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug/:areaSlug`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}/:combinedAreaSlug`,
      destination: "/shops",
    },
    {
      source: `/shops/:prefectureSlug${regexForPrefectureSlug}`,
      destination: "/shops",
    },
    // END search area
  ];

  const rewritesForSitemap = [
    {
      // Will match `sitemap.xml` or `sitemap.xml.gz` file. (workaround for can't use capturing groups in "path-to-regexp")
      // SEE: [component/path-to-regexp: DEPRECATED use https://github.com/pillarjs/path-to-regexp](https://github.com/component/path-to-regexp)
      source: `/:sitemapFile(sitemap\\d?\\.xml|sitemap\\d?\\.xml\\.gz)`,
      destination: `https://storage.googleapis.com/${FANSTA_ENV_BUCKET_NAME}/sitemaps/:sitemapFile`,
    },
  ];

  return [
    ...rewritesForMatchesPage,
    ...rewritesForShopsPage,
    ...rewritesForSitemap,
  ];
};

module.exports = {
  getRewrites,
};
