import * as React from "react";
import UnreadNotificationCount from "@hooks/useUnreadNotificationCount";
import styles from "./index.module.css";

const NotificationBadge: React.FC = () => {
  const { unreadCount } = UnreadNotificationCount.useContainer();

  if (unreadCount <= 0) {
    return null;
  }
  return (
    <span className={styles.num}>
      {unreadCount < 100 ? unreadCount : "99+"}
    </span>
  );
};

export default NotificationBadge;
