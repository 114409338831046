import { useState } from "react";
import { createContainer } from "unstated-next";
import type React from "react";

type ContinuePathContextProps = {
  continuePath: string | null;
  setContinuePath: React.Dispatch<React.SetStateAction<string | null>>;
};

const useContinuePath = (): ContinuePathContextProps => {
  const [continuePath, setContinuePath] = useState<string | null>(null);

  return {
    continuePath,
    setContinuePath,
  };
};

const ContinuePath = createContainer(useContinuePath);

export default ContinuePath;
