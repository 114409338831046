import classNames from "classnames";
import type { TeamSelection } from "@components/SearchInput/_useTeamSelection";
import { IFImg } from "@libs/utils/IFImg";
import styles from "../../_SearchItem/index.module.css";
import type { SearchInput_TeamsQuery } from "../../__generated__/queries";
import type { FC, RefObject } from "react";

export type Props = {
  selectedMasterTournamentId: number | undefined;
  teams: SearchInput_TeamsQuery["teamsUnbundled"];
  hasTwoOrMoreTournamentTabs: boolean;
  isTeamScroll: boolean;
  teamItemsRef: RefObject<HTMLDivElement>;
  toggleAllCheckboxLabel: string;
  draftSelection: TeamSelection;
  requiresAltTeamIcons: boolean;
};

export const TeamList: FC<Props> = ({
  selectedMasterTournamentId,
  teams,
  hasTwoOrMoreTournamentTabs,
  isTeamScroll,
  teamItemsRef,
  toggleAllCheckboxLabel,
  draftSelection,
  requiresAltTeamIcons,
}) => {
  return (
    <div
      className={classNames(
        styles.items,
        styles.teamItems,
        hasTwoOrMoreTournamentTabs || styles.teamItemsNoTabs,
        !isTeamScroll && styles.overflowHidden
      )}
      ref={teamItemsRef}
    >
      <div className={styles.itemSub}>
        <label
          key={toggleAllCheckboxLabel}
          className={classNames(styles.teamCheck, styles.teamFilterCheck)}
        >
          <input
            type="checkbox"
            name="teamSub"
            checked={
              selectedMasterTournamentId
                ? draftSelection.isSelectedTournament(
                    selectedMasterTournamentId
                  )
                : false
            }
            onChange={() => {
              if (selectedMasterTournamentId) {
                draftSelection.toggleTournamentSelection(
                  selectedMasterTournamentId
                );
              }
            }}
          />
          <div
            className={classNames(styles.teamDetail, styles.teamFilterDetail)}
          >
            <p className={styles.name}>{toggleAllCheckboxLabel}</p>
          </div>
        </label>

        {teams.map((team) => (
          <label
            className={classNames(styles.teamCheck, styles.teamFilterCheck)}
            key={team.id}
          >
            <input
              type="checkbox"
              name="teamSub"
              value={team.id}
              checked={draftSelection.isSelectedTeam(team.id)}
              onChange={() => draftSelection.toggleTeamSelection(team.id)}
            />
            <div
              className={classNames(styles.teamDetail, styles.teamFilterDetail)}
            >
              <div className={styles.logo}>
                <img
                  src={IFImg(
                    requiresAltTeamIcons ? team.altIconPath : team.iconPath
                  )}
                  width={36}
                  height={36}
                  alt=""
                />
              </div>
              <p className={styles.name}>{team.name}</p>
            </div>
          </label>
        ))}

        {draftSelection.tba && (
          <label
            className={classNames(styles.teamCheck, styles.teamFilterCheck)}
            key={selectedMasterTournamentId}
          >
            <input
              type="checkbox"
              name="teamSub"
              value={selectedMasterTournamentId}
              checked={draftSelection.tba.isSelected}
              onChange={draftSelection.tba?.toggle}
            />
            <div
              className={classNames(styles.teamDetail, styles.teamFilterDetail)}
            >
              <div className={styles.logo}>
                <img
                  src={IFImg(draftSelection.activeTournament?.tbaImagePath)}
                  width={36}
                  height={36}
                  alt=""
                />
              </div>
              <p className={styles.name}>未定</p>
            </div>
          </label>
        )}
      </div>
    </div>
  );
};
