import events from "events";
import { ApolloProvider } from "@apollo/client";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import NProgress from "nprogress";
import * as React from "react";
import { useEffect, useRef } from "react";
import "../../public/css/style.css";
import "../../public/css/react-slick.css";
import "@styles/general.css";
import "nprogress/nprogress.css";
import Compose from "@components/Compose";
import PageAuthProviderSwitcher from "@components/PageAuthProviderSwitcher";
import PageRegister from "@components/PageRegister";
import Auth from "@hooks/useAuth";
import { CommonDataState } from "@hooks/useCommonData";
import ContinuePath from "@hooks/useContinuePath";
import useDisplaySmartBanner from "@hooks/useDisplaySmartBanner";
import EmailEdit from "@hooks/useEmailEdit";
import IsPc from "@hooks/useIsPc";
import isPcByUA from "@hooks/useIsPcByUA";
import Message from "@hooks/useMessage";
import RequestReservation from "@hooks/useRequestReservation";
import type { TypeSearchQueryParams } from "@hooks/useSearchQueryParams";
import SearchQueryParams from "@hooks/useSearchQueryParams";
import { TeamsByTournamentState } from "@hooks/useTeamsByTournament";
import ToggleScroll from "@hooks/useToggleScroll";
import UnreadNotificationCount from "@hooks/useUnreadNotificationCount";
import UserPolicyConfirmationModalsController from "@hooks/useUserPolicyConfirmationModalsController";
import type { PageProps } from "@libs/apolloClient";
import { APOLLO_STATE_PROP_NAME, useApollo } from "@libs/apolloClient";
import { GTMPageView } from "@libs/utils/gtm";
import type { AppProps } from "next/app";

// 全ページ共通のHeadタグはdocumentではなくAppに書く。
// SEE: https://github.com/vercel/next.js/issues/6919

NProgress.configure({ showSpinner: false });

// 問題ないのにでる警告を抑制するために設定値を変更している
// issue: https://github.com/mixi-lx-sys/fansta-doc/issues/2375
events.defaultMaxListeners = 30;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const App = ({
  Component,
  pageProps,
}: AppProps<
  PageProps<unknown> & { parameters: TypeSearchQueryParams | undefined }
>): React.ReactElement => {
  const router = useRouter();
  const previousPageUrlRef = useRef("");
  const apolloClient = useApollo(pageProps[APOLLO_STATE_PROP_NAME]);

  useDisplaySmartBanner();

  const getFullPath = (relativePath: string) =>
    process.env.APP_HOST + relativePath;

  useEffect(() => {
    const handleRouteChangeStart = (): void => {
      NProgress.start();
    };
    const handleRouteChangeError = (): void => {
      NProgress.done();
    };
    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeError", handleRouteChangeError);

    const handleRouteChangeComplete = (pathName: string): void => {
      const fullPath = getFullPath(pathName);
      GTMPageView(previousPageUrlRef.current);
      previousPageUrlRef.current = fullPath;
      NProgress.done();
    };

    // 最初のアクセスでpageviewイベントをpushする
    GTMPageView(previousPageUrlRef.current);
    const fullPath = getFullPath(router.asPath);
    previousPageUrlRef.current = fullPath;
    router.events.on("routeChangeComplete", handleRouteChangeComplete);
    return (): void => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeError", handleRouteChangeError);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, []);

  const parameters = pageProps.parameters || undefined;

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
          key="viewport"
        />
        <meta name="theme-color" content={"#000000"} />
      </Head>
      {/* Google Tag Manager */}
      <Script
        id="gtm"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${process.env.GTM_ID}');`,
        }}
      />
      {/* End Google Tag Manager */}
      <SearchQueryParams.Provider initialState={parameters}>
        <Compose
          components={[
            CommonDataState.Provider,
            TeamsByTournamentState.Provider,
            ToggleScroll.Provider,
            EmailEdit.Provider,
            Message.Provider,
            Auth.Provider,
            ContinuePath.Provider,
            RequestReservation.Provider,
            UnreadNotificationCount.Provider,
            IsPc.Provider,
            isPcByUA.Provider,
            UserPolicyConfirmationModalsController.Provider,
            PageRegister,
          ]}
        >
          <PageAuthProviderSwitcher>
            <Component {...pageProps} />
          </PageAuthProviderSwitcher>
        </Compose>
      </SearchQueryParams.Provider>
    </ApolloProvider>
  );
};

export default App;
