import { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "@components/Button";
import { TeamList } from "@components/SearchInput/SearchTeam/TeamList";
import { OPEN_STATUS, SearchItem } from "@components/SearchInput/_SearchItem";
import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import type { CategoryTab } from "@libs/utils/teams";
import { getCategorizedTabs } from "@libs/utils/teams";
import styles from "../_SearchItem/index.module.css";
import { useTeamSelection } from "../_useTeamSelection";
import { TournamentTabs } from "./TournamentTabs";
import type { SearchInput_TeamsQuery } from "../__generated__/queries";
import type { FC } from "react";

export type SearchTeamProps = {
  tournaments: UseCommonData_CommonDataQuery["tournaments"] | undefined;
  placeholder?: string;
  selectedMasterTournamentId: number | undefined;
  selectMasterTournament: (tournamentId: number | null) => void | undefined;
  teams: SearchInput_TeamsQuery["teamsUnbundled"];
  openTeam: boolean;
  setOpenTeam: (flag: boolean) => void;
  onSelectTeamAndTba: (
    teamIds: Array<number>,
    tournamentIds: Array<number>
  ) => void;
  selectedTournamentIdsTba: Array<number>;
  selectedTeamIds: Array<number>;
  submitText: string;
};

export const SearchTeam: FC<SearchTeamProps> = ({
  tournaments,
  selectedMasterTournamentId,
  selectMasterTournament,
  teams,
  openTeam,
  setOpenTeam,
  onSelectTeamAndTba,
  selectedTournamentIdsTba,
  selectedTeamIds,
  submitText,
}) => {
  // 検索メニューの開閉状態を管理
  const [openStatus, setOpenStatus] = useState<number>(OPEN_STATUS.CLOSED);
  const [doOnSelectTeamAndTba, setDoOnSelectTeamAndTba] = useState(false);
  const [isTeamScroll, setTeamScroll] = useState(true);
  const [enableSwipe, setEnableSwipe] = useState(true);
  const [categorizedTabs, setCategorizedTabs] = useState<CategoryTab[]>([]);
  const [selectedCategoryTab, selectCategoryTab] = useState<CategoryTab | null>(
    null
  );

  const draftSelection = useTeamSelection({
    tournaments: tournaments ?? [],
    activeTournamentId: selectedMasterTournamentId,
    teams: teams,
    initialSelectedTeamIds: selectedTeamIds,
    initialTournamentTbaSelected: selectedTournamentIdsTba,
  });

  // テキストボックスのクリアボタンが使用された場合は、選択状況を親に合わせる
  // モーダルを閉じてから次にモーダルを開くまでに選択状況が変更されていたら
  // テキストボックスのクリアボタンが使用されたものとみなす
  type LastSelectionType = { teams: Set<number>; tbas: Set<number> };
  const [lastSelectionOnClosed, setLastSelectionOnClosed] =
    useState<LastSelectionType>({
      teams: new Set(selectedTeamIds),
      tbas: new Set(selectedTournamentIdsTba),
    });
  const areEqualSets = (a: Set<number>, b: Set<number>) =>
    a.size === b.size && [...a].every((i) => b.has(i));
  const isSelectionChangedWithoutModal = () => {
    const isTeamsChanegd = !areEqualSets(
      new Set(selectedTeamIds),
      lastSelectionOnClosed.teams
    );
    const isTbasChanged = !areEqualSets(
      new Set(selectedTournamentIdsTba),
      lastSelectionOnClosed.tbas
    );
    return isTeamsChanegd || isTbasChanged;
  };
  const [lastModalStatus, setLastModalStatus] = useState(openStatus);
  useEffect(() => {
    const willOpen =
      lastModalStatus === OPEN_STATUS.CLOSED &&
      openStatus === OPEN_STATUS.CHANGING;
    if (willOpen) {
      if (isSelectionChangedWithoutModal()) {
        draftSelection.setSelectedTeamIds(new Set(selectedTeamIds));
        draftSelection.setTournamentsTbaSelected(
          new Set(selectedTournamentIdsTba)
        );
      }
    }

    const isClosed =
      lastModalStatus === OPEN_STATUS.CHANGING &&
      openStatus === OPEN_STATUS.CLOSED;
    if (isClosed) {
      setLastSelectionOnClosed({
        teams: new Set(selectedTeamIds),
        tbas: new Set(selectedTournamentIdsTba),
      });
    }

    setLastModalStatus(openStatus);
  }, [openStatus]);

  const teamItemsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // create data for tabs based on 'tournaments' prop
    if (tournaments?.length) {
      setCategorizedTabs(getCategorizedTabs(tournaments));
    }
  }, [tournaments]);

  const toggleAllCheckboxLabel = useMemo(
    () =>
      draftSelection.activeTournament
        ? draftSelection.activeTournament.allTeamsLabel
        : "",
    [draftSelection.activeTournament]
  );

  const hasTwoOrMoreTournamentTabs = !!(
    selectedCategoryTab?.tournamentTabs &&
    selectedCategoryTab?.tournamentTabs.length > 1
  );

  const requiresAltTeamIcons = useMemo(() => {
    if (tournaments === undefined) {
      return false;
    }

    if (selectedMasterTournamentId === undefined) {
      return false;
    }

    return (
      tournaments.find((t) => t.id === selectedMasterTournamentId)
        ?.requiresAltTeamIcons ?? false
    );
  }, [tournaments, selectedMasterTournamentId]);

  if (!openTeam && openStatus === OPEN_STATUS.CLOSED) {
    return null;
  }

  return (
    <SearchItem
      openContent={openTeam}
      openStatus={openStatus}
      setOpenStatus={setOpenStatus}
      enableSwipe={enableSwipe}
      setEnableSwipe={setEnableSwipe}
      setScroll={setTeamScroll}
      tabs={
        <TournamentTabs
          onSelectCategoryTab={selectCategoryTab}
          onSelectTournament={selectMasterTournament}
          categorizedTabs={categorizedTabs}
          selectedTournamentId={selectedMasterTournamentId}
          hideTournamentTabs={!hasTwoOrMoreTournamentTabs}
        />
      }
      contentRef={teamItemsRef}
      onAnimationEnd={() =>
        // 選択状態を確定する。
        onSelectTeamAndTba(
          [...draftSelection.selectedTeamIds],
          [...draftSelection.tournamentsTbaSelected]
        )
      }
      onClose={(): void => {
        // `この条件で絞り込む` からの操作以外の場合は "onAnimationEnd" をskipするための処理
        setDoOnSelectTeamAndTba(false);

        // 閉じる
        setOpenTeam(false);
      }}
      doSearch={doOnSelectTeamAndTba}
    >
      <TeamList
        selectedMasterTournamentId={selectedMasterTournamentId}
        teams={teams}
        hasTwoOrMoreTournamentTabs={hasTwoOrMoreTournamentTabs}
        isTeamScroll={isTeamScroll}
        teamItemsRef={teamItemsRef}
        toggleAllCheckboxLabel={toggleAllCheckboxLabel}
        draftSelection={draftSelection}
        requiresAltTeamIcons={requiresAltTeamIcons}
      />
      <div className={styles.teamFilterButtons}>
        <Button
          className={styles.reset}
          styleType="filled"
          colorType="secondary"
          onClick={draftSelection.clearAll}
          disabled={!draftSelection.isClearable}
        >
          クリア
        </Button>
        <Button
          className={styles.submit}
          styleType="filled"
          colorType="primary"
          onClick={(): void => {
            setOpenTeam(false);
            setDoOnSelectTeamAndTba(true);
          }}
        >
          {submitText}
        </Button>
      </div>
    </SearchItem>
  );
};
