import { AnimatePresence, motion } from "framer-motion";
import {
  InformationPanel,
  InformationPanelItem,
} from "@components/InformationPanel";
import type { PredefinedMessage } from "@hooks/useMessage";
import Message from "@hooks/useMessage";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  messageFilter?: PredefinedMessage[];
};

const Toaster: FC<Props> = ({ messageFilter }) => {
  const { messages, removeMessage } = Message.useContainer();

  const filteredMessages = messageFilter
    ? messages.filter((m) => messageFilter.find((f) => f.id === m.id))
    : messages;

  return (
    <InformationPanel isFloating={true}>
      <AnimatePresence>
        {filteredMessages.map((m) => (
          <motion.div
            key={m.id}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ ease: "easeOut", duration: 0.5 }}
          >
            <InformationPanelItem
              type={m.messageType}
              closeButton={m.canDelete}
              children={m.value}
              className={styles.container}
              onClose={() => {
                removeMessage(m);
              }}
              isAutoClose={m.isAutoClose}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </InformationPanel>
  );
};

export default Toaster;
