import { map, assign, clone, some } from "lodash";
import { match } from "path-to-regexp";
import { getRewrites } from "./routes";
import type { MatchFunction, MatchResult } from "path-to-regexp";
import type { ParsedUrlQuery } from "querystring";

// prefixes are requested for seo but are not useful so we remove them
export const removeUnhelpfulPrefixes = (
  rewriteParams: RewriteParams
): RewriteParams => {
  // return early if no work to be done
  if (
    !rewriteParams.genreSlug &&
    !rewriteParams.baseYmdSlug &&
    !rewriteParams.matchId
  ) {
    return rewriteParams;
  }

  const withoutPrefixes = clone(rewriteParams);

  if (withoutPrefixes.genreSlug) {
    withoutPrefixes.genreSlug = withoutPrefixes.genreSlug.split(/s-/)[1];
  }
  if (withoutPrefixes.baseYmdSlug) {
    withoutPrefixes.baseYmdSlug = withoutPrefixes.baseYmdSlug.split(/d-/)[1];
  }

  if (withoutPrefixes.matchId) {
    withoutPrefixes.matchId = withoutPrefixes.matchId.split(/m-/)[1];
  }

  return withoutPrefixes;
};

export interface Rewrite {
  source: string;
  destination: string;
}

type MatchesPageRewriteParams = {
  baseYmdSlug?: string;
};

type ShopsPageRewriteParams = {
  prefectureSlug?: string;
  combinedAreaSlug?: string;
  areaSlug?: string;
  matchId?: string;
};

export type RewriteParams = {
  // useful on both shops and matches page
  genreSlug?: string;
  tournamentSlug?: string;
  teamSlug?: string;
} & MatchesPageRewriteParams &
  ShopsPageRewriteParams;

type Route = {
  match: MatchFunction;
} & Rewrite;

export type TerminatingParams = {
  team: string | undefined;
  area: string | undefined;
  hasBaseYmd: boolean;
};

export const getTerminatingStaticURLParameters = (
  query: ParsedUrlQuery
): TerminatingParams => {
  let area;
  let team;
  let hasBaseYmd = false;

  if (query.baseYmdSlug) {
    hasBaseYmd = true;
  }

  if (query.areaSlug) {
    area = "area";
  } else if (query.combinedAreaSlug) {
    area = "combinedArea";
  } else if (query.prefectureSlug) {
    area = "prefecture";
  }

  if (query.matchId) {
    team = "matchId";
  } else if (query.teamSlug) {
    team = "team";
  } else if (query.tournamentSlug) {
    team = "tournament";
  } else if (query.genreSlug) {
    team = "genre";
  }

  return {
    team,
    area,
    hasBaseYmd,
  };
};

export type RewriteMatchResult =
  | undefined
  | {
      route: undefined | Route;
      params: undefined | MatchResult<RewriteParams>["params"];
      terminatingParams?: TerminatingParams;
    };

let router: Router | null = null;

class Router {
  routes: Route[];

  constructor(routes: Rewrite[]) {
    this.routes = this.prepareRoutes(routes);
  }

  prepareRoutes(routes: Rewrite[]): Route[] {
    return map(routes, (route: Rewrite) => {
      return assign(clone(route), {
        // SEE: https://github.com/pillarjs/path-to-regexp
        match: match(route.source, { decode: decodeURIComponent }),
      });
    });
  }

  // transforming paths into parameters
  // e.g. /tokyo -> { foo: 'tokyo' }
  match(pathname: Rewrite["source"]): RewriteMatchResult {
    let route: undefined | Route, params: undefined | MatchResult["params"];

    // Trim query params if exists.
    pathname = pathname.split("?")[0] || "";

    const matched = some(this.routes, (_route) => {
      const matched = _route.match(pathname);
      if (!matched) {
        return;
      }
      route = _route;
      params = removeUnhelpfulPrefixes(matched.params);
      return true;
    });

    if (!matched) {
      return;
    }

    const terminatingParams = getTerminatingStaticURLParameters(
      params as ParsedUrlQuery
    );

    return {
      params,
      route,
      terminatingParams,
    };
  }

  parseParams(pathname: string, params: RewriteParams): RewriteMatchResult {
    // Ignore query params.
    pathname = pathname.split("?")[0];

    const terminatingParams = getTerminatingStaticURLParameters(
      params as ParsedUrlQuery
    );

    return {
      params: removeUnhelpfulPrefixes(params),
      route: {
        source: "",
        destination: pathname,
        // SEE: https://github.com/pillarjs/path-to-regexp
        match: match(pathname, { decode: decodeURIComponent }),
      },
      terminatingParams,
    };
  }
}

// change to this export format to capture typing
export const getRouter = () => {
  if (!router) {
    router = new Router(getRewrites());
  }
  return router;
};
