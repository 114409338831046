import * as url from "url";
import _ from "lodash";
import type { ParsedUrlQueryInput } from "querystring";

export type ShopsQuery = {
  prefectureId?: number;
  combinedAreaIds?: Array<number>;
  areaIds?: Array<number>;
  teamIds?: Array<number>;
  matchId?: number;
  tournamentIdsTba?: Array<number>;
};

export type MatchesQuery = {
  tournamentIdTba?: number;
  teamIds?: Array<number>;
};

type StaticShopsParams = {
  prefectureSlug?: string;
  combinedAreaSlug?: string;
  areaSlug?: string;
  genreSlug?: string;
  tournamentSlug?: string;
  teamSlug?: string;
  matchSlug?: string;
};

type StaticMatchesParams = {
  genreSlug?: string;
  tournamentSlug?: string;
  teamSlug?: string;
  dateSlug?: string;
};

export type RequestReservationQueryParams = {
  date?: string;
  matchId?: number;
};

export const pickTruthy = (
  object: Record<string, unknown> | null | undefined
): _.Dictionary<unknown> => {
  return _.pickBy(object, (value) => {
    if (_.isArray(value)) {
      return !_.isEmpty(value);
    }
    return _.identity(value);
  });
};

// pipeでつながれた値をparseする。
export const decodeArrayParams = (str: string): string[] => _.split(str, ",");

// Next.jsの標準の方法だとURLが長くなるので、カンマでつなぐ。
export const encodeArrayParams = (array: Array<unknown>): string =>
  array.join(",");

export type SearchConditionParam =
  | "combinedAreas"
  | "areas"
  | "teams"
  | "match"
  | "prefecture"
  | "tba";

export const getSearchConditionParams = (
  params:
    | {
        combinedAreas?: Array<number>;
        teams?: Array<number>;
        prefecture?: number;
        match?: number;
        areas?: Array<number>;
        tba?: Array<number>;
      }
    | undefined
): Record<SearchConditionParam, unknown> => {
  const nextParams = pickTruthy(_.assign({}, params)) as Record<
    SearchConditionParam,
    unknown
  >;

  _.each(nextParams, (value, key) => {
    // Array向けのキーのいずれかだった場合
    if (
      key === "areas" ||
      key === "combinedAreas" ||
      key === "teams" ||
      key === "tba"
    ) {
      nextParams[key] = encodeArrayParams(value as Array<unknown>);
    }
  });

  return nextParams;
};

const appendDeepLinkValue = (url: string, deepLinkValue: string) => {
  const urlObject = new URL(url);
  urlObject.searchParams.append("deep_link_value", deepLinkValue);
  return urlObject.toString();
};

export const paths = {
  home: "/",
  shops: (shopsQuery: ShopsQuery): string => {
    return url
      .format({
        pathname: "/shops",
        // _.pickBy({...}, _.identity) で、値がtruthyなクエリだけ残している
        query: getSearchConditionParams({
          // パラメータ名は以下のルールとする。意味を残しつつなるだけ短くしたいので `xxxId` の `Id` は省く
          // 単数形 = ID(number)。
          // 複数形 = IDの配列(Array<number>)。
          prefecture: shopsQuery.prefectureId,
          combinedAreas: shopsQuery.combinedAreaIds?.map((id) =>
            _.toNumber(id)
          ),
          areas: shopsQuery.areaIds?.map((id) => _.toNumber(id)),
          teams: shopsQuery.teamIds,
          match: shopsQuery.matchId,
          tba: shopsQuery.tournamentIdsTba,
        }) as ParsedUrlQueryInput,
      })
      .toString();
  },
  // アプリのWebViewでは静的URLに対応していないので使用箇所に注意すること
  staticShops: ({
    prefectureSlug,
    combinedAreaSlug,
    areaSlug,
    genreSlug,
    tournamentSlug,
    teamSlug,
    matchSlug,
  }: StaticShopsParams): string => {
    return (
      "/shops/" +
      [
        prefectureSlug,
        combinedAreaSlug,
        areaSlug,
        genreSlug,
        tournamentSlug,
        teamSlug,
        matchSlug,
      ]
        .filter((s) => !!s)
        .join("/")
    );
  },
  about: "/about",
  aboutShop: "/about/shop",
  login: "/login",
  loginAuth: "/login/auth",

  matches: (matchesQuery: MatchesQuery): string => {
    return url
      .format({
        pathname: "/matches",
        query: getSearchConditionParams({
          teams: matchesQuery.teamIds,
          // Mark "未定" of tournament as selected also.
          tba: _.compact([matchesQuery.tournamentIdTba]),
        }) as ParsedUrlQueryInput,
      })
      .toString();
  },
  // アプリのWebViewでは静的URLに対応していないので使用箇所に注意すること
  staticMatches: ({
    genreSlug,
    tournamentSlug,
    teamSlug,
    dateSlug,
  }: StaticMatchesParams): string => {
    return (
      "/matches/" +
      [genreSlug, tournamentSlug, teamSlug, dateSlug]
        .filter((s) => !!s)
        .join("/")
    );
  },

  campaign: "/campaign",
  campaignFcTokyo202110: "/campaign/fctokyo_202110",
  campaignFcTokyo202203: "/campaign/fctokyo-202203",
  campaignFmarinos202203: "/campaign/fmarinos-202203",
  campaignCerezo202203: "/campaign/cerezo-202203",
  campaignFrontale202203: "/campaign/frontale-202203",
  campaignReds202203: "/campaign/reds-202203",
  campaignGrampus202209: "/campaign/grampus-202209",
  campaignDaznMemberBenefits202204: "/campaign/dazn-member-benefits-202204",
  campaignDaznMemberBenefits202208: "/campaign/dazn-member-benefits-202208",
  campaignFanstaAppRelease202206:
    "https://support.fansta.jp/hc/ja/articles/7362852017945",
  campaignFanstaAppRelease20220629:
    "https://support.fansta.jp/hc/ja/articles/7768315112217",
  sportsLiveViewing: "/sports-liveviewing",
  dazn: "/dazn",
  mypage: "/mypage",
  mypageTel: "/mypage/tel",
  mypageTelEdit: "/mypage/tel/edit",
  mypageEmail: "/mypage/email",
  mypageEmailEdit: "/mypage/email/edit",
  mypageEmailComplete: "/mypage/email/complete",
  mypageProfile: "/mypage/profile",
  mypageProfileEdit: "/mypage/profile/edit",
  mypageReservations: "/mypage/reservations",
  mypageReservationDetail: (uuid: string): string =>
    `/mypage/reservations/${uuid}`,
  mypageFavoriteShops: "/mypage/favorite_shops",
  mypageCoupons: "/mypage/coupons",
  mypagePoints: "/mypage/points",
  mypagePointsGiftDetail: (id: number) => `/mypage/points/gifts/${id}`,
  mypagePointsHistories: "/mypage/points/histories",
  mypagePointsExchanges: "/mypage/points/exchanges",
  mypageNotificationSettings: "/mypage/notification_settings",
  mypagePaymentCards: "/mypage/payment_cards",
  confirmEmail: "/confirm_email",
  recoverEmail: "/recover_email",
  notificationsList: "/notifications",
  notFound: "/404",
  internalServerError: "/500",
  shopDetail: (uuid: string): string => `/shops/${uuid}`,
  shopPhoto: (uuid: string, anchor?: string): string =>
    `/shops/${uuid}/photos` + (anchor ? `#${anchor}` : ""),
  shopMatches: (uuid: string): string => `/shops/${uuid}/matches`,
  shopRequestReservation: (
    uuid: string,
    query: RequestReservationQueryParams = {}
  ): string => {
    const url = new URL(
      `/shops/${uuid}/request_reservation`,
      process.env.APP_HOST
    );
    query.date && url.searchParams.append("date", query.date);
    query.matchId && url.searchParams.append("match", query.matchId.toString());
    return url.pathname + url.search;
  },
  shopRequestReservationConfirm: (uuid: string): string =>
    `/shops/${uuid}/request_reservation/confirm`,
  shopRequestReservationPaymentMethods: (uuid: string): string =>
    `/shops/${uuid}/request_reservation/payment_methods`,
  shopRequestReservationComplete: (uuid: string): string =>
    `/shops/${uuid}/request_reservation/complete`,
  signup: "/signup",
  signupAuth: "/signup/auth",
  signupBasicInfo: "/signup/basic_info",
  notifications: "https://support.fansta.jp/hc/ja/sections/4407069598233",
  inquiries: "https://support.fansta.jp/hc/ja/sections/360008733314",
  privacyPolicy: "https://mixi.co.jp/privacy/#handling",
  privacyPolicyForApp: "/privacy",
  terms: "/terms",
  reservationTerms: "/reservation_terms",
  pointTerms: "/point_terms",
  verifyEmailDone: "verify_email_done",
  company: "https://mixi.co.jp/",
  aboutCovidInformation:
    "https://support.fansta.jp/hc/ja/articles/1500004318461-Fansta%E3%81%AE%E6%96%B0%E5%9E%8B%E3%82%B3%E3%83%AD%E3%83%8A%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%81%B8%E3%81%AE%E5%AF%BE%E5%BF%9C%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6",
  contact:
    "https://support.fansta.jp/hc/ja/requests/new?ticket_form_id=1500000261241",
  supportEditTel: "https://support.fansta.jp/hc/ja/articles/4406431190553",
  supportEmailNotSent: "https://support.fansta.jp/hc/ja/articles/4405689880089",
  supportEmailNotRegistered:
    "https://support.fansta.jp/hc/ja/articles/4405681932569",
  supportBasicInfoPurpose:
    "https://support.fansta.jp/hc/ja/articles/4405681957273",
  supportRequestReservation:
    "https://support.fansta.jp/hc/ja/articles/4417874185369",
  appStore: "https://apps.apple.com/jp/app/id1608266270",
  googlePlay: "https://play.google.com/store/apps/details?id=jp.fansta.app",
  appOneLinkCommon: "https://fansta.onelink.me/ZFgu/gfdjdvfr",
  appOneLinkForCoupons: "https://fansta.onelink.me/ZFgu/f6wvv84b",
  appOneLinkForSportsLiveViewing: "https://fansta.onelink.me/ZFgu/pzt7j5yo",
  appOneLinkForShop: (deepLinkValue: string) =>
    appendDeepLinkValue(
      process.env.DEPLOY_ENV === "production"
        ? "https://fansta.onelink.me/ZFgu/k9zmfx73"
        : "https://development-fansta.onelink.me/3X0f/3h13t8tp",
      deepLinkValue
    ),
  appOneLinkForRequestReservation: (deepLinkValue: string) =>
    appendDeepLinkValue(
      process.env.DEPLOY_ENV === "production"
        ? "https://fansta.onelink.me/ZFgu/fycx1h8s"
        : "https://development-fansta.onelink.me/3X0f/uw5kdjpc",
      deepLinkValue
    ),
  appOneLinkForRequestReservationComplete:
    "https://fansta.onelink.me/ZFgu/d79skhiz",
  twitter: "https://twitter.com/fansta_jp",
  instagram: "https://www.instagram.com/fansta_jp",
  teams: "/teams",
  team: (sportGenreSlug: string, teamSlug: string): string =>
    `/teams/s-${sportGenreSlug}/${teamSlug}`,
  teamNewsArticles: (sportGenreSlug: string, teamSlug: string): string =>
    `/teams/s-${sportGenreSlug}/${teamSlug}/news`,
  newsArticles: "/news",
  newsArticle: (id: string): string => `/news/${id}`,
  aboutPoints: "https://support.fansta.jp/hc/ja/articles/23987980583833",
  lotteryCampaigns: "/lottery_campaigns",
  commercialNotice: "/commercial_notice",
};

export const imagePaths = {
  defaultOgp: "/img/ogp.png",
  asiancupOgp: "/img/ogp_asiancup.png",
};
