import Head from "next/head";
import Link from "next/link";
import { useEffect } from "react";
import LandingPagesHeader from "@components/Layout/LandingPagesHeader";
import { OptInModal } from "@components/OptInModal";
import { PointTermsModal } from "@components/PointTermsModal";
import { PrivacyPolicyConfirmationModal } from "@components/PrivacyPolicyConfirmationModal";
import useAccessFromApp from "@hooks/useAccessFromApp";
import UserPolicyConfirmationModalsController from "@hooks/useUserPolicyConfirmationModalsController";
import { paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import CommonHeader from "./CommonHeader";
import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

type Props = {
  noHeaderButton?: boolean;
  hasLandingPagesHeader?: boolean;
  hasFooter?: boolean;
  footerPC?: boolean;
  headerFixed?: boolean;
  hasFixedButtonSpace?: boolean;
  noModal?: boolean;
  hasHeaderPoints?: boolean;
  children: ReactNode;
};

const CommonLayout: FC<Props> = ({
  noHeaderButton = false,
  hasLandingPagesHeader = false,
  hasFooter = false,
  footerPC = false,
  headerFixed = false,
  hasFixedButtonSpace = false,
  noModal = false,
  hasHeaderPoints = false,
  children,
}) => {
  const isFanstaMobileApp = useAccessFromApp();
  const userPolicyConfirmationModals =
    UserPolicyConfirmationModalsController.useContainer();

  useEffect(() => {
    userPolicyConfirmationModals.setIsActive(!noModal);
  }, [noModal]);

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="apple-touch-icon"
          href="/apple-touch-icon.png"
          sizes="180x180"
        />
      </Head>
      <div>
        {!isFanstaMobileApp && !hasLandingPagesHeader && (
          <CommonHeader
            noHeaderButton={noHeaderButton}
            headerFixed={headerFixed}
            hasHeaderPoints={hasHeaderPoints}
          />
        )}

        {hasLandingPagesHeader && <LandingPagesHeader />}

        <main>{children}</main>

        {!isFanstaMobileApp && hasFooter && (
          <footer
            className={`${styles.footer} ${footerPC ? "none md:block" : ""} ${
              hasFixedButtonSpace ? styles.fixdedButtonSpace : ""
            }`}
          >
            <div className={styles.footerInner}>
              <div className={styles.footerTop}>
                <div className={styles.footerLogo}>
                  <Link href={paths.home}>
                    <img
                      src={IFImg("/img/logo_fansta_gray.svg")}
                      alt="Fansta"
                    />
                  </Link>
                  <div className={styles.footerText}>Powered by DAZN</div>
                </div>
                <ul className={styles.footerSns}>
                  <li>
                    <Link href={paths.twitter} target="_blank">
                      <img
                        src={IFImg("/img/icon_x.svg")}
                        width={24}
                        height={24}
                        alt="X"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link href={paths.instagram} target="_blank">
                      <img
                        src={IFImg("/img/icon_instagram.svg")}
                        width={24}
                        height={24}
                        alt="Instagram"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
              <ul className={styles.footerList}>
                <li className={styles.footerItem}>
                  <Link href={paths.about} className={styles.footerLink}>
                    Fanstaとは
                  </Link>
                </li>
                <li className={styles.footerItem}>
                  <Link
                    href={paths.sportsLiveViewing}
                    className={styles.footerLink}
                  >
                    スポーツライブビューイングの楽しみ方
                  </Link>
                </li>
                <li className={styles.footerItem}>
                  <a
                    href={paths.notifications}
                    target="_blank"
                    className={styles.footerLink}
                  >
                    運営からのお知らせ
                  </a>
                </li>
                <li className={styles.footerItem}>
                  <a
                    href={paths.inquiries}
                    target="_blank"
                    className={styles.footerLink}
                  >
                    ヘルプ・お問い合わせ
                  </a>
                </li>
                <li className={styles.footerItem}>
                  <Link href={paths.terms} className={styles.footerLink}>
                    利用規約
                  </Link>
                </li>
                <li className={styles.footerItem}>
                  <Link
                    href={paths.reservationTerms}
                    className={styles.footerLink}
                  >
                    予約サービス利用規約
                  </Link>
                </li>
                <li className={styles.footerItem}>
                  <Link href={paths.pointTerms} className={styles.footerLink}>
                    ポイント利用規約
                  </Link>
                </li>
                <li className={styles.footerItem}>
                  <Link
                    href={paths.privacyPolicy}
                    className={styles.footerLink}
                    target="_blank"
                  >
                    プライバシーポリシー
                  </Link>
                </li>
                <li className={styles.footerItem}>
                  <Link
                    href={paths.company}
                    className={styles.footerLink}
                    target="_blank"
                  >
                    運営会社
                  </Link>
                </li>
              </ul>
              <p className={styles.footerCopy}>&copy; Fansta</p>
            </div>
          </footer>
        )}
        {!noModal && <OptInModal />}
        <PrivacyPolicyConfirmationModal
          onConfirm={userPolicyConfirmationModals.onPrivacyPolicyConfirm}
          isOpen={userPolicyConfirmationModals.isPrivacyPolicyShown}
        />
        <PointTermsModal
          isOpen={userPolicyConfirmationModals.isPointTermsShown}
          onConfirm={userPolicyConfirmationModals.onPointTermsConfirm}
        />
      </div>
    </>
  );
};

export default CommonLayout;
