import classNames from "classnames";
import _ from "lodash";
import { forwardRef } from "react";
import styles from "./index.module.css";
import type { ComponentProps } from "react";

interface CustomProps {
  styleType?: "filled" | "outlined";
  colorType?: "primary" | "secondary" | "tertiary" | "delete";
}

// 独自のプロパティ + aタグ, buttonタグのpropsを許容する。
type ButtonProps = CustomProps & ComponentProps<"a"> & ComponentProps<"button">;

export const Button = forwardRef<
  HTMLAnchorElement & HTMLButtonElement,
  ButtonProps
>((props, ref) => {
  const {
    className = "",
    onClick = _.noop,
    children,
    styleType,
    colorType,
    href,
    ...rest
  } = props;

  // リンクが指定されてたらaタグ。それ以外はbuttonタグとする。
  const Component = href ? "a" : "button";

  return (
    <Component
      onClick={onClick}
      className={classNames(className, styles.btn, {
        [styles.primary]: colorType === "primary",
        [styles.secondary]: colorType === "secondary",
        [styles.tertiary]: colorType === "tertiary",
        [styles.delete]: colorType === "delete",
        [styles.filled]: styleType === "filled",
        [styles.outlined]: styleType === "outlined",
      })}
      ref={ref}
      href={href}
      {...rest}
    >
      {children}
    </Component>
  );
});
