import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { useIsRouterReady } from "@hooks/useIsRouterReady";
import { decodeArrayParams, getSearchConditionParams } from "@libs/paths";
import type { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring";

// BEGIN
// type safety when assigning properties to type TypeSearchQueryParams
const KEYS_WITH_NUMBER_VALUES = ["match", "prefecture"] as const;
type NumberParams = Partial<
  Record<(typeof KEYS_WITH_NUMBER_VALUES)[number], number>
>;

const KEYS_WITH_STRING_VALUES = ["baseYmd"] as const;
type StringParams = Partial<
  Record<(typeof KEYS_WITH_STRING_VALUES)[number], string>
>;

const KEYS_WITH_NUMBER_ARRAY_VALUES = [
  "areas",
  "combinedAreas",
  "teams",
  "tba",
] as const;
type AarrayParams = Partial<
  Record<(typeof KEYS_WITH_NUMBER_ARRAY_VALUES)[number], number[]>
>;

export type TypeSearchQueryParams = NumberParams & StringParams & AarrayParams;

// user defined type guards
const isKeyWithNumberValue = (
  str: string
): str is (typeof KEYS_WITH_NUMBER_VALUES)[number] =>
  (KEYS_WITH_NUMBER_VALUES as readonly string[]).includes(str);

export const isKeyWithStringValue = (
  str: string
): str is (typeof KEYS_WITH_STRING_VALUES)[number] =>
  (KEYS_WITH_STRING_VALUES as readonly string[]).includes(str);

const isKeyWithNumberArrayValue = (
  str: string
): str is (typeof KEYS_WITH_NUMBER_ARRAY_VALUES)[number] =>
  (KEYS_WITH_NUMBER_ARRAY_VALUES as readonly string[]).includes(str);

const isNumber = (value: unknown): value is number => typeof value === "number";

const isString = (value: unknown): value is string => typeof value === "string";

const isNumbers = (value: unknown): value is number[] => {
  return Array.isArray(value) && value.every((v) => typeof v === "number");
};

const assignPropertyWithTypeSafety = (
  object: TypeSearchQueryParams,
  key: keyof TypeSearchQueryParams,
  value: TypeSearchQueryParams[keyof TypeSearchQueryParams]
) => {
  if (isKeyWithNumberValue(key)) {
    if (isNumber(value)) {
      object[key] = value;
    }
  } else if (isKeyWithStringValue(key)) {
    if (isString(value)) {
      object[key] = value;
    }
  } else if (isKeyWithNumberArrayValue(key)) {
    if (isNumbers(value)) {
      object[key] = value;
    } else if (isNumber(value)) {
      object[key] = [value];
    }
  }
};
// END

export type SearchQueryState = {
  params: TypeSearchQueryParams;
  rawParams: TypeSearchQueryParams;
  syncHistory: (nextParams: TypeSearchQueryParams) => Promise<boolean>;
  syncHistoryBeforePopState: (nextParams: ParsedUrlQuery) => void;
  isReady: boolean;
};

const keys: Array<keyof TypeSearchQueryParams> = [
  "combinedAreas",
  "areas",
  "teams",
  "match",
  "prefecture",
  "tba",
  "baseYmd",
];

export const getParamValue = (
  query: ParsedUrlQuery,
  key: keyof TypeSearchQueryParams
): string | number | number[] | undefined => {
  const queryValue = query[key];
  let paramValue;

  // Array向けのキーのいずれかだった場合
  if (isKeyWithNumberArrayValue(key)) {
    if (isString(queryValue)) {
      paramValue = _.compact(_.map(decodeArrayParams(queryValue), _.toNumber));
    } else {
      paramValue = [];
    }
  } else if (isKeyWithStringValue(key)) {
    if (isString(queryValue)) {
      paramValue = queryValue;
    } else {
      paramValue = "";
    }
  } else if (isKeyWithNumberValue(key)) {
    paramValue = _.toNumber(queryValue || 0);
  }

  return paramValue;
};

const getParams = (query: ParsedUrlQuery): TypeSearchQueryParams => {
  return _.transform(
    keys,
    (acc, key) => {
      const value = getParamValue(query, key);

      assignPropertyWithTypeSafety(acc, key, value);

      return acc;
    },
    {} as TypeSearchQueryParams
  );
};

function useSearchQueryParams(
  ssrParams: TypeSearchQueryParams | undefined
): SearchQueryState {
  const [lastParams, setLastParams] = useState({});
  const [isReady, setIsReady] = useState(false);
  const router = useRouter();

  const isRouterReady = useIsRouterReady();
  const { query, pathname, push } = router;

  useEffect(() => {
    if (isRouterReady) {
      const nextParams = {
        ...getSearchConditionParams(getParams(query)),
        ...getSearchConditionParams(ssrParams),
      };
      setLastParams(nextParams);
      if (!isReady) {
        setIsReady(true);
      }
    }
  }, [isRouterReady, ssrParams]);

  useEffect(() => {
    // Toggle isReady for trigger dependent UI to forceUpdate.
    const onStart = () => {
      setIsReady(false);
    };
    const onComplete = () => {
      requestAnimationFrame(() => setIsReady(true));
    };
    router.events.on("routeChangeStart", onStart);
    router.events.on("routeChangeComplete", onComplete);
    return () => {
      router.events.off("routeChangeStart", onStart);
      router.events.off("routeChangeComplete", onComplete);
    };
  }, [router]);

  // SEE: [Routing: Shallow Routing | Next.js](https://nextjs.org/docs/routing/shallow-routing)
  // area/teamパラメータの変更をクエリ文字列に反映する。
  const syncHistory = (params: TypeSearchQueryParams): Promise<boolean> => {
    const nextParams = getSearchConditionParams({
      ...lastParams,
      ...params,
    }) as ParsedUrlQueryInput;

    // Ignore if no difference.
    if (_.isEqual(nextParams, lastParams)) {
      return Promise.resolve(false);
    }

    setLastParams(nextParams);

    return push(
      {
        pathname,
        query: nextParams,
      },
      undefined,
      { shallow: true }
    );
  };

  const syncHistoryBeforePopState = (params: ParsedUrlQuery): void => {
    const nextParams = getSearchConditionParams({
      ...lastParams,
      ...getParams(params),
    }) as ParsedUrlQueryInput;

    // Ignore if no difference.
    if (!_.isEqual(nextParams, lastParams)) {
      setLastParams(nextParams);
    }
  };

  if (!isRouterReady) {
    return {
      params: {},
      rawParams: {},
      syncHistory,
      syncHistoryBeforePopState,
      isReady,
    };
  }

  const params = getParams(lastParams);

  return <SearchQueryState>{
    // Only expose truthy params
    params,
    rawParams: lastParams,
    syncHistory,
    syncHistoryBeforePopState,
    isReady,
  };
}

export { getParams };

const SearchQueryParams = createContainer(useSearchQueryParams);

export default SearchQueryParams;
