import * as React from "react";
import type { ContainerProviderProps } from "unstated-next/src/unstated-next";

type Props = {
  components: Array<
    | React.JSXElementConstructor<React.PropsWithChildren<unknown>>
    | React.ComponentType<ContainerProviderProps<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
  >;
  children: React.ReactNode;
};

const Compose: React.FC<Props> = ({ children, components = [] }) => {
  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </>
  );
};

export default Compose;
