import type { FlowListStepStatus } from "@components/FlowList";
import { FlowList } from "@components/FlowList";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  step: 1 | 2 | 3;
  showsStep2: boolean | undefined;
};

export const RequestReservationSteps: FC<Props> = ({ step, showsStep2 }) => {
  const step2Status = (): FlowListStepStatus => {
    switch (step) {
      case 1:
        return "";
      case 2:
        return "current";
      case 3:
        return "checked";
    }
  };
  if (showsStep2 === undefined) {
    return null;
  }
  return (
    <div className={styles.flow}>
      <FlowList
        label={[
          {
            value: "ご予約詳細入力",
            status: step === 1 ? "current" : "checked",
          },
          ...(showsStep2
            ? [{ value: "お支払い方法", status: step2Status() }]
            : []),
          { value: "確認", status: step === 3 ? "current" : "" },
        ]}
      />
    </div>
  );
};
