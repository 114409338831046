import {
  applyActionCode as firebaseApplyActionCode,
  getAuth,
  sendEmailVerification as firebaseSendEmailVerification,
  signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
  updateEmail as firebaseUpdateEmail,
  updatePassword as firebaseUpdatePassword,
} from "@firebase/auth";
import type { PredefinedMessage } from "@hooks/useMessage";
import { PredefinedMessages } from "@hooks/useMessage";
import { app } from "@libs/utils/firebase/config";
import type { User } from "@firebase/auth";
import type { RecaptchaVerifier } from "firebase/auth";

const auth = getAuth(app);

// SMS認証時のreCaptchaのUIなどFirebaseSDKが提供するUIを日本語にするのに必要
// https://firebase.google.com/docs/auth/web/phone-auth#set-up-the-recaptcha-verifier
auth.languageCode = "ja";

const signOut = async (): Promise<void> => {
  if (!auth.currentUser) {
    return;
  }
  await auth.signOut();
};

let resolved = false;
const getIdToken = async (): Promise<string | null> => {
  if (!resolved) {
    // Wait for sync firebase session(fetch currentUser).
    await new Promise((resolve, reject) => {
      auth.onAuthStateChanged(resolve, reject);
    });
    resolved = true;
  }
  if (!auth.currentUser) {
    return null;
  }
  return await auth.currentUser.getIdToken();
};

const wasEmailVerified = (): boolean =>
  auth.currentUser?.emailVerified ?? false;

const isAuthError = (err: { code: string }): boolean =>
  `${err?.code}`.startsWith("auth/");

const deleteUser = async (): Promise<boolean> => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    return false;
  }

  try {
    await currentUser.delete();
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const updatePassword = async (
  currentUser: User,
  newPassword: string
): Promise<boolean> => {
  try {
    await firebaseUpdatePassword(currentUser, newPassword);
    return true;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const sendEmailVerification = (
  user: User,
  fanstaUserUuid: string
): Promise<void> => {
  return firebaseSendEmailVerification(user, {
    // クエリuserUuidを渡すのが目的なのでURLパスは実際には使用されない
    url: `${process.env.APP_HOST}/verify_email?userUuid=${fanstaUserUuid}`,
  });
};

const applyActionCode = async (oobCode: string): Promise<void> => {
  await firebaseApplyActionCode(auth, oobCode);
};

const signInWithPhoneNumber = async (
  phoneNumber: string,
  recaptchaVerifier: RecaptchaVerifier
) => {
  return await firebaseSignInWithPhoneNumber(
    auth,
    phoneNumber,
    recaptchaVerifier
  );
};

const updateEmail = async (user: User, email: string) => {
  await firebaseUpdateEmail(user, email);
};

const translateToastErrorMessage = (code: string): PredefinedMessage => {
  switch (code) {
    case "auth/too-many-requests":
      return PredefinedMessages.firebaseTooManyCodeRequests;
    default:
      return PredefinedMessages.commonError;
  }
};

export {
  auth,
  getIdToken,
  wasEmailVerified,
  isAuthError,
  deleteUser,
  updatePassword,
  signOut,
  sendEmailVerification,
  applyActionCode,
  signInWithPhoneNumber,
  updateEmail,
  translateToastErrorMessage,
};
