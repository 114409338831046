import classNames from "classnames";
import Link from "next/link";
import React from "react";
import { paths } from "@libs/paths";
import styles from "./index.module.css";

type Props = {
  isAuthStatusFixed: boolean;
  isAuthenticated: boolean;
  points: number | undefined;
};

const HeaderPoint: React.FC<Props> = ({
  isAuthStatusFixed,
  isAuthenticated,
  points,
}) => {
  const isInvisible =
    !isAuthStatusFixed || (isAuthenticated && points === undefined);

  return (
    <Link
      href={isAuthenticated ? paths.mypagePoints : paths.aboutPoints}
      className={styles.link}
      target={isAuthenticated ? "_self" : "_blank"}
    >
      <div className={styles.wrapper}>
        <div className={isInvisible ? styles.invisible : ""}>
          {isAuthenticated && points && points !== 0 ? (
            <div className={styles.pointWrapper}>
              <span className={styles.pointText}>保有ポイント</span>
              <div className={styles.pointDetail}>
                <span className={styles.pointNum}>
                  {points.toLocaleString()}
                </span>
                <span className={styles.pointUnit}>pt</span>
              </div>
            </div>
          ) : (
            <div className={classNames(styles.pointWrapper, styles.noPoint)}>
              <span className={styles.pointText}>
                ネット予約でポイントもらえる！
              </span>
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default HeaderPoint;
