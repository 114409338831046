import classNames from "classnames";
import Link from "next/link";
import type { MessageType } from "@hooks/useMessage";
import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

const AutoCloseMillisecond = 4000;

type Props = {
  isFloating: boolean;
  children: ReactNode;
};

export const InformationPanel: FC<Props> = ({ isFloating, children }) => {
  return (
    <div
      className={classNames(styles.wrapper, { [styles.floating]: isFloating })}
    >
      {children}
    </div>
  );
};

type ItemProps = {
  type: MessageType;
  closeButton: boolean;
  isExposed?: boolean;
  onClose?: () => void;
  className?: string;
  children: ReactNode;
  isAutoClose?: boolean;
};

export const InformationPanelItem: FC<ItemProps> = ({
  type,
  closeButton,
  isExposed,
  onClose,
  children,
  className,
  isAutoClose,
}) => {
  // 閉じるボタンが有効で閉じる際の挙動が定義されている場合の挙動
  if (closeButton && onClose && !isExposed && isAutoClose) {
    // 一定時間経過後に自動で閉じる
    setTimeout(onClose, AutoCloseMillisecond);
  }

  const classes = [styles.box];
  if (type && styles[`${type}`]) {
    classes.push(styles[`${type}`]);
  }

  return (
    <div className={classNames(classes, className)}>
      <div className={styles.text}>{children}</div>
      {closeButton && (
        <button
          type="button"
          className={styles.close}
          onClick={() => {
            if (!onClose) {
              return;
            }
            onClose();
          }}
        />
      )}
    </div>
  );
};

type StrongLinkProps = {
  text: string;
  onClick?: () => void;
  isNewTab?: boolean;
  href?: string;
};

export const InformationPanelStrongLinkItem: FC<StrongLinkProps> = ({
  text,
  onClick,
  isNewTab,
  href,
}) => {
  return (
    <Link
      href={href || ""}
      className={styles.strongLink}
      onClick={onClick}
      target={(isNewTab && "_blank") || undefined}
    >
      {text}
    </Link>
  );
};
