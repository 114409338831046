import _ from "lodash";
import type { UsersTeamType } from "@graphql/__generated__/types";
import type { UseCommonData_MasterPrefectureFragment as MasterPrefectureFragment } from "@hooks/useCommonData/__generated__/fragments";
import type { SearchCondition } from "@hooks/useSearchCondition";
import type { Og_UsersTeamFragment as UsersTeamFragment } from "./__generated__/fragments";
import type { Og_MatchesStaticUrlParamsQuery as MatchesStaticUrlParamsQuery } from "./__generated__/queries";

export const trimSpaces = (str: string) => str.replace(/\s/g, "");

const seoTournamentName = (
  tournaments:
    | MatchesStaticUrlParamsQuery["tournaments"]
    | UsersTeamFragment["masterTournaments"]
) => (tournaments && tournaments.length > 0 ? tournaments[0].name : "");

const getDynamicArea = (
  searchCondition: SearchCondition,
  masterPrefectures?: MasterPrefectureFragment[]
): string | undefined => {
  const { areaIds } = searchCondition;

  const prefectureNames: string[] = _.uniq(
    _.reduce(
      areaIds,
      (acc: string[], id) => {
        _.each(masterPrefectures || [], (masterPrefecture) => {
          _.each(masterPrefecture.combinedAreas || [], (combinedArea) => {
            _.each(combinedArea.areas || [], (area) => {
              if (area.id === id) {
                acc.push(masterPrefecture.name);
              }
            });
          });
        });
        return acc;
      },
      []
    )
  );

  return prefectureNames.length > 1 ? "" : prefectureNames[0];
};

const getDynamicTeam = (
  searchCondition: SearchCondition,
  allTeams: UsersTeamType[] | UsersTeamFragment[]
): string | undefined => {
  const { teamIds = [] } = searchCondition;
  const teams = _.filter(allTeams, ({ id }) => teamIds.includes(id));

  // no team search
  if (teams.length === 0) {
    return;
  }

  // 1チーム
  if (teams.length === 1) {
    return teams[0].name;
  }

  // 異なるスポーツが含まれている場合は「サッカーや野球」のように、複数のスポーツ名を「や」で繋げた文字列を返す
  const sportGenres = Array.from(
    // スポーツジャンルの重複をなくす処理
    // 例: [{id: 1, name: 'サッカー'}, {id: 2, name: '野球'}, {id: 1, name: 'サッカー'}] → [{id: 1, name: 'サッカー'}, {id: 2, name: '野球'}]
    new Map(
      teams.map((team) => [team.masterSportGenre.id, team.masterSportGenre])
    ).values()
  );
  if (sportGenres.length > 1) {
    return sportGenres.map((genre) => genre.name).join("や");
  }

  // 全てのチームが1つの同じトーナメントだけに所属している場合はそのトーナメント名を返す
  const allTournamentIdsWithDuplication = ([] as number[]).concat(
    ...teams.map((t) => t.masterTournaments?.map((mt) => mt.id) ?? [])
  );
  const tournamentCount = new Set(allTournamentIdsWithDuplication).size;
  if (tournamentCount === 1) {
    return teams[0].topPriorityTournament?.name ?? "";
  }

  return teams[0].masterSportGenre.name || undefined;
};

export { seoTournamentName, getDynamicArea, getDynamicTeam };
