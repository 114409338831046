import { createSignalIfSupported, useApolloClient } from "@apollo/client";
import classNames from "classnames";
import hash from "hash-sum";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import type { CalendarDay } from "@components/Calender";
import { Calendar } from "@components/Calender";
import { CommonHead, NoIndex } from "@components/CommonHead";
import CommonLayout from "@components/CommonLayout";
import { MatchList } from "@components/MatchList";
import { PoweredByArea } from "@components/PoweredByArea";
import { SearchInput } from "@components/SearchInput";
import { CommonDataState } from "@hooks/useCommonData";
import type { SearchCondition } from "@hooks/useSearchCondition";
import { getSearchConditionFromSearchQueryParams } from "@hooks/useSearchCondition";
import SearchQueryParams from "@hooks/useSearchQueryParams";
import { TeamsByTournamentState } from "@hooks/useTeamsByTournament";
import { isTypeProps, queryForSSP } from "@libs/apolloClient";
import { paths } from "@libs/paths";
import { getRouter as getRewriteRouter } from "@libs/rewrite";
import { useAbortPendingRequestOnUnmount } from "@libs/utils/abortFetch";
import {
  baseTimeForMatches,
  dayjs,
  fanstaToday,
  formats,
  toLocaleStr,
} from "@libs/utils/date";
import {
  InvalidParametersError,
  NotFoundError,
} from "@libs/utils/dynamicURLParams/common";
import { getStaticRepresentation } from "@libs/utils/dynamicURLParams/matches";
import {
  getOgFromParsedUrlQuery,
  getOgFromSearchCondition,
} from "@libs/utils/og/matches";
import { doScroll, scrollToTop } from "@libs/utils/scroll";
import type { OgInfo } from "@libs/utils/staticURLParams/common";
import { getOgImagePathFromTournamentSlug } from "@libs/utils/staticURLParams/common";
import {
  isRecordNotFound,
  parseDynamicURLParameters,
} from "@libs/utils/staticURLParams/matches";
import {
  PagesMatches_MatchesDocument as MatchesDocument,
  PagesMatches_MatchesStaticUrlParamsDocument as MatchesStaticUrlParamsDocument,
} from "./__generated__/queries";
import styles from "./index.module.css";
import type {
  PagesMatches_MatchesQuery as MatchesQuery,
  PagesMatches_MatchesStaticUrlParamsQuery as MatchesStaticUrlParamsQuery,
} from "./__generated__/queries";
import type { ApolloQueryResult } from "@apollo/client";
import type { GetServerSidePropsContext, NextPage } from "next";
import type { ParsedUrlQuery } from "querystring";

type MatchProps = {
  parameters?: {
    baseYmd?: string;
    teams?: number[];
    tba?: number[];
    tournament?: number;
  };
  og: OgInfo;
};

// ここで使用する試合リストはAPIが月単位のものを返すので開始時間が2時間を過ぎたものしかない日付はここでフィルタリング
// このフィルタリングをしないと過去の試合しかない場合に「試合がありません」のメッセージが出せなくなる
// ※ matchesは日付でグルーピングされた試合の配列をもつハッシュ配列
export const getFilteredMatches = (
  matches: MatchesQuery["matches"]
): MatchesQuery["matches"] => {
  const baseTime = baseTimeForMatches();
  return (matches || []).filter(
    (m) => m.nodes?.some((n) => dayjs(n.startsAt).isAfter(baseTime))
  );
};

const Match: NextPage<MatchProps> = ({ parameters, og }) => {
  const [currentOg, setCurrentOg] = useState(og);
  const searchQueryParams = SearchQueryParams.useContainer();
  const { data: prefData } = CommonDataState.useContainer();

  const [searchCondition, setSearchCondition] = useState<SearchCondition>(
    {} as SearchCondition
  );

  const { asPath } = useRouter();
  const rewriteRouter = getRewriteRouter();
  const client = useApolloClient();
  const [response, setResponse] = useState(
    {} as ApolloQueryResult<MatchesQuery | undefined>
  );
  const result = rewriteRouter.match(asPath);
  const isRewrite = !!result;
  const isPageUrl = asPath === "/matches";
  const isStaticUrl = isRewrite;
  const isDynamicUrl = !isStaticUrl && !isPageUrl;

  const prefectureParam: number | undefined = searchCondition.prefectureId;
  const combinedAreaParam: number[] | undefined =
    searchCondition.combinedAreaIds;
  const areaParam: number[] | undefined = searchCondition.areaIds;

  const teamsByTournamentState = TeamsByTournamentState.useContainer();

  const { allTeams, allTournaments } = teamsByTournamentState;

  // 当月データに表示できるものがない場合翌月に飛んだかどうかのフラグ
  // カレンダーナビゲーションUIで当月（表示するものはないけど）に戻れるようにするため
  const [skipped, setSkipped] = useState(false);

  const [today, setToday] = useState<dayjs.Dayjs | null>(null);
  useEffect(() => {
    setToday(fanstaToday());
  }, []);

  const getInitialBaseYmd = (): dayjs.Dayjs => {
    if (parameters?.baseYmd) {
      const date = dayjs(parameters.baseYmd);
      if (date.isSameOrAfter(today ?? fanstaToday())) {
        return date;
      }
    }

    return today ?? fanstaToday();
  };

  const [baseYmd, setBaseYmd] = useState(getInitialBaseYmd());

  // 翌月/前月の移動時に、ページ最上部に即時スクロールする。
  useEffect(() => {
    requestAnimationFrame(() => {
      scrollToTop();
    });
  }, [baseYmd]);

  const baseYmdStr = baseYmd.format(formats.ymdOnly);

  const selectedTeamIds = searchCondition?.teamIds || [];
  const selectedTournamentIdsTba = searchCondition?.tournamentIdsTba || [];

  const prefectures = prefData?.masterPrefectures || [];

  // Sync URL changes into SearchCondition.
  useEffect(() => {
    if (searchQueryParams.isReady) {
      const nextParams = getSearchConditionFromSearchQueryParams(
        searchQueryParams.params
      );
      setSearchCondition(nextParams);
    }
  }, [searchQueryParams.isReady]);

  // searchConditionが変わったらクエリを実行する。
  useEffect(() => {
    // SEE: [Cancellable apollo-client queries :: OpenHood](https://experiments.openhood.com/apollo-client/typescript/2020/10/23/apollo-client-cancellable-queries/)
    const { signal } = createSignalIfSupported();

    (async () => {
      if (_.isEmpty(searchCondition) || _.isEmpty(prefectures)) {
        return;
      }

      // Truthyな値のみを残す
      const variables = _.pickBy(
        {
          baseYmd: baseYmdStr,
          teamIds: selectedTeamIds,
          tournamentIdsTba: selectedTournamentIdsTba,
        },
        _.identity
      );

      const context = {
        debounceKey: "matches",
        // Authが有効だとクエリが遅くなるので、公開APIの場合は明示的にskipする。
        skipAuth: true,
        fetchOptions: {},
      };

      if (signal) {
        context.fetchOptions = { signal };
      }

      // クエリのタイミングを細かく制御するために、useQueryを使わない。
      const response = await client.query({
        query: MatchesDocument,
        context,
        variables,
      });

      const { data } = response;

      const matches = getFilteredMatches(data?.matches);

      const og = getOgFromSearchCondition(
        searchCondition,
        {
          ...data,
          masterPrefectures: prefectures,
          tournaments: allTournaments,
          teamsUnbundled: allTeams,
        },
        baseYmd
      );

      isDynamicUrl &&
        setCurrentOg({
          title: og[0],
          description: og[1],
          imagePath: null,
        });

      // クエリ実行後に表示するものが0件の場合は翌月に移動
      // トップ(/)が最大翌月まで取得している仕様に合わせている
      if (
        !skipped &&
        data &&
        matches.length === 0 &&
        today?.startOf("month").isSame(baseYmd.startOf("month"))
      ) {
        onGoToNextMonth();
      }
      // 初回以外は自動翌月遷移は無効にする
      setSkipped(true);

      setResponse(response);
    })();
  }, [prefectures, asPath, hash(searchCondition), baseYmdStr]);

  // Do abort pending request.
  useAbortPendingRequestOnUnmount();

  let isHidden = false;

  const { data, loading, error } = response;

  if (_.isEmpty(response) || loading || error) {
    if (error) {
      console.error(error);
    }
    isHidden = true;
  }

  const matches = getFilteredMatches(data?.matches || []);
  const isNotFound = matches.length === 0;
  const matchDays = matches.map((match) => match.date);

  // これより過去の月の表示を許可するかどうか
  const hasPreviousMonth = today && baseYmd.isAfter(today, "month");

  let onGoToPreviousMonth: (() => void) | null = () => {
    let nextYmd = baseYmd.subtract(1, "month");

    // 当月の場合は、today(初期で指定された日)を基準日とする。
    if (today && nextYmd.isSame(today, "month")) {
      nextYmd = today;
    }

    setBaseYmd(nextYmd);
  };

  if (!hasPreviousMonth) {
    onGoToPreviousMonth = null;
  }

  const onGoToNextMonth: () => void = () => {
    setBaseYmd(baseYmd.startOf("month").add(1, "month"));
  };

  const onClickDay: (day: CalendarDay) => void = (calendarDay) => {
    // Scroll-to top on clicking today.
    if (today && calendarDay.rawDay?.isSame(today, "day")) {
      scrollToTop();
      return;
    } else if (!calendarDay.hasMatch) {
      return;
    }

    const ymdStr = baseYmd.date(calendarDay.date).format(formats.ymdOnly);
    const node = document.querySelector(`#date-${ymdStr}`);
    if (!node) {
      return;
    }

    doScroll(node.parentElement);
  };

  const removeUnecessaryPath = (url: string): string =>
    url.replace(/\/d-\d{6}/, "");

  const getCanonicalForDynamic = (): string => {
    if (prefData) {
      try {
        const staticRepresentation = getStaticRepresentation(
          prefData,
          searchQueryParams.params
        );
        if (staticRepresentation !== null) {
          return staticRepresentation;
        }
      } catch (error) {
        const isTranslateStaticParamsError =
          error instanceof InvalidParametersError ||
          error instanceof NotFoundError;

        if (!isTranslateStaticParamsError) {
          throw error;
        }
      }
    }

    return "/matches";
  };

  const getCanonicalUrl = (): string => {
    if (isStaticUrl) {
      return removeUnecessaryPath(asPath);
    }
    if (isPageUrl) {
      return "/matches";
    }
    return getCanonicalForDynamic();
  };
  const canonicalUrl = getCanonicalUrl();

  return (
    <CommonLayout footerPC>
      <CommonHead
        title={currentOg.title}
        description={currentOg.description}
        ogImage={currentOg.imagePath ?? undefined}
        ogUrl={canonicalUrl}
        options={{
          titleSuffix: true,
        }}
        canonicalUrl={canonicalUrl}
      />

      {isDynamicUrl && canonicalUrl === "/matches" && <NoIndex />}

      {today && (
        <div className={styles.main} data-test-id="matches-page">
          <div className={styles.column}>
            <div className={styles.columnLeft}>
              <div className={styles.sticky}>
                <div className={styles.filter}>
                  <SearchInput
                    className={"sort"}
                    teamSearchPlaceholder="条件で絞り込む"
                    teamSearchIcon="filter"
                    onChange={(searchCondition): void => {
                      setSearchCondition(searchCondition);
                    }}
                    shouldSyncHistory
                    showSearchArea={false}
                    submitText="この条件で絞り込む"
                    isDisplayingSearchTitle={true}
                    teamSearchTitle="絞り込み"
                  />
                </div>

                <Calendar
                  today={today}
                  month={toLocaleStr(baseYmd, "month")}
                  matchDays={matchDays}
                  onClickDay={onClickDay}
                  onGoToPreviousMonth={onGoToPreviousMonth}
                  onGoToNextMonth={onGoToNextMonth}
                />
              </div>
            </div>

            <div className={styles.columnRight}>
              {!isHidden && (
                <div
                  className={classNames("l-main__innerWidth", styles.container)}
                >
                  <div className="none md:block">
                    <h1
                      className="c-contentTitle c-contentTitle--fz24"
                      data-test="header"
                    >
                      {currentOg.title}
                    </h1>
                  </div>

                  {isNotFound ? (
                    <div className={styles.box}>
                      <p className={styles.notfound}>試合はありません</p>
                    </div>
                  ) : (
                    matches.map((row) => (
                      <MatchList
                        key={row.date}
                        row={row}
                        prefectureId={prefectureParam}
                        combinedAreaIds={combinedAreaParam}
                        areaIds={areaParam}
                      />
                    ))
                  )}

                  <div className="none md:block">
                    <div className={styles.pager}>
                      <button
                        type="button"
                        className={classNames(
                          styles.arrow,
                          styles.arrowPrev,
                          !onGoToPreviousMonth && styles.disabled
                        )}
                        onClick={onGoToPreviousMonth || _.noop}
                        disabled={!onGoToPreviousMonth}
                      >
                        前月
                      </button>
                      <button
                        type="button"
                        className={classNames(styles.arrow, styles.arrowNext)}
                        onClick={onGoToNextMonth}
                      >
                        翌月
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="md:none">
        <PoweredByArea />
      </div>
    </CommonLayout>
  );
};

export async function getServerSideProps(context: GetServerSidePropsContext) {
  // 1. URLからクエリ文字列を取得
  const rewriteRouter = getRewriteRouter();
  const result = rewriteRouter.parseParams(context.resolvedUrl, context.query);
  const query = result?.params as ParsedUrlQuery;

  // tournamentSlugとteamSlug両方指定されている場合は古い仕様なので新しい仕様のURLにリダイレクトさせる
  if (query.tournamentSlug && query.teamSlug) {
    return {
      redirect: {
        statusCode: 301,
        destination: paths.staticMatches({
          ...context.query, // prefix付きがよいのでcontext.queryを使っている
          dateSlug:
            typeof context.query.baseYmdSlug === "string"
              ? context.query.baseYmdSlug
              : "",
          tournamentSlug: "",
        }),
      },
    };
  }

  // 2. `StaticURLParamsQuery` を使って、クエリ文字列からパラメータ(ID)へ変換する
  const paramsQueryRes = await queryForSSP<
    ApolloQueryResult<MatchesStaticUrlParamsQuery>["data"]
  >({
    query: MatchesStaticUrlParamsDocument,
    context: {
      // Authが有効だとクエリが遅くなるので、公開APIの場合は明示的にskipする。
      skipAuth: true,
    },
    variables: query,
  });

  // 3. `DynamicURLParameters` を生成する。
  let matchesDynamicURLParameters;
  const data = _.get(paramsQueryRes, ["props", "data"], {});
  matchesDynamicURLParameters = parseDynamicURLParameters(data, query);

  if (isRecordNotFound(query, data)) {
    return {
      notFound: true,
    };
  }

  if (result?.terminatingParams?.hasBaseYmd) {
    matchesDynamicURLParameters = {
      ...matchesDynamicURLParameters,
      baseYmd: query.baseYmdSlug as string,
    };
  }

  const searchCondition = getSearchConditionFromSearchQueryParams(
    matchesDynamicURLParameters
  );

  // 4. ページ固有のクエリを実行する
  const pageProps = await queryForSSP({
    query: MatchesDocument,
    context: {
      // Authが有効だとクエリが遅くなるので、公開APIの場合は明示的にskipする。
      skipAuth: true,
    },
    variables: searchCondition,
  });

  if (!isTypeProps(pageProps)) {
    return {
      props: {
        parameters: matchesDynamicURLParameters,
      },
    };
  }

  const baseYmd = matchesDynamicURLParameters.baseYmd
    ? dayjs(matchesDynamicURLParameters.baseYmd)
    : undefined;

  const [title, description] = getOgFromParsedUrlQuery(query, data, baseYmd);

  return {
    props: {
      parameters: matchesDynamicURLParameters,
      og: {
        title,
        description,
        imagePath: getOgImagePathFromTournamentSlug(query.tournamentSlug),
      },
    },
  };
}

export default Match;
