import { useRouter } from "next/router";
import { useEffect } from "react";
import { useIsRouterReady } from "@hooks/useIsRouterReady";
import { getRouter as getRewriteRouter } from "@libs/rewrite";

const getFullPath = (relativePath: string) =>
  process.env.APP_HOST + relativePath;

const displaySmartBanner = (): void => {
  Function(`AF("banners", "showBanner", { bannerZIndex: 1 });`)();
};

const displaySmartBannerWithParams = (fullPath: string): void => {
  Function(
    `AF("banners", "showBanner", { bannerZIndex: 1, additionalParams: { deep_link_value: "${fullPath}" } });`
  )();
};

const useDisplaySmartBanner = () => {
  const { events, asPath, query } = useRouter();
  const isRouterReady = useIsRouterReady();
  const rewriteRouter = getRewriteRouter();

  useEffect(() => {
    if (!isRouterReady) {
      displaySmartBanner();
      return;
    }

    const handleRouteChangeComplete = (): void => {
      // アプリで静的URLのハンドリングが出来ないので、静的URLの場合はTOPページをスマートバナーのパラメータにセットする
      const isStaticUrl = !!rewriteRouter.match(asPath);
      const url = getFullPath(isStaticUrl ? "/" : asPath);
      displaySmartBannerWithParams(encodeURIComponent(url));
    };

    events.on("routeChangeComplete", handleRouteChangeComplete);
    return (): void => {
      events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [query, asPath]);
};

export default useDisplaySmartBanner;
