import { PaymentElement, useElements } from "@stripe/react-stripe-js";
import classNames from "classnames";
import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import ReservationPaymentOption from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservasionPaymentOption";
import type { PaymentMethodInputType } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import { StaticPaymentMethodEnum } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import styles from "./index.module.css";
import type {
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import type { FC } from "react";

type Props = {
  handlePaymentElementChange: (event: StripePaymentElementChangeEvent) => void;
};

const paymentElementOptions: StripePaymentElementOptions = {
  fields: {
    billingDetails: { address: { country: "never" } },
  },
};

export const ReservationPaymentNewCard: FC<Props> = ({
  handlePaymentElementChange,
}) => {
  const elements = useElements();
  const { register, control } = useFormContext<PaymentMethodInputType>();
  const savesNewCard = useWatch({ control, name: "savesNewCard" });

  useEffect(() => {
    elements?.update({
      setupFutureUsage: savesNewCard ? "on_session" : null,
    });
  }, [savesNewCard]);

  return (
    <ReservationPaymentOption
      label={"新規クレジットカード"}
      value={StaticPaymentMethodEnum.NewCard}
      point={4}
      errorMessage={null}
      hidesChildrenIfNotSelected
    >
      <div className={classNames(styles.register)}>
        <div className={styles.registerFrom}>
          <PaymentElement
            options={paymentElementOptions}
            onChange={handlePaymentElementChange}
          />
        </div>

        {/*TODO: [事前決済] ポイント還元率の変更 のPBIで実装する*/}
        {/*<div className={classNames(styles.appeal, styles.appealApp)}>*/}
        {/*  <div className={styles.appealTitle}>*/}
        {/*    アプリ決済なら、ポイントが*/}
        {/*    <br />*/}
        {/*    もっとお得にたまる！*/}
        {/*  </div>*/}

        {/*  <div className={styles.appealTransformationWrapper}>*/}
        {/*    <div*/}
        {/*      className={classNames(*/}
        {/*        styles.appealTransformation,*/}
        {/*        styles.appealTransformationBefore*/}
        {/*      )}*/}
        {/*    >*/}
        {/*      <div className={styles.appealTransformationTitle}>WEBで決済</div>*/}
        {/*      <div className={styles.appealTransformationPercentage}>*/}
        {/*        <em>4%</em>*/}
        {/*      </div>*/}
        {/*    </div>*/}

        {/*    <div*/}
        {/*      className={classNames(*/}
        {/*        styles.appealTransformation,*/}
        {/*        styles.appealTransformationAfter*/}
        {/*      )}*/}
        {/*    >*/}
        {/*      <div className={styles.appealTransformationTitle}>*/}
        {/*        アプリで決済*/}
        {/*      </div>*/}
        {/*      <div className={styles.appealTransformationPercentage}>*/}
        {/*        <em>5%</em>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}

        {/*  <div className={styles.appealLink}>*/}
        {/*    <a href="#" className={styles.appealLinkApp}>*/}
        {/*      アプリをインストールする*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <label className={styles.registerCheck}>
          <input type="checkbox" {...register("savesNewCard")} />
          <div className={styles.registerCheckLabels}>
            このカード情報を保存する
          </div>
        </label>
      </div>
    </ReservationPaymentOption>
  );
};
