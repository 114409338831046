import classNames from "classnames";
import Link from "next/link";
import * as React from "react";
import useAccessFromApp from "@hooks/useAccessFromApp";
import { paths } from "@libs/paths";
import styles from "./index.module.css";

const LandingPagesHeader: React.FC = () => {
  const isFanstaMobileApp = useAccessFromApp();
  return (
    <header className={classNames(styles.header, styles.headerFixed)}>
      <div className={styles.headerLogo}>
        <Link href={paths.home}>
          <img
            src="/img/logo_fansta.svg"
            alt="スポーツバー検索・予約サービス Fansta"
          />
          <div className={styles.headerText}>Powered by DAZN</div>
        </Link>
      </div>
      <div
        className={classNames(
          styles.appDownloadButtons,
          isFanstaMobileApp && styles.isWevView
        )}
      >
        <Link href={"https://fansta.onelink.me/ZFgu/pzt7j5yo"} target="_blank">
          <img src="/img/app_appstore.svg" alt="App Storeからダウンロード" />
        </Link>
        <Link href={"https://fansta.onelink.me/ZFgu/pzt7j5yo"} target="_blank">
          <img src="/img/app_googleplay.png" alt="Google Playで手に入れよう" />
        </Link>
      </div>
    </header>
  );
};

export default LandingPagesHeader;
