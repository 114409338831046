import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./index.module.css";
import type { FC } from "react";

export type ToolTipType = {
  id: string;
  message: string;
};

type Props = {
  type?: "left" | "right";
  toolTip: ToolTipType;
};

export const PredefinedToolTips = {
  area: {
    id: "areaToolTip",
    message:
      "今後表示コンテンツのおすすめに利用する予定です。\n" +
      "他に公開されることはありません。",
  },
  nickname: {
    id: "nicknameToolTip",
    message: "今後公開プロフィールとしてFansta上に表示される予定です。",
  },
  birthdate: {
    id: "birthdateToolTip",
    message:
      "飲食店ご利用の際の年齢確認に利用する場合があります。\n" +
      "他に公開されることはありません。\n" +
      "登録後に修正するにはお問い合わせが必要です。",
  },
  gameWatchedCount: {
    id: "gameWatchedCountToolTip",
    message:
      "今後表示コンテンツのおすすめに利用する予定です。\n" +
      "他に公開されることはありません。",
  },
  email: {
    id: "emailToolTip",
    message: "サービスのご利用にあたっての各種ご連絡に利用します。",
  },
  reservationPaymentMethods: {
    id: "reservationPaymentMethods",
    message:
      "クレジットカードを選択した場合、リクエスト予約承認時にクレジットカードで決済を行います。クレジットカードは3枚まで保存できます。",
  },
  reservationPerson: {
    id: "reservationPersonToolTip",
    message:
      "基本情報に登録されている氏名・電話番号がセットされます。変更したい場合は マイページ > 基本情報 から行ってください。",
  },
  name: {
    id: "nameToolTip",
    message:
      "ネット予約申込みの際に「予約者情報」として利用されます。他に公開されることはありません。",
  },
  nameKana: {
    id: "nameKanaToolTip",
    message:
      "ネット予約申込みの際に「予約者情報」として利用されます。他に公開されることはありません。",
  },
  reservationSupportingTeam: {
    id: "reservationSupportingTeamToolTip",
    message:
      "お客様が応援するチームをお店側が把握するために利用されます。座席ご案内の参考にさせていただく場合があります。",
  },
};
export const ToolTip: FC<Props> = ({ type = "left", toolTip }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { id, message } = toolTip;

  const closeToolTip = (e: MouseEvent) => {
    if (e.target instanceof HTMLButtonElement && e.target.id === id) {
      return;
    }
    setIsOpened(false);
  };

  useEffect(() => {
    document.addEventListener("click", closeToolTip);
    return () => {
      document.removeEventListener("click", closeToolTip);
    };
  }, []);

  const classes = isOpened ? [styles.box] : [styles.box, styles.hidden];

  // 矢印の位置
  if (type && styles[`${type}`]) {
    classes.push(styles[`${type}`]);
  }

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        id={id}
        className={styles.btn}
        onClick={() => {
          setIsOpened(!isOpened);
        }}
      />
      <div className={classNames(classes)}>{message}</div>
    </div>
  );
};
