import type { UsersUserConfirmationModalType } from "@graphql/__generated__/types";
import { useModalDialog } from "@hooks/useModalDialog";
import { useUseUserPolicyConfirmationModalController_ConfirmUserPolicyMutation } from "@hooks/useUserPolicyConfirmationModalsController/useUserPolicyConfirmationModalController/__generated__/mutations";

const useUserPolicyConfirmationModalController = (
  policy: UsersUserConfirmationModalType
) => {
  const policyConfirmationModal = useModalDialog();

  const [confirmPolicy] =
    useUseUserPolicyConfirmationModalController_ConfirmUserPolicyMutation({
      variables: {
        input: policy,
      },
    });

  const onConfirm = async () => {
    try {
      await confirmPolicy();
      policyConfirmationModal.continueHandler();
    } catch (e) {
      policyConfirmationModal.abortHandler();
    }
  };
  const showIfNeeded = async (modals: UsersUserConfirmationModalType[]) => {
    const modalsHaveSamePolicy = modals.some(
      (m) =>
        m.policyType === policy.policyType &&
        m.policyVersion === policy.policyVersion
    );

    if (modalsHaveSamePolicy) {
      await policyConfirmationModal.show();
      return true;
    }
    return false;
  };

  return {
    isShown: policyConfirmationModal.isShown,
    showIfNeeded,
    onConfirm,
  };
};

export default useUserPolicyConfirmationModalController;
