if (process.browser) {
  // "smoothscroll-polyfill" はSSR時には読み込まれないようにしたいので、requireとしています。
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require("smoothscroll-polyfill").polyfill();
}

export const doScroll: (node: string | Element | null) => void = (node) => {
  if (typeof node === "string") {
    node = document.querySelector(node);
  }
  node && node.scrollIntoView({ behavior: "smooth" });
};

export const scrollToTop: () => void = () => {
  document.body.scrollIntoView();
};
