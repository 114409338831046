import classNames from "classnames";
import * as React from "react";
import styles from "./index.module.css";

interface CustomProps {
  tag?: "p" | "div" | "dt" | "label" | "h2" | "h3" | "span";
  color?: "gray" | "darkgray" | "red";
  size?: 8 | 10 | 12 | 14 | 15 | 16 | 18 | 22;
  bold?: boolean;
  preWrap?: boolean;
  children?: React.ReactNode;
}

// 独自のプロパティ + 指定されるタグのpropsを許容する。
type TextProps = CustomProps &
  React.ComponentProps<"p"> &
  React.ComponentProps<"div"> &
  React.ComponentProps<"dt"> &
  React.ComponentProps<"label"> &
  React.ComponentProps<"h2"> &
  React.ComponentProps<"h3"> &
  React.ComponentProps<"span">;

export const Text: React.FC<TextProps> = (props) => {
  const { tag, color, size, bold, preWrap, className, children, ...rest } =
    props;

  const classes = [className, styles.text];

  // サイズ
  if (size && styles[`size${size}`]) {
    classes.push(styles[`size${size}`]);
  }

  // 色
  if (color && styles[color]) {
    classes.push(styles[color]);
  }

  // 太字かどうか
  if (bold) {
    classes.push(styles.bold);
  }

  // テキストの折返しを強制する
  if (preWrap) {
    classes.push(styles.preWrap);
  }

  return React.createElement(
    tag || "p",
    { ...rest, className: classNames(classes) },
    children || null
  );
};
