import classNames from "classnames";
import { useFormContext, useWatch } from "react-hook-form";
import type { PaymentMethodInputType } from "@components/pages/shops/[uuid]/request_reservation/payment_methods/ReservationPaymentForm";
import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

type Props = {
  label: string;
  value: PaymentMethodInputType["method"];
  point: number;
  children: ReactNode;
  errorMessage: string | null;
  hidesChildrenIfNotSelected: boolean;
};

const ReservationPaymentOption: FC<Props> = ({
  label,
  value,
  errorMessage,
  point,
  children,
  hidesChildrenIfNotSelected,
}) => {
  const { register, control } = useFormContext<PaymentMethodInputType>();
  const isSelected = useWatch({ control, name: "method" }) === value;
  return (
    <div>
      <div className={classNames(styles.radio, errorMessage && styles.error)}>
        <label>
          <input {...register("method")} type="radio" value={value} />
          <div className={styles.label}>
            <div className={styles.methodLabel}>{label}</div>

            <div className={styles.earnedPoints}>
              獲得ポイント{" "}
              <span>
                <em>{point}%</em> (XXpt)
              </span>
            </div>
            {/*選択を外したときに入力したカード情報をリセットしたくないのでcssのdisplay: none;を使って表示制御している*/}
            <div
              className={classNames(
                !isSelected && hidesChildrenIfNotSelected && styles.hide
              )}
            >
              {children}
            </div>
          </div>
        </label>
      </div>

      {errorMessage && (
        <div className={styles.invalidMessage}>{errorMessage}</div>
      )}
    </div>
  );
};

export default ReservationPaymentOption;
