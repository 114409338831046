import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import useAccessFromApp from "@hooks/useAccessFromApp";
import Auth from "@hooks/useAuth";
import { subscribeUserNotificationAllUpdatedAtRef } from "@libs/utils/firebase/database";
import { useUseUnreadNotificationCount_UnreadCountLazyQuery as useUnreadCountLazyQuery } from "./__generated__/queries";

const useUnreadNotificationCount = () => {
  const [unreadCount, setUnreadCount] = useState(0);
  const [updateUnreadCount] = useUnreadCountLazyQuery({
    onCompleted: ({ unreadCount }) => {
      setUnreadCount(unreadCount);
    },
  });

  const { isFanstaUserRegisteredAndValidated } = Auth.useContainer();
  const isFanstaMobileApp = useAccessFromApp();

  useEffect(() => {
    if (!isFanstaUserRegisteredAndValidated || isFanstaMobileApp) {
      return;
    }
    const unsubscribeUserReadNotifications =
      subscribeUserNotificationAllUpdatedAtRef(() => {
        updateUnreadCount();
      });
    return unsubscribeUserReadNotifications;
  }, [isFanstaUserRegisteredAndValidated]);

  return {
    unreadCount: Math.max(unreadCount, 0),
    refetchUnreadCount: async () => {
      await updateUnreadCount();
    },
  };
};

const UnreadNotificationCount = createContainer(useUnreadNotificationCount);

export default UnreadNotificationCount;
