import { useState, useLayoutEffect } from "react";
import { createContainer } from "unstated-next";
import { useScrollbarWidth } from "../useScrollbarWidth";

type ToggleScrollProps = {
  isDisabled: boolean;
  scrollbarWidth: number | null;
  enable: () => void;
  disable: () => void;
};

// SEE: https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
// SSR時は何もしない。
const useBrowserLayoutEffect =
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  typeof window !== "undefined" ? useLayoutEffect : (): void => {};

function useToggleScroll(initialState = false): ToggleScrollProps {
  const { scrollbarWidth } = useScrollbarWidth();
  const [scrollY, setScrollY] = useState(0);

  const [isDisabled, setIsDisabled] = useState(initialState);

  const enable = (): void => setIsDisabled(false);
  const disable = (): void => setIsDisabled(true);

  useBrowserLayoutEffect(() => {
    const $body = document.querySelector("body");
    const $html = document.querySelector("html");

    if (!$html || !$body) {
      return;
    }

    if (isDisabled) {
      const currentScrollY = window.scrollY;

      // scrollの無効時に、scrollbarの幅分だけ、bodyの右側に空白を足す(= 画面がズレない)
      $body.style.marginRight = `${scrollbarWidth}px`;

      $html.classList.add("is-scroll-disabled");

      // iOSの場合は `position: fixed` が適用される為、見える範囲が変わらないようににスクロールしていた分を上にずらす
      if (CSS.supports("-webkit-touch-callout", "none")) {
        $body.style.top = `${-currentScrollY}px`;
        window.scroll({ top: 0 });
      }

      setScrollY(currentScrollY);
    } else {
      $body.style.removeProperty("top");
      $body.style.marginRight = "0px";
      $html.classList.remove("is-scroll-disabled");
      window.scroll({ top: scrollY });
    }
  }, [isDisabled]);

  return { isDisabled, enable, disable, scrollbarWidth };
}

const ToggleScroll = createContainer(useToggleScroll);

export default ToggleScroll;
