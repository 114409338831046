import _ from "lodash";
import type { UseCommonData_CommonDataQuery } from "@hooks/useCommonData/__generated__/queries";
import type { TypeSearchQueryParams } from "@hooks/useSearchQueryParams";
import { reduceTeamsByTournament } from "@libs/utils/teams";

export type ParametersForStaticUrl = {
  prefecture: string;
  combinedArea: string;
  area: string;
  genre: string;
  tournament: string;
  team: string;
  match: string;
};

type Optional<Type> = {
  [Property in keyof Type]+?: Type[Property];
};

export const reduceStaticParameters = (
  parametersForStaticUrl: Optional<ParametersForStaticUrl>,
  accumulator: string
): string => {
  return _.reduce(
    parametersForStaticUrl,
    (acc, value, key) => {
      if (value) {
        let prefixedValue = value;
        switch (key) {
          case "genre":
            prefixedValue = "s-" + prefixedValue;
            break;
          case "match":
            prefixedValue = "m-" + prefixedValue;
            break;
        }

        return `${acc}/${prefixedValue}`;
      }

      return acc;
    },
    accumulator
  );
};

export class NotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "NotFoundError";
  }
}

export class InvalidParametersError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "InvalidParametersError";
  }
}

export type ParametersForMatchesStaticUrl = Pick<
  ParametersForStaticUrl,
  "genre" | "tournament" | "team"
>;

export const translateTeamParameters = (
  commonData: Pick<
    UseCommonData_CommonDataQuery,
    "teamsUnbundled" | "tournaments"
  >,
  params: TypeSearchQueryParams
): ParametersForMatchesStaticUrl => {
  const { teams: teamIds = [] } = params;
  const { teamsUnbundled, tournaments } = commonData;

  if (teamIds.length === 0) {
    // no team search parameters can be represented by a static url
    return { genre: "", tournament: "", team: "" };
  }

  if (teamIds.length === 1) {
    const team = _.find(teamsUnbundled, ["id", teamIds[0]]);
    if (!team) {
      throw new NotFoundError("could not find team in teamsUnbundled");
    }
    return {
      genre: team.masterSportGenre.slug ?? "",
      tournament: "",
      team: team.slug ?? "",
    };
  }

  // チームが複数の場合は、トーナメントのチームが全選択されている可能性があるので、チーム全選択トーナメントと残りのチームを分離する
  const { tournaments: reducedTournaments, teams: reducedTeams } =
    reduceTeamsByTournament(teamsUnbundled || [], tournaments || [], teamIds);

  // heuristics
  if (reducedTeams.length > 0 || reducedTournaments.length === 0) {
    throw new InvalidParametersError("invalid number of teams or tournaments");
  }

  if (reducedTournaments.length === 1) {
    const tournament = reducedTournaments[0];
    return {
      genre: tournament.masterSportGenre.slug ?? "",
      tournament: tournament.slug ?? "",
      team: "",
    };
  }

  // discover if all tournaments of 1 genre are selected
  const targetGenreSlug = reducedTournaments[0].masterSportGenre.slug;
  if (
    !targetGenreSlug ||
    !_.map(tournaments, "masterSportGenre.slug").includes(targetGenreSlug)
  ) {
    throw new NotFoundError("unable to find the slug");
  }
  if (
    _.some(
      reducedTournaments,
      (tournament) =>
        tournament.masterSportGenre.slug !==
        reducedTournaments[0].masterSportGenre.slug
    )
  ) {
    throw new InvalidParametersError("tournaments from different genres");
  }
  const tournamentsWithTargetGenreSlug = tournaments.filter(
    (tournament) => tournament.masterSportGenre.slug === targetGenreSlug
  );
  if (tournamentsWithTargetGenreSlug.length !== reducedTournaments.length) {
    throw new InvalidParametersError("invalid number of tournaments for genre");
  }
  return { genre: targetGenreSlug, tournament: "", team: "" };
};
