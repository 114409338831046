import classNames from "classnames";
import { noop as _noop } from "lodash";
import { useEffect } from "react";
import * as React from "react";
import { checkIsPcByScreenWidth } from "@libs/utils/browser";
import styles from "./index.module.css";
import type { FC } from "react";

type Props = {
  onClose: () => void;
  teamSearchPosition?: string;
  className?: string;
  children: React.ReactNode;
};

export const SearchItemPc: FC<Props> = ({
  onClose,
  teamSearchPosition,
  className,
  children,
}) => {
  useEffect(() => {
    // Skip if non PC.
    if (!checkIsPcByScreenWidth()) {
      return _noop;
    }

    const isWithinBoundaries = (
      eventTargets: EventTarget[],
      target: HTMLElement
    ): boolean => {
      return eventTargets.includes(target);
    };

    // composedPath() はIE11には非対応
    // SEE: https://caniuse.com/?search=composedPath
    // SEE: https://stackoverflow.com/a/64665817
    const clickAwayListner = (e: MouseEvent) => {
      const eventTargets = e.composedPath();
      const target = document.getElementById("searchItemPc");
      if (target && !isWithinBoundaries(eventTargets, target)) {
        e.stopPropagation();
        e.preventDefault();
        onClose();
      }
    };

    const keyboardEventListener = (keyBoardEvent: KeyboardEvent) => {
      if (keyBoardEvent.key === "Escape") {
        onClose();
      }
    };

    // captureはreact-v17を使ったら必要です
    // https://reactjs.org/blog/2020/08/10/react-v17-rc.html#changes-to-event-delegation
    document.addEventListener("click", clickAwayListner, { capture: true });
    document.addEventListener("keyup", keyboardEventListener, {
      capture: true,
    });

    // remove when component unmounts
    return () => {
      document.removeEventListener("click", clickAwayListner, {
        capture: true,
      });
      document.removeEventListener("keyup", keyboardEventListener, {
        capture: true,
      });
    };
  }, []);

  return (
    <div
      id="searchItemPc"
      className={classNames(styles.wrapper, className, {
        [styles.right]: teamSearchPosition === "right",
      })}
    >
      {children}
    </div>
  );
};
