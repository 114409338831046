import { useRouter } from "next/router";
import { useEffect } from "react";
import Toaster from "@components/Toaster";
import Auth from "@hooks/useAuth";
import ContinuePath from "@hooks/useContinuePath";
import { PredefinedMessages } from "@hooks/useMessage";
import { paths } from "@libs/paths";
import type { FC, ReactNode } from "react";

const PageRegister: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const { isCurrentUserLoggedOut, isFanstaUserRegisteredAndValidated } =
    Auth.useContainer();
  const { setContinuePath } = ContinuePath.useContainer();
  const pathname = router.pathname;
  const isLoginPage = pathname.includes(paths.login);
  const isSignupPage = pathname.includes(paths.signup);

  useEffect(() => {
    if (isLoginPage || isSignupPage) {
      return;
    }
    if (isCurrentUserLoggedOut) {
      // router.asPathだとうまくいかないので、window.location.pathnameを使っている
      // SEE: https://github.com/mixi-lx-sys/fansta-frontend/pull/2515
      setContinuePath(window.location.pathname);
    }
    if (isFanstaUserRegisteredAndValidated) {
      setContinuePath(null);
    }
  }, [router, isCurrentUserLoggedOut]);

  return (
    <>
      {!(isLoginPage || isSignupPage) && (
        <Toaster
          messageFilter={[
            PredefinedMessages.loginWithAccountExists,
            PredefinedMessages.signupWithAccountExists,
            PredefinedMessages.signupWithAccountNotExists,
            PredefinedMessages.emailVerificationRequested,
          ]}
        />
      )}
      {children}
    </>
  );
};

export default PageRegister;
