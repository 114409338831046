import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";
import { Button } from "@components/Button";
import { paths } from "@libs/paths";
import { IFImg } from "@libs/utils/IFImg";
import { toJpDate, toJpTime } from "@libs/utils/date";
import { GTMEvent, GTMEvents } from "@libs/utils/gtm";
import { shouldShowMatchTime } from "@libs/utils/match";
import styles from "./index.module.css";
import type { MatchList_MatchListItemFragment as MatchListItemFragment } from "./__generated__/fragments";

type MatchListItemProps = {
  match: MatchListItemFragment;
  noLink?: boolean;
  prefectureId?: number;
  combinedAreaIds?: Array<number>;
  areaIds?: Array<number>;
  className?: string;
  shopUuid?: string;
  isReservable?: boolean;
  forceDynamicUrl?: boolean;
};

export const MatchListItem: React.FC<MatchListItemProps> = ({
  match,
  noLink = false,
  forceDynamicUrl = false,
  className,
  shopUuid,
  isReservable = false,
  prefectureId,
  combinedAreaIds,
  areaIds,
}) => {
  const router = useRouter();
  if (!match) {
    return null;
  }

  let time = match?.startsAt ? toJpTime(match?.startsAt).toUpperCase() : "";
  if (!shouldShowMatchTime(match)) {
    time = "時間未定";
  }

  const handleButtonClick = () => {
    GTMEvent(
      GTMEvents.toReservationFormPressed(
        "観戦をネット予約する",
        shopUuid || "",
        match.id
      )
    );
    router.push(
      paths.shopRequestReservation(shopUuid ?? "", {
        date: toJpDate(match.startsAt, "ymdOnly"),
        matchId: match.id,
      })
    );
  };

  let matchListItem = (
    <div className={classNames(styles.team)}>
      {match.contentsHolderLogoPath && (
        <img
          className={styles.contentsHolderLogo}
          src={IFImg(match.contentsHolderLogoPath)}
          alt="contents holder logo"
        />
      )}
      <div className={styles.cat}>
        <i
          className={styles.logo}
          style={{ backgroundImage: `url(${IFImg(match.homeTeamIconPath)})` }}
        />
        <div className={styles.name}>{match.homeTeamDisplayShortName}</div>
      </div>
      <div>
        <div className={styles.tournamentName}>
          {match?.masterTournament && match.masterTournament.displayName}
        </div>
        <div className={styles.tournamentDate}>
          {toJpDate(match?.startsAt).toUpperCase()}
          <br />
          {time}
        </div>
      </div>
      <div className={styles.cat}>
        <i
          className={styles.logo}
          style={{ backgroundImage: `url(${IFImg(match.awayTeamIconPath)})` }}
        />
        <div className={styles.name}>{match.awayTeamDisplayShortName}</div>
      </div>

      {match.label && (
        <div className={styles.ribbon}>
          <span className={styles.label}>{match.label}</span>
        </div>
      )}
    </div>
  );

  if (noLink) {
    // リンクが不要な場合はdivタグで囲む
    matchListItem = <div className={styles.link}>{matchListItem}</div>;
  } else {
    let link = "";
    if (forceDynamicUrl) {
      link = paths.shops({
        matchId: match.id,
        prefectureId: prefectureId,
        combinedAreaIds: combinedAreaIds?.length ? combinedAreaIds : undefined,
        areaIds: areaIds?.length ? areaIds : undefined,
      });
    } else {
      link = paths.staticShops({
        genreSlug: `s-${match.masterTournament?.masterSportGenre.slug}`,
        tournamentSlug: match.masterTournament?.slug || "",
        matchSlug: `m-${match.id}`,
      });
    }

    // static URL
    matchListItem = (
      <Link href={link} className={styles.link}>
        {matchListItem}
      </Link>
    );
  }

  return (
    <div
      className={classNames(
        className,
        styles.broadcastBox,
        match.useSpecialColor && styles.bgBlue
      )}
    >
      {matchListItem}
      {isReservable && (
        <div className={styles.reservation}>
          <div className={styles.inner}>
            <Button
              className={styles.btn}
              onClick={handleButtonClick}
              styleType="filled"
              colorType="primary"
            >
              観戦をネット予約する
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
