// TODO: Extract searchConditionReducer to other file.
import {
  fromPairs as _fromPairs,
  isEmpty as _isEmpty,
  isEqual as _isEqual,
  pickBy as _pickBy,
  transform as _transform,
} from "lodash";
import { useReducer } from "react";
import type { TypeSearchQueryParams } from "@hooks/useSearchQueryParams";
import { getParams } from "@hooks/useSearchQueryParams";
import type { SearchConditionParam } from "@libs/paths";

enum SearchConditionActionType {
  RESET = "RESET",
  SELECT_TEAM_IDS = "SELECT_TEAM_IDS",
  SELECT_AREA_IDS = "SELECT_AREA_IDS",
  SELECT_COMBINED_AREA_IDS = "SELECT_COMBINED_AREA_IDS",
  SELECT_PREFECTURE_ID = "SELECT_PREFECTURE_ID",
  SELECT_TOURNAMENT_IDS_TBA = "SELECT_TOURNAMENT_IDS_TBA",
}

type SearchConditionAction =
  | { type: "RESET"; payload: SearchCondition }
  | { type: "SELECT_TEAM_IDS"; payload: Array<number> }
  | { type: "SELECT_AREA_IDS"; payload: Array<number> }
  | { type: "SELECT_COMBINED_AREA_IDS"; payload: Array<number> }
  | { type: "SELECT_PREFECTURE_ID"; payload: number }
  | { type: "SELECT_TOURNAMENT_IDS_TBA"; payload: Array<number> };

export type SearchCondition = {
  prefectureId: number | undefined;
  matchId: number | undefined;
  combinedAreaIds: Array<number> | undefined;
  areaIds: Array<number> | undefined;
  teamIds: Array<number> | undefined;
  tournamentIdsTba: Array<number> | undefined;
};

export type SearchConditionState = {
  state: SearchCondition;
  reset: (searchCondition: SearchCondition) => void;
  selectTeamIds: (teamIds: Array<number>) => void;
  selectAreaIds: (areaIds: Array<number>) => void;
  selectCombinedAreaIds: (combinedAreaIds: Array<number>) => void;
  selectPrefectureId: (prefectureId: number) => void;
  selectTournamentIdsTba: (tournamentIdsTba: Array<number>) => void;
  inSyncWith: (
    searchConditionState: SearchCondition,
    queryString: string
  ) => [boolean, CorrectedSearchCondition];
};

export const paramToSearchConditionDict: {
  [Property in SearchConditionParam]: keyof SearchCondition;
} = {
  combinedAreas: "combinedAreaIds",
  areas: "areaIds",
  teams: "teamIds",
  match: "matchId",
  prefecture: "prefectureId",
  tba: "tournamentIdsTba",
};

const searchConditionReducer = (
  state: SearchCondition,
  action: SearchConditionAction
): SearchCondition => {
  switch (action.type) {
    case SearchConditionActionType.RESET:
      return {
        ...state,
        ...action.payload,
      };
    case SearchConditionActionType.SELECT_TEAM_IDS:
      return {
        ...state,
        teamIds: action.payload,
      };
    case SearchConditionActionType.SELECT_AREA_IDS:
      return {
        ...state,
        areaIds: action.payload,
      };
    case SearchConditionActionType.SELECT_COMBINED_AREA_IDS:
      return {
        ...state,
        combinedAreaIds: action.payload,
      };
    case SearchConditionActionType.SELECT_PREFECTURE_ID:
      return {
        ...state,
        prefectureId: action.payload,
      };
    case SearchConditionActionType.SELECT_TOURNAMENT_IDS_TBA:
      return {
        ...state,
        tournamentIdsTba: action.payload,
      };
    default:
      return state;
  }
};

// parse SearchCondition from Query.
// SEE: https://github.com/feross/fromentries
const getScFromQuery = (queryString: string): SearchCondition => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const params = getParams(_fromPairs([...new URLSearchParams(queryString)]));
  return _transform(
    params,
    (acc, value, key) => {
      // Translate params key to SearchCondition key.
      if (key in paramToSearchConditionDict) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[paramToSearchConditionDict[key]] = value;
      }
    },
    {} as SearchCondition
  );
};

export const getSearchConditionFromSearchQueryParams = (
  searchQueryParams: TypeSearchQueryParams
): SearchCondition => {
  return _transform(
    searchQueryParams,
    (acc, value, key) => {
      // Translate params key to SearchCondition key.
      if (key in paramToSearchConditionDict) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc[paramToSearchConditionDict[key]] = value;
      }
    },
    {} as SearchCondition
  );
};

export type CorrectedSearchCondition = {
  [key: string]: number | number[] | string | undefined;
};

const useSearchCondition = ({
  defaultPrefectureId,
}: {
  defaultPrefectureId: number;
}): SearchConditionState => {
  const [searchConditionState, dispatch] = useReducer(searchConditionReducer, {
    prefectureId: defaultPrefectureId,
    matchId: 0,
    combinedAreaIds: [],
    areaIds: [],
    teamIds: [],
    tournamentIdsTba: [],
  } as SearchCondition);

  const reset = (searchCondition: SearchCondition) => {
    dispatch({
      type: SearchConditionActionType.RESET,
      payload: searchCondition,
    });
  };

  const selectTeamIds = (teamIds: Array<number>) => {
    dispatch({
      type: SearchConditionActionType.SELECT_TEAM_IDS,
      payload: teamIds,
    });
  };

  const selectAreaIds = (areaIds: Array<number>) => {
    dispatch({
      type: SearchConditionActionType.SELECT_AREA_IDS,
      payload: areaIds,
    });
  };

  const selectCombinedAreaIds = (combinedAreaIds: Array<number>) => {
    dispatch({
      type: SearchConditionActionType.SELECT_COMBINED_AREA_IDS,
      payload: combinedAreaIds,
    });
  };

  const selectPrefectureId = (prefectureId: number) => {
    dispatch({
      type: SearchConditionActionType.SELECT_PREFECTURE_ID,
      payload: prefectureId,
    });
  };

  const inSyncWith = (
    searchConditionState: SearchCondition,
    queryString: string
  ): [boolean, CorrectedSearchCondition] => {
    // Parse queryString as SearchCondition.
    const nextSearchCondition = getScFromQuery(queryString);

    // Pick only differences.
    const diff = _pickBy(nextSearchCondition, (nextValue, key) => {
      const oldValue = searchConditionState[key as keyof SearchCondition];

      // Override nextValue as default value for "prefectureId"
      if (key === "prefectureId" && !nextValue) {
        nextValue = defaultPrefectureId;
      }

      // Pick only differences.
      return !_isEqual(oldValue, nextValue);
    });

    // Treat as `not in-sync` if any difference found.
    const isInSync = _isEmpty(diff);

    return [isInSync, diff];
  };

  const selectTournamentIdsTba = (tournamentIdsTba: Array<number>) => {
    dispatch({
      type: SearchConditionActionType.SELECT_TOURNAMENT_IDS_TBA,
      payload: tournamentIdsTba,
    });
  };

  return {
    state: searchConditionState,
    reset,
    selectAreaIds,
    selectCombinedAreaIds,
    selectPrefectureId,
    selectTeamIds,
    inSyncWith,
    selectTournamentIdsTba,
  };
};

export { useSearchCondition };
