import * as React from "react";
import {
  dayjs,
  formats,
  toLocaleStr,
  baseTimeForMatches,
  defaultTimeZone,
} from "@libs/utils/date";
import { MatchListItem } from "./MatchListItem";
import styles from "./index.module.css";
import type { MatchList_MatchesQueryResult as MatchesQueryResult } from "./__generated__/queries";

type MatchListProps = {
  row: Exclude<MatchesQueryResult["data"], undefined>["matches"][0];
  prefectureId?: number;
  combinedAreaIds?: Array<number>;
  areaIds?: Array<number>;
};

export const MatchList: React.FC<MatchListProps> = ({
  row,
  prefectureId,
  combinedAreaIds,
  areaIds,
}) => {
  const baseTime = baseTimeForMatches();
  const date = dayjs(row.date, formats.ymdOnly).tz(defaultTimeZone);
  const matches = (row.nodes || []).filter((m) =>
    dayjs(m.startsAt).isAfter(baseTime)
  );

  if (matches.length === 0) {
    return null;
  }

  const headerId = `date-${date.format(formats.ymdOnly)}`;

  return (
    <div className={styles.box}>
      <div className={styles.title} id={headerId}>
        <h3 className={`c-contentTitle ${styles.contentTitle}`}>
          {toLocaleStr(date, "date")}
        </h3>
      </div>

      <div>
        {matches.map((match) => (
          <MatchListItem
            key={match.id}
            match={match}
            prefectureId={prefectureId}
            combinedAreaIds={
              combinedAreaIds?.length ? combinedAreaIds : undefined
            }
            areaIds={areaIds?.length ? areaIds : undefined}
            forceDynamicUrl
          />
        ))}
      </div>
    </div>
  );
};
