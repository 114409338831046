import { useState } from "react";
import { createContainer } from "unstated-next";
import type { ConfirmationResult } from "firebase/auth";
import type React from "react";

type AuthResultContextProps = {
  confirmationResult: ConfirmationResult | undefined;
  setConfirmationResult: React.Dispatch<
    React.SetStateAction<ConfirmationResult | undefined>
  >;
};

const useAuthResult = (): AuthResultContextProps => {
  const [confirmationResult, setConfirmationResult] = useState<
    ConfirmationResult | undefined
  >(undefined);

  return {
    confirmationResult,
    setConfirmationResult,
  };
};

const AuthResult = createContainer(useAuthResult);

export default AuthResult;
