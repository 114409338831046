import _ from "lodash";
import { parseRelativeUrl } from "next/dist/shared/lib/router/utils/parse-relative-url";
import { useRouter } from "next/router";
import { isBrowser } from "@libs/utils/browser";
import type { NextRouter } from "next/dist/shared/lib/router/router";

// Use official(Next.js) query-string parser.

const PATH_404 = "/404";

let initialRoute = "";

const checkIsReadyByQuery = (router: NextRouter): boolean => {
  const url = parseRelativeUrl(router.asPath);

  // Exclude URL params (/shops/:uuid) from "router.query"
  const routerQueryParams = _.pick(router.query, _.keys(url.query));

  return _.isEqual(url.query, routerQueryParams);
};

// TODO: Remove this workaround once original issue were fixed.
// SEE: [Next.js router.isReady never set to true on custom 404 page · Issue #35990 · vercel/next.js](https://github.com/vercel/next.js/issues/35990)
export const isRouterTrulyReady = (router: NextRouter): boolean => {
  const { route, isReady } = router;

  const is404Page = route === PATH_404;
  if (is404Page) {
    return true;
  }

  // Workaround for issue above.
  if (initialRoute === PATH_404) {
    // Fallback to custom isReady checking.
    return checkIsReadyByQuery(router);
  }

  return isReady;
};

export const useIsRouterReady = () => {
  const router = useRouter();

  if (isBrowser() && router.route && !initialRoute) {
    initialRoute = router.route;
  }

  return isRouterTrulyReady(router);
};
