import type { ToolTipType } from "@components/ToolTip";
import { ToolTip } from "@components/ToolTip";
import styles from "./index.module.css";
import type { FC, ReactNode } from "react";

type Props = {
  label: string;
  toolTip?: ToolTipType;
  children?: ReactNode;
};
export const LeftAlignedTitle: FC<Props> = ({ label, toolTip, children }) => {
  return (
    <div className={styles.title}>
      <h1 className={styles.titleText}>
        {label}
        {toolTip && <ToolTip toolTip={toolTip} />}
      </h1>
      {children}
    </div>
  );
};
